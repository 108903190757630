import pii from '@/assets/js/filterWords'

/* 
    OK -> feed-post
    OK -> feed-post-resposta
    OK -> video-post-comentario
    OK -> video-post-comentario-resposta
*/

const feedPost = (req, service) => req.body = pii.filter(service, req, 'feed-post', 'mensagem');
const videoComment = (req, service) => req.body = pii.filter(service, req, 'video-post-comentario', 'comentario');
const feedPostResp = (req, service) => req.params = pii.filter(service, req, 'feed-post-resposta', 'comentario', 'params');
const responseVideoComment = (req, service) => req.body = pii.filter(service, req, 'video-post-comentario-resposta', 'comentario');

const urlsToRedirectGoApi = {
    "plataforma-conteudo/feed": feedPost,
    "plataforma-conteudo/feed{/idFeed}/comentario": feedPostResp,
    "plataforma-conteudo/feed{/idFeedComment}/comentario": feedPostResp,
    "plataforma-conteudo/comunidade/comentario-temporada-video{/idTemporadaVideo}/comentario": videoComment,
    "plataforma-conteudo/comunidade/comentario-temporada-video{/idTemporadaVideo}{/idComentario}/resposta": responseVideoComment,
}

export default (request, service) => {
    if (request.method !== "POST") return null;
    
    // console.log(request.url);
    urlsToRedirectGoApi[request.url]?.(request, service);
}