<template>
  <div class="row font-13 mb-0">
    <div
      v-for="tab in tabs"
      :class="['col', isActive(tab.name)]"
      :key="tab.name"
    >
      <div
        @click="selectTab(tab.name, tab.isPro)"
        class="px-md-3 px-1 py-2 py-md-3 white upper pointer hovered font-12"
      >
        <div
          class="d-flex flex-row align-items-center justify-content-center position-relative"
        >
          <div v-if="!tab.isPro" class="content-icon">
            <LockIcon />
          </div>

          <component :is="tab.icon" />

          <span class="ms-2 pt-1"
            >{{ tab.label }}
            <small v-if="typeof tab.hasLength === 'number'"
              >({{ tab.hasLength }})</small
            ></span
          >
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="py-md-2 px-3 active-menu height-tabs py-0">
        <MaterialCourse
          v-model="links"
          v-show="activeNav === 'material-course'"
        />

        <MaterialClass
          v-model="materiais"
          v-show="activeNav === 'material-class'"
        />

        <Comments v-show="activeNav === 'comments'" />
      </div>
    </div>
  </div>
</template>
<script>
import Comments from "./Comments";
import MaterialClass from "./MaterialClass";
import MaterialCourse from "./MaterialCourse";
import CommentIcon from "@/assets/icons/CommentIcon";
import MaterialIcon from "@/assets/icons/MaterialIcon";
import MaterialDownloadIcon from "@/assets/icons/MaterialDownload";
import { mapGetters } from "vuex";

export default {
  name: "NavComp",

  components: {
    MaterialDownloadIcon,
    MaterialIcon,
    CommentIcon,
    MaterialClass,
    Comments,
    MaterialCourse,
  },

  data() {
    return {
      links: 0,
      materiais: 0,
      activeNav: "comments",
    };
  },

  methods: {
    isActive(tab) {
      return this.activeNav === tab ? "active-menu" : "";
    },

    selectTab(tab, isPro) {
      if (!isPro) return;

      this.activeNav = tab;
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      allVideos: "temporadas/getAllVideos",
    }),

    currentVideo() {
      return Number(this.$route.params.video);
    },

    isLocked() {
      return this.allVideos.some(
        ({ isPro, id }) => isPro && id === this.currentVideo && !this.user.isPro
      );
    },

    tabs() {
      return [
        {
          icon: CommentIcon,
          name: "comments",
          isPro: true,
          label: "Comentários",
        },
        {
          icon: MaterialDownloadIcon,
          label: "Material do curso",
          name: "material-course",
          isPro: !this.isLocked,
          hasLength: this.links,
        },
        {
          icon: MaterialIcon,
          label: "Material da Aula",
          name: "material-class",
          isPro: !this.isLocked,
          hasLength: this.materiais,
        },
      ];
    },
  },
};
</script>
<style lang="sass" scoped>
.active-menu
  background-color: #0e0e0e
.height-tabs
  min-height: 200px !important
.content-icon
  top: 50%
  left: 20px
  position: absolute
  transform: translateX(-50%) translateY(-50%)
  .fa-lock
    font-size: 20px !important
    color: #ffffff88
    position: relative
</style>
