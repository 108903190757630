import { router } from '@/main'

const HANDLE_WATCHED = 'HANDLE_WATCHED'
const SET_ISLOADING = 'SET_ISLOADING'
const SET_TEMPORADAS = 'SET_TEMPORADAS'
const SET_ID_VINCULO = 'SET_ID_VINCULO'

const state = {
  idVinculo: null,
  temporadas: [],
  isLoading: false
}

const actions = {
  setIsLoading: ({ commit }, value) => commit(SET_ISLOADING, value),

  setIdVinculo: ({ commit }, idVinculo) => commit(SET_ID_VINCULO, idVinculo),

  setWatched: ({ commit }, value) => commit(HANDLE_WATCHED, { id: value, val: 'S' }),

  removeWatched: ({ commit }, value) => commit(HANDLE_WATCHED, { id: value, val: 'N' }),

  setTemporadas: ({ commit }, temporadas) => commit(SET_TEMPORADAS, temporadas),

  escolherVideo ({ getters }, direction) {
    const route = router.history.current.params
    const current = Number(route.video)
    const currentIndex = getters.getAllVideos.findIndex(({ id }) => id === current)

    const nextByDirection = currentIndex + direction
    const lengthVideos = getters.getAllVideos.length
    const nextIndex = nextByDirection <= 0 ? 0 : nextByDirection >= lengthVideos ? 0 : nextByDirection

    const { id: video, idTemporada: modulo, idTemporadaVideo } = getters.getAllVideos[nextIndex]
    router.push({ name: 'video', params: { id: route.id, modulo, video, idTemporadaVideo } }).catch(e => e)
  }
}

const getters = {
  isLoading: (state) => state.isLoading,

  getTemporadas: (state) => state.temporadas,

  getIdVinculo: (state) => state.idVinculo,

  getAllVideos: (state) => state.temporadas.reduce((acc, { videos, idTemporada }) => {
    return [...acc, ...videos.map(({ videoVisto, idVideo, idTemporadaVideo, isPro }) => ({
      isPro,
      idTemporada,
      id: idVideo,
      idTemporadaVideo,
      visto: videoVisto === 'S'
    }))]
  }, [])
}

const mutations = {
  [SET_ISLOADING]: (state, value) => (state.isLoading = value),

  [SET_ID_VINCULO]: (state, idVinculo) => (state.idVinculo = idVinculo),

  [SET_TEMPORADAS]: (state, temporadas) => (state.temporadas = temporadas),

  [HANDLE_WATCHED]: (state, { id, val }) => {
    state.temporadas = state.temporadas.map(item => ({
      ...item, videos: item.videos.map(video => ({ ...video, videoVisto: video.idVideo === Number(id) ? val : video.videoVisto }))
    }))
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
