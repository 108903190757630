<template>
  <div>
    <div class="mt-4 mt-md-0" />

    <div class="row justify-content-around align-items-end mb-5 mb-md-5">
      <div class="col">
        <h4 class="title upper">Informações básicas</h4>
        <p>
          (Algumas informações ficarão visíveis em seu perfil e certificados)
        </p>
      </div>

      <div class="col-auto">
        <router-link
          class="pink font-16"
          :to="{ name: 'perfil', params: { user: this.user.id } }"
        >
          <ReturnIcon class="me-1" />
          Voltar
        </router-link>
      </div>
    </div>

    <LoadingComp v-if="isLoadingData" />
    <form v-else @submit.prevent="saveForm">
      <div class="row justify-content-center pb-5 enter-effect-opacity">
        <div class="col-md-2 text-center">
          <ImgPerfilDados />
          <p
            class="font-13 dark-grey-2 mt-2 pointer"
            data-toggle="modal"
            data-target="#modalImageProfile"
          >
            Alterar foto de perfil
            <UploadCloudIcon class="ms-1" />
          </p>
        </div>

        <div class="col-md-10">
          <div class="row">
            <InputData
              label="Nome:"
              v-model="profile.nome"
              class="col-12 col-md-6"
            />
            <InputData
              label="Sobrenome:"
              v-model="profile.sobrenome"
              class="col-12 col-md-6"
            />
            <InputData
              label="Profissão:"
              v-model="profile.ocupacao"
              class="col-6"
            />
            <div class="col-6">
              <MultSelect
                :height="36"
                :list="softwares"
                label="Softwares:"
                classe="min-height"
                v-model="profile.softwares"
                v-if="softwares && softwares.length"
              />
            </div>
            <InputData
              label="E-mail:"
              placeholder="Insira seu e-mail"
              v-model="profile.email"
              class="col-12"
              type="email"
              :disable="true"
            />
            <InputData
              label="Celular:"
              v-model="profile.telefone"
              class="col-12 col-md-6"
              mask="(##) #####-####"
            />
            <InputData
              label="Nascimento:"
              v-model="profile.dataNascimento"
              class="col-12 col-md-6"
              mask="##/##/####"
            />
            <SelectData
              label="Estado:"
              v-model="profile.uf"
              :list="ufs"
              class="col-12 col-md-6"
            />
            <SelectData
              label="Cidade:"
              :list="citiesList"
              v-model="profile.cidade"
              class="col-12 col-md-6"
            />

            <TextArea
              background="input-modal"
              label="Fale um pouco sobre você"
              v-model="profile.descricao"
              class="col-12 mt-1"
            />
          </div>
        </div>

        <p class="font-14 mt-3 mb-2">
          <span class="upper">Na web</span> (Cole o link das suas páginas)
        </p>
        <div class="col-12">
          <InputLinks
            icon="instagram"
            label="Instagram:"
            v-model="profile.linkInstagram"
            class="col-12"
          />
        </div>
        <div class="col-12">
          <InputLinks
            icon="twitter"
            label="Twitter:"
            v-model="profile.linkTwitter"
            class="col-12"
          />
        </div>
        <div class="col-12">
          <InputLinks
            icon="youtube"
            label="Youtube:"
            v-model="profile.linkYoutube"
            class="col-12"
          />
        </div>
        <div class="col-12">
          <InputLinks
            icon="tiktok"
            label="TikTok:"
            v-model="profile.linkTiktok"
            class="col-12"
          />
        </div>
        <div class="col-12">
          <InputLinks
            icon="linkedin"
            label="Linkedin:"
            v-model="profile.linkLinkedin"
            class="col-12"
          />
        </div>

        <ButtonPink classe="small mt-2" label="Salvar" @click="saveForm" />
      </div>
    </form>
  </div>
</template>

<script>
import Mixins from "@/Mixins";
import ufs from "@/consts/ufs";
import ImgPerfilDados from "./ImgPerfilDados";
import { mapGetters, mapActions } from "vuex";
import ReturnIcon from "@/assets/icons/Return";
import PerfilService from "@/service/perfilService";
import EducandoResource from "@/service/userService";
import UploadCloudIcon from "@/assets/icons/UploadCloud";
import SoftwaresService from "@/service/softwaresService";
import InputData from "@/components/shared/forms/InputData";
import InputLinks from "@/components/shared/forms/InputLinks";
import SelectData from "@/components/shared/forms/SelectData";
import ButtonPink from "@/components/shared/forms/ButtonPink";
import MultSelect from "@/components/shared/forms/MultSelect";
import TextArea from "@/components/shared/forms/FieldTextArea";

export default {
  mixins: [Mixins],

  components: {
    TextArea,
    InputData,
    InputLinks,
    SelectData,
    ReturnIcon,
    ButtonPink,
    MultSelect,
    ImgPerfilDados,
    UploadCloudIcon,
  },

  async created() {
    await this.getDataInfos();
    await this.getAllSoftwares();
  },

  data() {
    this.PerfilService = new PerfilService(this.$resource);
    this.EducandoResource = new EducandoResource(this.$resource);
    this.SoftwaresService = new SoftwaresService(this.$resource);

    return {
      ufs: ufs?.map(({ uf, name }) => ({ value: uf, name })),
      profile: { ...this.user, conquistas: this.infos },
    };
  },

  methods: {
    ...mapActions({
      setLoaded: "perfil/setLoaded",
      setPerson: "perfil/setPerson",
      setLoading: "perfil/setLoading",
      setUser: "registration/setUser",
      setAllSoftwares: "softwares/setAll",
    }),

    async getAllSoftwares() {
      if (this.softwares.length) return;
      const { body: softwares } = await this.SoftwaresService.get();
      this.setAllSoftwares(
        softwares?.map(({ id, nome, extensaoImagem }) => ({
          id,
          name: nome,
          img: this.mountImagePath("software", id, extensaoImagem),
        }))
      );
    },

    async saveForm() {
      this.$sweet.sweetWait("Aguarde enquanto estamos salvando");

      const { status: statusAluno } = await this.EducandoResource.editEducando(
        this.mountData(this.profile)
      );
      const { status: statusSoft } = await this.SoftwaresService.update({
        idsSoftwares: this.profile.softwares,
      });
      const { body } = await this.EducandoResource.buscarDados();

      this.$sweet.sweetSuccessResp("<h4>Dados atualizados com sucesso!</h4>");
      if (statusSoft !== 202)
        this.$sweet.sweetMensagem(
          "Ops, houve um erro ao editar seus softwares."
        );
      if (statusAluno !== 202)
        this.$sweet.sweetMensagem(
          "Ops, houve um erro ao tentar editar seus dados."
        );

      this.setUser(body);
      this.getDataInfos();
    },

    async getDataInfos() {
      this.setLoading(true);
      if (!this.user.id) return;
      const { body } = await this.PerfilService.getDados({ id: this.user.id });

      body.dadosEducando[0].dataHoraCadastro = this.$f.converterDatas(
        body.dadosEducando[0].dataHoraCadastro
      );
      this.setPerson(body);

      this.profile = {
        ...this.user,
        conquistas: this.infos,
        softwares: this.person.softwares?.map(({ id }) => id) || [],
        dataNascimento: this.$f.converterDatas(this.user.dataNascimento),
      };

      this.setLoading(false);
    },

    mountData(profile) {
      return {
        nome: profile.nome,
        telefone: profile.telefone,
        descricao: profile.descricao,
        sobrenome: profile.sobrenome,

        tiktok: profile.linkTiktok,
        twitter: profile.linkTwitter,
        youtube: profile.linkYoutube,
        linkedin: profile.linkLinkedin,
        instagram: profile.linkInstagram,

        uf: profile.uf,
        cidade: profile.cidade,
        ocupacao: profile.ocupacao,
        dataNascimento: profile.dataNascimento
          ? this.$f.converterDataString(profile.dataNascimento)
          : "",
      };
    },
  },

  watch: {
    user() {
      this.getDataInfos();
    },
  },

  computed: {
    citiesList() {
      if (!this.profile?.uf) return [];
      return ufs?.find(({ uf }) => uf === this.profile?.uf)?.cities;
    },

    ...mapGetters({
      infos: "perfil/getPerson",
      person: "perfil/getPerson",
      user: "registration/getUser",
      softwares: "softwares/getAll",
      isLoadingData: "perfil/isLoading",
      getsoftwares: "perfil/getsoftwares",
    }),
  },
};
</script>

<style lang="sass" scoped>
.small
  border-radius: 15px
  min-width: 100px
</style>
