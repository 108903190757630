<template>
  <div id="app" :class="isLogged ? 'container-fluid' : 'container-fluid px-0 overflow-hidden'
    ">
    <div class="row justify-content-center">
      <Pesquisa v-if="isLogged" />
      <BlackFriday v-if="isLogged && $isBlackFriday" />

      <Menu v-if="isLogged" class="col-auto px-0 width-menu-mobile" />
      <router-view class="col width-mobile" :style="`width: calc(100% - ${400}px)`" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "@/components/shared/menu/Menu";
import Pesquisa from "@/components/shared/pesquisa/Pesquisa";
import BlackFriday from "@/components/shared/blackfriday/BlackFriday";

export default {
  name: "App",

  components: { Menu, Pesquisa, BlackFriday },

  async created() {
    this.$store.dispatch("configs/setWidth", window.innerWidth);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$enable) {
      this.bloquearBotoesInspect();
      this.bloquearCliqueDireito();
    }
  },

  methods: {
    bloquearCliqueDireito() {
      if (document.addEventListener) {
        document.addEventListener("contextmenu", (e) => {
          e.preventDefault();
          return false;
        });
      } else {
        document.attachEvent("oncontextmenu", (e) => {
          e = e || window.event;
          e.returnValue = false;
          return false;
        });
      }
    },

    bloquearBotoesInspect() {
      document.addEventListener("keydown", (e) => {
        const isCtrl = !!e.ctrlKey;
        const isMetaAlt = !!e.metaKey && !!e.altKey;
        const isMetaShift = !!e.metaKey && !!e.shiftKey;
        const isCtrlShift = isCtrl && !!e.shiftKey;

        const isInspect =
          e.keyCode === 123 ||
          (isCtrl && e.keyCode === 85) ||
          (isMetaShift && e.keyCode === 67) ||
          (isCtrlShift && e.keyCode === 73) ||
          (isCtrlShift && e.keyCode === 74) ||
          (isCtrlShift && e.keyCode === 67) ||
          (isMetaAlt === true && e.keyCode === 73) ||
          (isMetaAlt === true && e.keyCode === 74) ||
          (isMetaAlt === true && e.keyCode === 67) ||
          (isMetaAlt === true && e.keyCode === 85);

        if (isInspect) this.logut();
      });
    },

    logut() {
      if (!this.$store.getters["registration/getUser"].id) return;

      localStorage.setItem("tokenPlataformaEducando", false);
      this.$store.dispatch("registration/logOut");
      this.$router.push({ name: "login" });
      location.reload();
    },

    handleResize() {
      this.$store.dispatch("configs/setWidth", window.innerWidth);
    },
  },

  computed: {
    ...mapGetters({
      menuIsOpen: "configs/menuIsOpen",
    }),

    menuWidth() {
      const menu = document.getElementById("menu");
      if (!menu) return 0;
      return menu.offsetWidth;
    },

    isLogged() {
      const routeName = this.$route.name;
      const toIgnore = ["token", "login", "recover", "empresas"];
      return !toIgnore.includes(routeName);
    },
  },
};
</script>

<style lang="sass">
.max-1000
  max-width: 1000px !important
.loading-btn
  width: 15px
  height: 15px
  border-radius: 50%
  border: 1px dashed #777
  animation: rotate 1500ms infinite
  margin: auto
@keyframes rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

$pink: #ff4163
.font-10
  font-size: 10px !important
%btn
  border-radius: 25px
  padding-left: 10px
  color: white
  font-size: 14px
  padding-right: 10px
  transition: all .15s
  &:hover
    opacity: .85

.btn-fill
  background-color: $pink
  border: none !important
  @extend %btn

.btn-transparent
  @extend %btn
  border: 1px solid $pink
  background-color: transparent
.ql-formats
  scale: .85
  margin-right: 5px !important
  margin-bottom: 6px !important
.btn-verde
  fill: white !important
  background-color: #009a00
  width: fit-content
  font-size: 13px
  cursor: pointer
  height: 28px
  border-radius: 15px
  padding: 0px 10px !important
  transition: all .15s
  box-shadow: 0px 0px 1px 4px rgba(255,65,99,0) !important
  &:hover
    opacity: .85
.enter-effect
  animation: enter-animation 700ms

@keyframes enter-animation
  0%
    opacity: 0
    margin-top: -50px
  100%
    opacity: 1

.enter-effect-opacity
  animation: enter-animation-opacity 500ms

@keyframes enter-animation-opacity
  0%
    opacity: 0
  100%
    opacity: 1
.overflow-hidden
  max-width: 100%
  overflow: hidden
.scroll
  overflow-y: auto !important
  overflow-x: hidden
.bg-dark
  background-color: #151515 !important
.bg-btn-pink
  background-color: $pink !important
  color: white
  display: inline-flex
  line-height: normal
  justify-content: center
  align-items: center
  padding: 4px 7px 3px 7px !important
  border-radius: 5px
  font-size: 11px !important
  &:hover
    color: white !important
    opacity: .75
.modal
  background-color: rgba(0, 0, 0, 0.85) !important
.modal-backdrop
  z-index: -1 !important
  opacity: 0 !important
.hovered:hover
  transition: all .25s
  color: $pink !important
a
  text-decoration: none !important
  transition: all .25s !important
  &:hover
    color: $pink !important
    li, button, p, h1,h2,h3,h4,h5,h6, span
      color: $pink !important
li, button, p, h1,h2,h3,h4,h5,h6, span, div, img
  &::selection
    color: #fff
    background-color: #ff416366
.min
  min-height: 100vh
.max
  max-height: 100vh
.font-20
  font-size: 20px !important
.font-16
  font-size: 16px !important
.font-14
  font-size: 14px !important
.font-13
  font-size: 13px !important
.font-18
  font-size: 18px !important
.font-12
  font-size: 12px !important
.font-11
  font-size: 11px !important
.border-radius-50
  border-radius: 50% !important
.swal2-loader
  border-color: $pink transparent $pink transparent !important
.swal2-title
  font-size: 22px !important
.swal2-styled
  font-size: 14px !important
  padding-top: 2px !important
  padding-bottom: 0px !important
  padding-inline: 1.1em !important
.checkmark
  accent-color: $pink
.border-radius-10
  border-radius: 10px !important
  overflow: hidden
#app, body
  background-color: #000
.white
  color: #fff !important
.secondary
  color: #c5c5c5
.bg-email-field
  background-color: #191c21
.popup-top
  background-color: #fffef2
.pink
  color: $pink
.border-none
  border: none !important
.bg-pink
  background-color: $pink
  &:hover, &:active, &:focus
    background-color: $pink !important
    opacity: .85
.dark-grey
  color: #1b1b1b
.dark-grey-2
  color: #646464
.color-input
  color: #c5c5c5
%default-input
  width: 100%
  background-color: transparent
  border: none
  border-bottom: 1px solid #404040 !important
  font-size: 14px
  @extend .color-input
  &:focus
    box-shadow: -0px 4px 4px -2px rgba(255, 65, 99, .5)
    border-bottom: 1px solid $pink !important
    outline: none !important
  &:disabled
    @extend .dark-grey-2
.input-modal, .select-modal
  @extend %default-input

@media (max-width: 600px)
  #main-container
    overflow: hidden
  .title
    font-size: 18px !important
    height: 18px !important
  .min
    min-height: 35vh
  .width-mobile
    width: 85% !important
  .width-menu-mobile
    width: 15% !important
  .label-modal
    font-size: 14px !important
  .bg-dark-mobile
    background-color: #151515
  .btn-fill, .btn-transparent
    font-size: 10px !important
.is-invalid
  animation: is-invalid 400ms
@keyframes is-invalid
  0%
    margin-left: -10px
  25%
    margin-left: 10px
  50%
    margin-left: -10px
  75%
    margin-left: 10px
  100%
    margin-left: -10px
.btn-pink
  background-color: $pink !important
.bg-free
  background: #888 !important
  // background: #02b1f0
.status-tag
  width: 8px !important
  right: 0px !important
  height: 8px !important
  bottom: 5px !important
  border-radius: 50% !important
.block-pointer-events
  user-drag: none
  pointer-events: none
.with-events
  user-drag: initial !important
  pointer-events: initial !important
</style>
