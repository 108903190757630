var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:['menu-container min max pb-3', _vm.menuIsOpen ? 'max-width' : '']},[(_vm.width > 700 || _vm.notificacoesIsOpen)?_c('ToggleMenu',{attrs:{"notificacoes-is-open":_vm.notificacoesIsOpen},nativeOn:{"click":function($event){return _vm.toggleMenu()}}}):_vm._e(),_c('Notificacoes',{attrs:{"is-open":_vm.notificacoesIsOpen},on:{"closeCollapse":function($event){return _vm.toggleMenu()}}}),_c('div',{class:['scroll', _vm.blockScroll ? 'no-scroll' : '']},[_c('div',{staticClass:"menu-content"},[_c('Header'),_vm._l((_vm.orderedMenu),function(item){return _c('div',{key:item.name,class:[_vm.isMobile ? 'mb-3' : '']},[(item.linkTo)?_c('div',{on:{"click":function($event){return _vm.openCollapse(item)}}},[_c('router-link',{attrs:{"to":{
              name: item.isBloqued ? 'home' : item.linkTo,
              params: item.params,
            }}},[_c('MenuItem',{attrs:{"notificacoes-is-open":_vm.notificacoesIsOpen,"item":item}})],1)],1):_c('div',[_c('div',{on:{"click":function($event){return _vm.openCollapse(item)}}},[_c('MenuItem',{attrs:{"notificacoes-is-open":_vm.notificacoesIsOpen,"item":item}})],1)]),(item.collapse && !item.right)?_c('div',{class:[
            'ps-4 pb-2',
            _vm.isOpenCollapse ? 'open-menu-collapse' : 'close-menu-collapse' ]},_vm._l((item.collapses),function(subitem,id){return _c('div',{key:("subitem-" + id),on:{"click":function($event){return _vm.openCollapse(subitem)}}},[_c('router-link',{attrs:{"to":{ name: subitem.isBloqued ? 'home' : subitem.linkTo }}},[_c('MenuItem',{attrs:{"notificacoes-is-open":_vm.notificacoesIsOpen,"item":subitem}})],1)],1)}),0):_vm._e()])}),_c('p',{class:[
          'dark-grey-2 font-12 mt-4 hovered pointer mb-2',
          _vm.menuIsOpen ? 'desconectar' : 'desconectar-closed' ],on:{"click":function($event){return _vm.logOut()}}},[_vm._v(" Sair ")]),_c('span',{staticClass:"font-10 dark-grey-2"},[_vm._v(" v. "+_vm._s(_vm.$version)+" ")])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }