<template>
  <div>
    <div class="title-bordered mb-4 mt-4 mt-md-0">
      <h4 class="title">Meus certificados</h4>
    </div>

    <div class="col-12 pb-5">
      <div v-if="isLoading" class="min-height">
        <LoadingComp />
      </div>
      <div
        class="row enter-effect"
        v-else-if="certificados && certificados.length"
      >
        <div
          v-for="(certificado, ind) in certificados"
          :key="`ceritificado-${ind}`"
          class="col-md-6 col-12"
        >
          <CardCertificados
            @gerarCertificado="gerarCertificado"
            :certificado="certificado"
          />
        </div>
      </div>
      <div v-else class="row justify-content-center">
        <img
          class="sem-conquistas mb-3"
          src="@/assets/images/wins/baby_yoda.png"
        />
        <p class="text-center">
          Ainda não há certificados disponíveis. Eles estarão <br />disponíveis
          para download aqui assim que você <br />finalizar um curso
        </p>
      </div>
    </div>
    <Impressao
      :certificado="impressao"
      :gerar-certificado="gerarCertificado"
      ref="impressao"
      id="bg-white"
      class="bg-white"
    />
  </div>
</template>

<script>
import Impressao from "./Impressao";
import CardCertificados from "./CardCertificados";
import { mapGetters } from "vuex";

export default {
  name: "CertificadosComp",
  components: {
    Impressao,
    CardCertificados,
  },

  data() {
    return {
      first: false,
      isLoading: true,
      impressao: {},
    };
  },

  async created() {
    await this.$store.dispatch("registration/setCertificados", {
      resource: this.$resource,
    });
    this.isLoading = false;
  },

  methods: {
    gerarCertificado(certificado) {
      this.impressao = certificado;
      this.$refs.impressao.generatePdf();
    },
  },

  computed: {
    ...mapGetters({
      certificados: "registration/getCertificados",
    }),
  },
};
</script>
<style lang="sass" scoped>
.min-height
  min-height: 70vh !important
  display: inline-flex
  align-items: center
  width: 100%
  justify-content: center
.opacity-25
  transition: all .15s
  opacity: .35 !important
  &:hover
    opacity: .85 !important
.sem-conquistas
  width: 200px
</style>
