<template>
  <div @keydown.esc="closeModal()">
    <button
      data-toggle="modal"
      class="d-none"
      data-target="#modal-picture"
      id="btn-modal-picture"
    />

    <div
      tabindex="-1"
      role="dialog"
      class="modal fade"
      id="modal-picture"
      @click.self="closeModal()"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div
            class="arrows"
            v-if="allGalery.length > 1 && !isCdn"
          >
            <div
              class="arrow-l"
              @click="selectNextAlbum()"
            />
            <div
              class="arrow-r"
              @click="selectPreviousAlbum()"
            />
          </div>

          <div class="modal-body pt-0 pt-md-1 pb-1">
            <div
              class="row"
              v-if="album.albumId || isCdn"
            >
              <div class="col-lg-7 col-md-5 mb-4 mb-md-0 col-12 p-0 p-md-2 pe-md-0 height-content height-slides">
                <div class="w-100 text-end">
                  <CloseIcon
                    class="d-md-none"
                    data-toggle="modal"
                    data-target="#modal-picture"
                  />
                </div>
                <div class="position-relative w-100 h-100 height-sm-0 bg-black">
                  <ModalGaleryImage
                    :list="album.imagens"
                  />
                </div>
              </div>

              <div class="col-12 col-md-7 col-lg-5 px-0 mt-3 mt-md-0">
                <div class="content-grid height-content">
                  <ModalGaleryHeader
                    :is-cdn="isCdn"
                    ref="ModalGaleryHeader"
                    :album="album"
                    :aluno="album.aluno"
                  />

                  <ModalGaleryBody
                    :body="album"
                    :is-cdn="isCdn"
                    class="px-md-3 px-1"
                  />

                  <ModalGaleryFooter
                    :is-cdn="isCdn"
                    class="px-md-3 px-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import softwares from '@/assets/consts/softwares'
import CloseIcon from '@/assets/icons/CloseIcon'
import ModalGaleryHeader from './ModalGaleryHeader'
import ModalGaleryImage from './ModalGaleryImage'
import ModalGaleryBody from './ModalGaleryBody'
import ModalGaleryFooter from './ModalGaleryFooter'
import GaleryService from '@/service/galeryService'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalPicture',

  props: {
    isCdn: {
      type: Boolean,
      default: false
    }
  },

  components: {
    CloseIcon,
    ModalGaleryBody,
    ModalGaleryHeader,
    ModalGaleryFooter,
    ModalGaleryImage
  },

  data () {
    this.GaleryService = new GaleryService(this.$resource)
    return {
      isView: [],
      softwares: softwares.slice(0, 3)
    }
  },

  computed: {
    ...mapGetters({
      album: 'galery/getAlbum',
      allGalery: 'galery/getGalery'
    })
  },

  methods: {
    ...mapActions({
      setAlbum: 'galery/setAlbum',
      nextAlbum: 'galery/nextAlbum',
      previousAlbum: 'galery/previousAlbum'
    }),

    selectNextAlbum () {
      this.nextAlbum()
      this.changeRoute()
    },

    selectPreviousAlbum () {
      this.previousAlbum()
      this.changeRoute()
    },

    changeRoute () {
      const nextRoute = {
        name: this.$route.name,
        params: {
          album: this.album.albumId,
          user: this.album.aluno.id,
          image: this.album.images?.[0]?.id
        }
      }
      this.$router.push(nextRoute)

      const to = `image_${this.album.albumId}`
      document.getElementById(to)?.scrollIntoView({ behavior: 'smooth' }, true)
    },

    closeModal () {
      this.setAlbum({})
      const noParams = ['galeria', 'home']
      const isGalery = noParams.includes(this.$route.name)
      const params = isGalery ? {} : { user: this.$route.params.user }
      this.$router.push({ name: this.$route.name, params }).catch(() => {})
    },

    incrementView () {
      const idGalery = this.$route.params.album
      if (!idGalery || this.isView.includes(idGalery)) return

      this.isView.push(idGalery)
      this.GaleryService.incrementExibition({ idGalery })
    }
  },

  watch: {
    '$route.params.album': {
      deep: true,
      handler (album) {
        const isGaleryPage = this.$route.name === 'galeria'
        if (!isGaleryPage) return

        this.incrementView(album)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
$distance: -50px
$colorArrow: #ff4163

%arrow
  cursor: pointer
  position: absolute
  z-index: 999999
  top: 50%
  width: 20px
  height: 20px
  transform: translateY(-50%)
  &:before
    content: ''
    width: 20px
    height: 20px
    position: absolute
    border-top: 4px solid $colorArrow
    border-right: 4px solid $colorArrow

.arrows
  .arrow-l
    @extend %arrow
    left: $distance
    &:before
      transform: rotate(220deg)
  .arrow-r
    @extend %arrow
    right: $distance
    &:before
      transform: rotate(45deg)

@media (min-width: 992px)
  .modal-lg
    max-width: 1000px !important
@media (min-width: 576px)
  .modal-dialog
    max-width: 95%
@media (max-width: 600px)
  .height-content
    height: auto !important
  .content-grid
    display: block !important
  .height-slides
    height: 30vh !important

.modal-content
  background-color: #1f1f1f
.img-post
  max-width: 100%
  max-height: 100%
  position: absolute
  top: 50%
  left: 50%
  transform: translateY(-50%) translateX(-50%)
.height-100
  height: 100%
.height-content
  height: calc(90vh - 32px)
.content-grid
  display: grid
  grid-template-rows: auto 1fr auto
  max-height: 85vh
</style>
