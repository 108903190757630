const SET_NOVIDADES = 'SET_NOVIDADES'

const state = {
  novidades: [],
  isLoading: false
}

const actions = {
  setNovidades ({ commit }, novidades) {
    commit(SET_NOVIDADES, { novidades })
  },
  setIsLoading ({ commit }, isLoading) {
    commit(SET_NOVIDADES, { isLoading })
  }
}

const getters = {
  getNovidades: (state) => {
    return state.novidades
  },
  isLoading: (state) => {
    return state.isLoading
  }
}

const mutations = {
  [SET_NOVIDADES] (state, novidades) {
    Object.assign(state, novidades)
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
