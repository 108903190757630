<template>
  <div class="personal-infos text-center">
    <div class="position-relative perfil-image-content">
      <TagMode
        :isPro="dadosEducando.assinatura === 'P'"
        class="position-absolute tag"
      />

      <img
        class="perfil-image"
        :src="imgPerfil || ''"
        @error="hasError = true"
        v-if="!hasError"
      />

      <div
        v-else
        class="perfil-image font-20 row align-items-center justify-content-center letter upper"
      >
        {{ nomeEducando[0] }}
      </div>
    </div>

    <h6 class="text-center font-14">
      {{ nomeEducando }}
    </h6>

    <p class="text-center font-13 mb-1">
      {{ dadosEducando.email }}
    </p>

    <div class="row justify-content-center mb-1">
      <div
        class="col-auto px-1"
        v-for="(conquista, ind) in conquistas"
        :key="`conquista-perfil-${ind}`"
      >
        <img
          :title="conquista.titulo"
          class="img-perfil"
          :src="conquista.src"
        />
      </div>
    </div>

    <p class="text-center dark-grey-2 font-12 mb-2">
      {{ enderecoEducando }}
    </p>

    <button
      v-if="isCurrentUser"
      @click="$router.push({ name: 'dados' })"
      class="bg-btn-pink btn w-100 font-14 my-3 border-radius-10"
    >
      Editar perfil <PenIcon />
    </button>

    <button
      v-else
      @click="followPerson"
      :disabled="isLoadingFollower"
      :class="[
        'btn w-100 font-14 my-3 border-radius-10 white center',
        perfil.following ? 'btn-transparent' : 'bg-btn-pink',
      ]"
    >
      <div class="loading-btn" v-if="isLoadingFollower" />
      <span v-else>
        {{ perfil.following ? "Deixar de seguir" : "Seguir" }}
      </span>
    </button>
  </div>
</template>

<script>
import Mixins from "@/Mixins";
import PenIcon from "@/assets/icons/Pen";
import FollowService from "@/service/followService";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [Mixins],

  components: {
    PenIcon,
  },

  props: {
    isCurrentUser: {
      type: Boolean,
      default: false,
    },

    conquistas: {
      type: Array,
      default: () => [],
    },

    dadosEducando: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    this.followService = new FollowService(this.$resource);

    return {
      hasError: false,
      isLoadingFollower: false,
    };
  },

  created() {
    this.checkIsFollowing();
  },

  computed: {
    ...mapGetters({
      perfil: "perfil/getPerson",
      currentUser: "registration/getUser",
      followers: "followers/getFollowers",
    }),

    imgPerfil() {
      return this.mountImagePath(
        "educando",
        this.$route.params.user,
        this.dadosEducando.extensaoImagem
      );
    },

    nomeEducando() {
      const { nome, sobrenome } = this.dadosEducando;
      return `${nome || ""} ${sobrenome || ""}`;
    },

    enderecoEducando() {
      const { cidade, uf } = this.dadosEducando;
      return `${cidade || ""} ${cidade && uf ? "-" : ""} ${uf || ""}`.trim();
    },

    isCurrentPerfil() {
      return Number(this.perfil.id) === Number(this.$route.params.user);
    },
  },

  methods: {
    ...mapActions({
      setFollowingPerfil: "perfil/setFollowing",
      setIsFollowing: "followers/setIsFollowing",
      setFollowingsCount: "perfil/setFollowingsCount",
    }),

    async checkIsFollowing() {
      if (this.isLoadingFollower) return;

      this.isLoadingFollower = true;
      const { body } = await this.followService.following({
        id: this.$route.params.user,
      });

      const isFollowing = Boolean(Array.isArray(body) && body.length);
      this.setFollowingPerfil(isFollowing);

      this.isLoadingFollower = false;
    },

    async followPerson() {
      const idEducando = Number(this.$route.params.user);
      this.isLoadingFollower = true;

      if (this.perfil.following)
        await this.followService.unfollow({ idEducando });
      if (!this.perfil.following)
        await this.followService.follow({ idEducando });
      if (this.isCurrentPerfil && !this.isCurrentUser)
        this.setFollowingsCount(this.perfil.following);

      this.setFollowingPerfil(!this.perfil.following);
      this.setIsFollowing(this.perfil);

      this.isLoadingFollower = false;
    },
  },

  watch: {
    "$route.params.user": {
      deep: true,
      handler(selectedUser) {
        if (Number(selectedUser || "0") === Number(this.perfil.id || "0"))
          return;
        this.checkIsFollowing();
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.personal-infos
  .tag
    left: 80%
    bottom: 0px
    transform: translateX(-50%) scale(.85)

  .img-perfil
    width: 25px
    height: 25px
    border-radius: 50%
    object-fit: contain
  .perfil-image-content
    width: 85px
    height: 85px
    margin-top: -50px
    margin-left: auto
    margin-right: auto
    border-radius: 50%
    margin-bottom: 8px
    background-color: #000
    border: 2px solid #000

    .perfil-image
      width: 100%
      height: 100%
      object-fit: cover
      border-radius: 50%
</style>
