<template>
  <section />
</template>

<script>
import UsuarioService from '@/service/userService'
import Vue from 'vue'

export default {
  name: 'TokenComp',
  data () {
    this.UsuarioService = new UsuarioService(this.$resource)
    return {}
  },

  created () {
    const token = this.$route.params.token
    const uuid = this.$route.params.uuid
    Vue.http.headers.common.Authorization = `Bearer ${token}`
    localStorage.setItem('tokenPlataformaEducando', token)
    localStorage.setItem('uuidPlataformaEducando', uuid)
    this.$router.push({ name: 'home' })
  }
}
</script>
