<template>
  <div class="column">
    <div
      class="col label-modal"
      v-if="label"
    >
      <label>{{ label }}</label>
    </div>

    <div
      class="col"
      :height="height"
    >
      <multiselect
        label="name"
        v-model="field"
        track-by="name"
        class="relative"
        :multiple="true"
        selected-label=""
        :options="options"
        placeholder="Selecione"
        :preselect-first="false"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        select-label="Clique para Selecionar"
        deselect-label="Clique para Remover"
      >
        <template slot="tag">
          <span
            class="multiselect__single color-input font-12"
          >{{ field ? field.length : '' }} Selecionado{{ plural }}</span>
        </template>

        <template
          slot="option"
          slot-scope="props"
        >
          <div class="option__desc">
            <img
              class="option__image"
              :src="props.option.img"
            >
            <span class="option__title">
              {{ props.option.name }}
            </span>
          </div>
        </template>

        <template
          slot="selection"
          slot-scope="{ isOpen }"
        >
          <span
            class="multiselect__single color-input font-13"
            v-if="field.length && !isOpen"
          >{{ field.length }} Selecionado{{ plural }}</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },

  props: {
    list: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 30
    }
  },

  data () {
    return {
      options: this.list,
      field: this.list.filter(({ id }) => this.value.includes(id)) || []
    }
  },

  computed: {
    plural () {
      return this.field && this.field.length > 1 ? 's' : ''
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        const values = this.options?.filter(({ id }) => value.includes(id))
        if (this.$f.isEqual(values, this.field)) return
        this.field = values
      }
    },

    field: {
      handler (field) {
        const fields = field?.map(({ id }) => id)
        if (this.$f.isEqual(fields, this.value)) return
        this.$emit('input', fields)
      }
    },

    list: {
      handler (list) {
        this.options = list
      }
    }
  }
}
</script>

<style lang="sass">
%height
  min-height: 30px !important
  max-height: 30px !important

%height36
  min-height: 36px !important
  max-height: 36px !important

div[height="36"]
  .multiselect__content-wrapper
    top: 35px !important
  .multiselect, .multiselect__select, .multiselect__tags
    @extend %height36
  .multiselect__single
    margin-top: 4px
  .multiselect__placeholder
    margin-top: 4px

div[height="30"]
  .multiselect__single
    margin-top: 2px
  .multiselect__content-wrapper
    top: 30px !important
  .multiselect, .multiselect__select, .multiselect__tags
    @extend %height

.tag
  background-color: #ff4163
  border-radius: 2px
  padding: 2px 8px 2px 4px
  display: inline-block
  font-size: 12px
  color: #fff
  .img
    max-width: 25px
.option__image
  width: 30px !important
  height: 30px !important
  margin-right: 5px
.multiselect
  border-radius: 2px
  box-sizing: border-box
  background: transparent
  border: 1px solid #606060 !important
  position: relative
  z-index: 10
.multiselect__option--selected.multiselect__option--highlight
  background-color: #ff416311
  &::after
    background-color: transparent

  .option__desc
    .option__title
      color: #fff !important
.option__title
  color: #c5c5c5
  font-size: 14px
.multiselect__content-wrapper
  border: 1px solid #ff4163 !important
  border-top: none !important
  box-shadow: -0px 4px 4px -2px rgba(255, 65, 99, .5)
  outline: none !important
.multiselect__input
  background: transparent
  color: #646464
.multiselect__option:after
  top: 50%
  line-height: 100% !important
  transform: translateY(-50%)
.multiselect__option--highlight
  background-color: #0003
  &::after
    background-color: transparent
  .option__desc
    .option__title
      line-height: 8px !important
      color: #fff
.multiselect__option
  padding: 8px 12px
  min-height: 30px
.multiselect__option--selected
  background-color: transparent
  .option__desc
    .option__title
      color: #ff4163 !important

.multiselect__content
  background-color: #1f1f1f
.multiselect__placeholder
  color: #646464
  margin-bottom: 0px
  padding-top: 0px
  vertical-align: text-top
.multiselect__select
  top: -1px
  @extend %height
.multiselect__tags
  background: transparent
  border: none !important
  @extend %height
  padding-top: 0px !important
  align-self: center
.multiselect__single
  background-color: inherit
  margin-bottom: 0px !important
  vertical-align: text-top
</style>
