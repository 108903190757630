<template>
  <div :class="fatherClass">
    <div v-if="showInfos">
      <div v-for="(image, ind) in images" :key="`${ind}-image-${image.nome}`" class="px-2">
        <ModalCourse :id="camelize(image.nome)" v-if="image.detalhesCorpoModal" :detalhes="image.detalhesCorpoModal" />
      </div>
    </div>

    <div class="content-slider">
      <div v-if="images.length > slidesToShow && rows === 1 && slideDefault" />
      <div v-for="row in rows" :key="`row-${row}`">
        <VueSlickCarousel v-bind="settings">
          <slot v-if="hasSlot" name="slider" />

          <div v-for="(item, ind) in getImages(row)" :key="`${ind}-${item.nome}`"
            :class="[slideDefault ? 'px-2 pt-2 mb-3' : 'px-0 pt-0 mb-0']">
            <div v-if="!item.linkExterno && !isIframe" :class="['banner', showInfos ? 'new-banner' : '']">
              <div v-if="showInfos" class="w-100">
                <div class="course-progress" v-positionPercentage="item">
                  <div class="content-img">
                    <div class="tag-mode">
                      <TagMode :is-pro="!!item.isPro" />
                    </div>
                    <img :src="item.image" class="image-course block-pointer-events" />
                  </div>
                  <div v-if="item.tempoConcluido" class="course-started">
                    <div class="tooltip font-12">
                      {{ progressBarPercentage(item, true) }}
                    </div>

                    <div class="progress-bar" :style="`--progress-bar-percentage: ${progressBarPercentage(
                      item,
                      true
                    )}`" />
                  </div>
                </div>

                <div class="conten-info">
                  <h6 class="bold font-13 px-1 dark-grey-2">
                    <span class="font-13 text-white">
                      {{ item.nome }}
                    </span>
                    <span class="font-13 weight-400 dark-grey-2">
                      - {{ item.textoCurtoApresentacao }}
                    </span>
                  </h6>
                </div>

                <div class="row content-button mx-auto m-0 mt-1">
                  <div class="col-auto px-1">
                    <!-- <a :href="$checkoutAnual" v-if="!!item.isPro">
                      <BtnPink
                        label="Comprar"
                        class="bg-btn-pink zoom-negative"
                      />
                    </a> -->

                    <router-link :to="{
                      name: 'video',
                      params: {
                        id: item.id,
                        modulo: 'search',
                        video: 'search',
                        idTemporadaVideo: 'search',
                      },
                    }">
                      <BtnPink label="Assistir" class="bg-btn-pink zoom-negative" />
                    </router-link>
                  </div>

                  <div v-if="item.detalhesCorpoModal" class="col-auto px-1 mb-1">
                    <BtnPink data-toggle="modal" :data-target="`#${camelize(item.nome)}`"
                      class="bg-btn-pink zoom-negative" label="Detalhes" />
                  </div>
                </div>
              </div>

              <router-link v-else-if="!hasLink" :to="{
                name: 'video',
                params: {
                  id: item.id,
                  modulo: 'search',
                  video: 'search',
                  idTemporadaVideo: 'search',
                },
              }">
                <img loading="lazy" class="image-course block-pointer-events" :src="item.image" />
              </router-link>

              <a v-else-if="item.link" :href="item.link || ''" target="_blank">
                <img loading="lazy" class="image-course block-pointer-events" :src="item.image" />
              </a>

              <img v-else loading="lazy" class="image-course block-pointer-events" :src="item.image" />
            </div>

            <div class="position-relative" v-else-if="item.linkExterno && !isIframe">
              <a :style="item.linkExterno === '.' ? 'pointer-events: none' : ''"
                :target="item.isPro && !user.isPro ? '_blank' : ''" :href="item.isPro && !user.isPro ? $checkoutAnual : item.linkExterno
                  ">
                <!-- <TagMode
                  :is-pro="item.isPro"
                  class="position-absolute tag-mode-banner"
                /> -->
                <img :src="item.image" :class="[
                  'image-course block-pointer-events',
                  notZoom ? 'has-not-zoom' : '',
                ]" />
              </a>
            </div>

            <div v-else-if="isIframe">
              <component :is="child" :item="item" />
            </div>
          </div>

          <template #prevArrow>
            <ButtonSlider v-if="arrowLeft" class="arrow-left" />
            <span v-else></span>
          </template>

          <template #nextArrow>
            <ButtonSlider v-if="arrowRight" class="arrow-right" />
            <span v-else></span>
          </template>

          <template #customPaging>
            <div class="custom-dot" />
          </template>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import BtnPink from "@/components/shared/forms/ButtonPink";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ModalCourse from "@/components/shared/modal/ModalCourse";
import ButtonSlider from "@/components/shared/forms/ButtonSlider";

export default {
  name: "SliderComp",
  components: {
    VueSlickCarousel,
    ButtonSlider,
    BtnPink,
    ModalCourse,
  },

  props: {
    slideDefault: {
      default: true,
    },

    child: {},

    internalArrowLeft: {
      default: false,
    },

    arrowLeft: {
      default: true,
    },

    arrowRight: {
      default: true,
    },

    internalArrowRight: {
      default: false,
    },

    isLocalImage: {
      type: Boolean,
      default: false,
    },

    images: {
      type: Array,
      default: () => [],
    },

    slidesToShow: {
      type: Number,
      default: 1,
    },

    showInfos: {
      type: Boolean,
      default: false,
    },

    dots: {
      type: Boolean,
      default: false,
    },

    rows: {
      type: Number,
      default: 1,
    },

    hasLink: {
      type: Boolean,
      default: false,
    },

    settingsProps: {
      type: Object,
      default: () => { },
    },

    fatherClass: {
      type: String,
      default: "",
    },

    notZoom: {
      default: false,
    },

    isIframe: {
      default: false,
    },
  },

  directives: {
    positionPercentage: {
      inserted: function (el, binding) {
        if (binding.value.tempoConcluido) {
          const minPercentual = Math.ceil((16 / el.clientWidth) * 100);
          const maxPercentual = 100 - (24 / el.clientWidth) * 100;
          let result = Math.trunc(
            (binding.value.tempoConcluido / binding.value.tempoTotalDuracao) *
            100
          );
          result = result > 100 ? 100 : result;
          el.getElementsByClassName("tooltip")[0].style.left =
            result < minPercentual
              ? `${minPercentual}%`
              : result > maxPercentual
                ? `${maxPercentual}%`
                : `${result}%`;
        }

      },
    },
  },

  methods: {
    getImages(ind) {
      const ref = --ind;
      if (this.rows > 1) {
        const inicial = ref * this.slidesToShow;
        const final = inicial + this.slidesToShow;
        return this.images.slice(inicial, final);
      }
      return this.images || [];
    },

    camelize(text) {
      return text
        .split(" ")
        .map((w) => w.replace(/./, (m) => m.toUpperCase()))
        .join("");
    },

    progressBarPercentage(item, val = null) {
      const result = Math.trunc(
        (item.tempoConcluido / item.tempoTotalDuracao) * 100
      );
      return `${result > 100 ? 100 : result}%`;
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      isMobile: "configs/isMobile",
    }),

    hasSlot() {
      return this.$slots["slider"];
    },

    settings() {
      if (this.settingsProps) return this.settingsProps;
      return {
        speed: 500,
        infinite: true,
        dots: this.dots,
        slidesToShow: this.slidesToShow,
        slidesToScroll: this.slidesToShow,
      };
    },
  },
};
</script>

<style lang="sass">
::-webkit-scrollbar
  width: 2px

.tag-mode-banner
  top: 10px
  right: 13px
  z-index: 9
  position: absolute
  transform: scale(1.2)

.tag-mode
  top: 2px
  right: 4px
  position: absolute
  transform: scale(.85)
.scroll
  height: 100%
.new-banner
  padding-bottom: 1px
  .course-progress
    overflow: hidden
    position: relative
    border-radius: 10px
    .content-img
      background-color: #202020
      img
        cursor: default
    .course-started
      .tooltip
        bottom: 0
        opacity: 1
        background: black
        padding: 0px 10px
        border-radius: 5px
        transform: translate(-50%, -35%)
      .progress-bar
        height: 3px
        background-color: #808080
        &::before
          content: ""
          height: 5px
          background-color: #ff4163
          width: var(--progress-bar-percentage)
  .conten-info
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 2
    text-overflow: ellipsis
    -webkit-box-orient: vertical
    h6
      min-height: 40px
      padding-top: 10px
  .content-button
    button
      width: 4rem
      text-transform: capitalize
      background-color: #3c3c3c !important
.slick-dots
  .slick-next
    right: -20px !important
  .slick-active
    .custom-dot:before
      width: 20px
      border-radius: 3px
      background-color: #fff
  .custom-dot
    width: 100%
    bottom: 50px !important
    display: inline-flex
    flex-direction: row
    position: relative
    justify-content: center
    &:before
      position: absolute
      content: ''
      width: 5px
      height: 5px
      transition: all .1s
      background-color: #ff4163 !important
      border-radius: 50%

.arrow-right
  opacity: .6
  transform: rotate(135deg) scale(.7)
  right: 15px !important
  top: 50%
  &:hover
    opacity: 1

.arrow-left
  opacity: .6
  left: 15px !important
  transform: rotate(-45deg) scale(.7)
  &:hover
    opacity: 1
.has-not-zoom
  &:hover
    transform: scale(1.0) !important

.image-course
  max-width: 100%
  margin: auto
  cursor: pointer
  transition: all .25s
.banner:not(.new-banner) .image-course:hover
  transform: scale(1.03)
  filter: grayscale(.2)
.pink:hover
  transition: all .15s
  opacity: .75
.content-slider
  @media (max-width: 500px)
    .zoom-negative
      transform: scale(.8) !important
    .bg-btn-pink
      font-size: 13px !important
      transform: scale(.78)
    p
      font-size: 12px !important
    .conten-info
      max-height: 45px !important
      padding: 5px 12px !important
    .content-button
      height: 40px
    .arrow-left
      left: 0px !important
      transform: rotate(-45deg) scale(.5)
    .arrow-right
      right: 0px !important
      top: 50%
      transform: rotate(135deg) scale(.5)
    .slick-dots
      .custom-dot
        bottom: 35px !important
</style>
