<template>
  <div
    class="softwares"
    v-if="softwares.length"
  >
    <p class="font-14">
      Softwares:
    </p>
    <img
      :key="software"
      v-for="software in softwares"
      :alt="software"
      :title="software"
      :src="require(`@/assets/images/softwares/${software}.png`)"
    >
  </div>
</template>

<script>

export default {
  name: 'SoftwaresComp',

  props: {
    softwares: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="sass" scoped>
.softwares
  padding: 0px 2px
  img
    width: 25px
    height: 25px
    border-radius: 50%
    margin-right: 2px
    margin-bottom: 2px
</style>
