<template>
  <div class="title-bordered box-certificado bg-black mb-3">
    <div class="row align-items-center">
      <div class="col-12 col-md ps-0 pe-md-3">
        <div class="banner-certificado">
          <img
            :src="
              mountImg(
                certificado.idCurso,
                certificado.extensaoBannerCurso,
                'horizontal'
              )
            "
          />
        </div>
      </div>
      <div class="col p-3 pe-4">
        <div class="conteudo-certificado">
          <h5 class="bold mb-2 upper">
            {{ certificado.curso }}
          </h5>
          <div class="row justify-content-between align-items-center">
            <div class="col">
              <span
                class="font-14 secondary pointer hovered"
                @click="gerarCertificado(certificado)"
              >
                Clique e faça o download
              </span>
            </div>
            <div
              class="col-auto pointer"
              @click="gerarCertificado(certificado)"
            >
              <Download />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Download from "@/assets/icons/Download";

export default {
  components: {
    Download,
  },

  props: {
    certificado: {},
  },

  methods: {
    mountImg(id, ext, exp) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=curso&identificador=${id}&extensao=${ext}&expressao=${exp}`;
    },
    gerarCertificado(certificado) {
      this.$emit("gerarCertificado", certificado);
    },
  },
};
</script>
<style lang="sass" scoped>
@media (max-width: 600px)
  .bg-black
    background-color: #000 !important
.title-bordered
  padding-left: 12px !important
.banner-certificado
  width: 100%
  overflow: hidden
  img
    width: 100%
h5
  border-bottom: 1px solid #1d1d1d
  padding-bottom: 2px
  font-size: 18px !important
img
  width: 100%
</style>
