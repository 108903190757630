<template>
  <div>
    <div :class="['col border-bottom-grey', menuIsOpen ? 'p-2' : '']">
      <router-link :to="{ name: 'home' }">
        <div class="text-start" v-if="menuIsOpen">
          <img class="logo-open" src="@/assets/images/logo.png" />
        </div>
        <img v-else class="logo-closed" src="@/assets/images/logo-plus.png" />
      </router-link>
    </div>

    <div :class="['col pt-2', menuIsOpen ? 'text-start' : '']">
      <div
        :class="[
          'w-100 d-inline-flex',
          menuIsOpen ? 'justify-content-start ps-3' : 'justify-content-center',
        ]"
      >
        <TagMode :is-pro="user.isPro" />
      </div>

      <ImgPerfil :is-open="menuIsOpen" />
      <ModalImageProfile />
    </div>

    <div :class="menuIsOpen ? 'pb-3 mb-4' : ''">
      <Hours :is-open="menuIsOpen" v-if="!isMobile" />
    </div>
  </div>
</template>

<script>
import ImgPerfil from "@/components/shared/menu/ImgPerfil";
import ModalImageProfile from "@/components/shared/menu/ModalImageProfile";
import Hours from "@/components/shared/menu/Hours";
import { mapGetters } from "vuex";

export default {
  name: "HeaderComp",

  components: {
    ImgPerfil,
    ModalImageProfile,
    Hours,
  },

  computed: {
    ...mapGetters({
      isMobile: "configs/isMobile",
      user: "registration/getUser",
      menuIsOpen: "configs/menuIsOpen",
    }),
  },
};
</script>

<style lang="sass" scoped>
@media (max-width: 500px)
  .logo-closed
    width: 25px !important
.logo-open
  width: 120px
  padding: 10px
.logo-closed
  padding-bottom: 15px
  padding-top: 15px
  width: 30px !important
.border-bottom-grey
  border-bottom: 1px solid rgb(50,50,50)
</style>
