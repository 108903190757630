// import swal from 'sweetalert2'
import swal from 'sweetalert2/dist/sweetalert2.all.min.js'
const confirmButtonColor = '#ff4163'

export default class sweetAlert {
  constructor () {
    this.swalWithBootstrapButtons = swal.mixin({
      allowOutsideClick: false,
      allowEscapeKey: false
    })
  }

  async sweetInput () {
    return swal.fire({
      input: 'email',
      inputLabel: 'Email',
      inputPlaceholder: 'Informe seu email',
      confirmButtonColor: '#ff4163'
    })
  }

  sweetNotification (msg) {
    swal.fire({
      title: '<strong>Ops, por favor confira os dados!</strong>',
      icon: 'warning',
      showCloseButton: true,
      html: `<h5>${msg}</h5>`,
      iconColor: '#ff4163',
      showCancelButton: false,
      confirmButtonColor: '#ff4163',
      showConfirmButton: true,
      background: '#222',
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok'
    })
  }

  sweetClose () {
    swal.fire({
      showConfirmButton: false,
      background: '#111',
      timer: 1
    })
  }

  sweetMensagem (msg, timer = 5111) {
    swal.fire({
      type: 'info',
      background: '#111',
      showCloseButton: true,
      html: msg,
      color: '#fff',
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonColor,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok',
      timer
    })
  }

  sweetMensagemEmpty (msg) {
    swal.fire({
      customClass: {
        container: 'p-0',
        content: 'p-0',
        closeButton: 'pink',
        htmlContainer: 'p-0'
      },
      type: 'info',
      padding: '0px',
      background: '#111',
      showCloseButton: true,
      html: msg,
      color: '#fff',
      showCancelButton: false,
      showConfirmButton: false
    })
  }

  sweetError () {
    swal.fire({
      position: 'center',
      type: 'error',
      background: '#111',
      icon: 'error',
      title: 'Ops... Algo deu errado',
      text: 'Por favor, tente novamente!',
      showConfirmButton: false,
      timer: 5111,
      showCloseButton: true,
      html: '<p><strong>Por favor, tente novamente!</p></strong><hr><p>Se o erro continuar, entre em contato com o suporte contato@projetojovemarquiteto.com.br.</p>'
    })
  }

  sweetErrorPassword () {
    swal.fire({
      position: 'center',
      type: 'error',
      background: '#111',
      icon: 'error',
      title: 'Ops... Algo deu errado',
      text: 'Por favor, tente novamente!',
      showConfirmButton: false,
      timer: 5111,
      showCloseButton: true,
      html: '<p><strong>Senha atual informada e a registrada na base não coincidem!</p></strong><hr><p>Se o erro continuar, entre em contato com o suporte contato@projetojovemarquiteto.com.br.</p>'
    })
  }

  sweetWait (msg) {
    swal.fire({
      position: 'center',
      background: '#111',
      title: 'AGUARDE',
      text: `${msg}`,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => {
        swal.showLoading()
      },
      timerProgressBar: true,
      didOpen: () => {
        swal.showLoading()
      }
    })
  }

  sweetSuccess (msg) {
    swal.fire({
      position: 'center',
      type: 'success',
      background: '#111',
      icon: 'success',
      title: `${msg}` + ' com sucesso!',
      showConfirmButton: false,
      timer: 2500
    })
  }

  sweetSuccessResp (msg, timer = 1111) {
    swal.fire({
      position: 'center',
      type: 'success',
      icon: 'success',
      background: '#111',
      html: `${msg}`,
      showConfirmButton: false,
      timer
    })
  }

  sweetQuestion (msg) {
    return this.swalWithBootstrapButtons.fire({
      title: 'Confirma a exclusão ' + `${msg}` + ' ?',
      confirmButtonText: 'Sim',
      confirmButtonColor,
      background: '#111',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Voltar',
      type: 'warning',
      icon: 'warning',
      reverseButtons: true,
      width: '450px'
    })
  }

  sweetQuestionPersonalizado (title, html) {
    return this.swalWithBootstrapButtons.fire({
      title: `${title}`,
      confirmButtonText: 'Sim',
      confirmButtonColor,
      showCancelButton: true,
      cancelButtonColor: '#999',
      cancelButtonText: 'Voltar',
      background: '#111',
      type: 'warning',
      icon: 'warning',
      html: `${html}`,
      reverseButtons: true,
      width: '450px'
    })
  }

  sweetQuestionRecoverPassowrd (confirm, cancel, html) {
    return this.swalWithBootstrapButtons.fire({
      confirmButtonText: confirm,
      confirmButtonColor,
      showDenyButton: true,
      showCancelButton: false,
      denyButtonColor: '#111',
      denyButtonText: cancel,
      background: '#111',
      type: 'success',
      icon: 'success',
      html: `${html}`,
      reverseButtons: true,
      width: '450px'
    })
  }

  sweetConfirm (msg) {
    return this.swalWithBootstrapButtons.fire({
      title: `${msg}`,
      confirmButtonText: 'Sim',
      confirmButtonColor,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Voltar',
      background: '#111',
      type: 'warning',
      icon: 'warning',
      reverseButtons: true,
      width: '450px'
    })
  }

  sweetInvalidToken () {
    return this.swalWithBootstrapButtons.fire({
      title: 'Token expirado',
      confirmButtonText: 'Ok',
      confirmButtonColor,
      showCancelButton: false,
      background: '#111',
      type: 'warning',
      icon: 'warning'
    })
  }

  sweetInvalidAccess () {
    return this.swalWithBootstrapButtons.fire({
      html: '<p>Ops, parece que outro dispositivo se conectou a sua conta. Você será redirecionada para a tela de login. Caso não tenha sido você a acessar, faça login novamente optando por atualizar o endereço de acesso e então, por segurança, atualize sua senha de acesso.</p>',
      confirmButtonText: 'Ok',
      confirmButtonColor,
      showCancelButton: false,
      background: '#111',
      type: 'warning',
      icon: 'warning'
    })
  }

  sweetCancel (msg) {
    this.swalWithBootstrapButtons.fire({
      allowOutsideClick: true,
      title: 'Cancelado',
      text: `${msg}`,
      background: '#111',
      type: 'error',
      icon: 'error',
      timer: 2500
    })
  }
}
