const baseMyGalery = 'plataforma-conteudo/minha-galeria'
const baseGaleries = 'plataforma-conteudo/galeria{/idGalery}'
const baseAllGaleries = 'plataforma-conteudo/galeria'

const baseStudents = 'plataforma-conteudo/educando{/idEducando}/galeria/listar{/qtd}/pulando{/skip}/ordenando'

export default class softwareService {
  constructor (resource) {
    const acoesCustomizadas = {
      // STUDENT *******************************************************************************************************************************
      // List Students
      getListUpdates: { method: 'GET', url: `${baseStudents}/curtidas` },
      getListLikes: { method: 'GET', url: `${baseStudents}/ultima-atualizacao` },

      // MY GALERY END POINTS ******************************************************************************************************************
      // List Galery
      getMyListLikes: { method: 'GET', url: `${baseMyGalery}/listar{/qtd}/pulando{/skip}/ordenando/curtidas` },
      getMyListUpdates: { method: 'GET', url: `${baseMyGalery}/listar{/qtd}/pulando{/skip}/ordenando/ultima-atualizacao` },

      // Galery
      postMyGalery: { method: 'POST', url: baseMyGalery },
      getMyGalery: { method: 'GET', url: `${baseMyGalery}{/idGalery}` },
      putMyGalery: { method: 'PUT', url: `${baseMyGalery}{/idGalery}` },
      deleteMyGalery: { method: 'DELETE', url: `${baseMyGalery}{/idGalery}` },

      // Softwares
      putGalerySoftwares: { method: 'PUT', url: `${baseMyGalery}{/idGalery}/software` },

      // Tags
      postGaleryTag: { method: 'POST', url: `${baseMyGalery}{/idGalery}/tag` },
      deleteGaleryTag: { method: 'DELETE', url: `${baseMyGalery}{/idGalery}/tag{/idTag}` },

      // Trabalhos
      postGaleryJob: { method: 'POST', url: `${baseMyGalery}{/idGalery}/trabalho` },
      deleteGaleryJob: { method: 'DELETE', url: `${baseMyGalery}{/idGalery}/trabalho{/idJob}` },
      getPictureJob: { method: 'GET', url: `${baseMyGalery}{/idGalery}/trabalho{/idJob}/arquivo-acesso` },
      postPictureJob: { method: 'POST', url: `${baseMyGalery}{/idGalery}/trabalho{/idJob}/arquivo` },
      deletePictureJob: { method: 'DELETE', url: `${baseMyGalery}{/idGalery}/trabalho{/idJob}/arquivo` },

      // Comentários
      getGaleryJobComments: { method: 'GET', url: `${baseMyGalery}{/idGalery}/comentario` },
      postGaleryJobComments: { method: 'POST', url: `${baseMyGalery}{/idGalery}/comentario` },
      deleteGaleryJobComments: { method: 'DELETE', url: `${baseMyGalery}{/idGalery}/comentario{/idComentary}` },
      answerGaleryJobComments: { method: 'POST', url: `${baseMyGalery}{/idGalery}/comentario{/idComentary}/resposta` },

      // GALERIAS  ****************************************************************************************************************************
      // Likes
      getLikes: { method: 'GET', url: `${baseGaleries}/curtida` },
      giveLike: { method: 'POST', url: `${baseGaleries}/curtida` },
      removeLike: { method: 'DELETE', url: `${baseGaleries}/curtida` },
      iLiked: { method: 'GET', url: `${baseGaleries}/curtida/eu-curti-essa-galeria` },
      galeryByGeneral: { method: 'GET', url: `${baseAllGaleries}/comunidade/listar{/qtd}/pulando{/skip}` },
      galeryByFollowing: { method: 'GET', url: `${baseAllGaleries}/seguindo/listar{/qtd}/pulando{/skip}` },

      // Tags || Jobs || Softwares || Coments || Views
      getTags: { method: 'GET', url: `${baseGaleries}/tag` },
      getJobs: { method: 'GET', url: `${baseGaleries}/trabalho` },
      getSoftwares: { method: 'GET', url: `${baseGaleries}/software` },
      incrementExibition: { method: 'PUT', url: `${baseGaleries}/incrementar-exibicao` },
      getComents: { method: 'GET', url: `${baseGaleries}/trabalho{/idJob}/comentarios` }
    }

    this.resource = resource('', {}, acoesCustomizadas)
  }

  galeryByFollowing (dados) {
    return this.resource
      .galeryByFollowing(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  galeryByGeneral (dados) {
    return this.resource
      .galeryByGeneral(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getListUpdates (dados) {
    return this.resource
      .getListUpdates(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getPictureJob (dados) {
    return this.resource
      .getPictureJob(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  postPictureJob (dados) {
    return this.resource
      .postPictureJob(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  deletePictureJob (dados) {
    return this.resource
      .deletePictureJob(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getListLikes (dados) {
    return this.resource
      .getListLikes(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getMyListLikes (dados) {
    return this.resource
      .getMyListLikes(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getMyListUpdates (dados) {
    return this.resource
      .getMyListUpdates(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  postMyGalery (dados) {
    return this.resource
      .postMyGalery(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getMyGalery (dados) {
    return this.resource
      .getMyGalery(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  putMyGalery (dados) {
    return this.resource
      .putMyGalery(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  deleteMyGalery (dados) {
    return this.resource
      .deleteMyGalery(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  postGaleryTag (dados) {
    return this.resource
      .postGaleryTag(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  deleteGaleryTag (dados) {
    return this.resource
      .deleteGaleryTag(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  putGalerySoftwares (dados) {
    return this.resource
      .putGalerySoftwares(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  postGaleryJob (dados) {
    return this.resource
      .postGaleryJob(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  deleteGaleryJob (dados) {
    return this.resource
      .deleteGaleryJob(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getGaleryJobComments (dados) {
    return this.resource
      .getGaleryJobComments(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  postGaleryJobComments (dados) {
    return this.resource
      .postGaleryJobComments(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  deleteGaleryJobComments (dados) {
    return this.resource
      .deleteGaleryJobComments(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  answerGaleryJobComments (dados) {
    return this.resource
      .answerGaleryJobComments(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getLikes (dados) {
    return this.resource
      .getLikes(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  giveLike (dados) {
    return this.resource
      .giveLike(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  removeLike (dados) {
    return this.resource
      .removeLike(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getTags (dados) {
    return this.resource
      .getTags(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getSoftwares (dados) {
    return this.resource
      .getSoftwares(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  iLiked (dados) {
    return this.resource
      .iLiked(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  incrementExibition (dados) {
    return this.resource
      .incrementExibition(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getJobs (dados) {
    return this.resource
      .getJobs(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getComents (dados) {
    return this.resource
      .getComents(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }
}
