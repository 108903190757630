<template>
  <div class="body-modal mb-1 mb-md-3 scroll-body">
    <div
      class="loading-btn my-3"
      v-if="loadSoftwares"
    />
    <div
      class="mb-4 pt-2"
      v-else-if="softwares && softwares.length"
    >
      <h6 class="font-14">
        Softwares utilizados:
      </h6>

      <div class="modal-softwares">
        <div
          class="me-1 soft col-auto"
          v-for="soft in softwares"
          :key="`soft-modal-${soft.id}`"
        >
          <img :src="soft.img">
        </div>
      </div>
    </div>

    <div
      class="descricao mb-3 mb-md-4 pb-2"
      v-if="body.descricao"
    >
      <h6 class="font-14 mb-2">
        Descrição:
      </h6>

      <p
        class="font-13 descricao-text"
        v-html="body.descricao"
      />
    </div>

    <ModalGaleryComments
      :is-cdn="isCdn"
      :messages="body.messages"
    />
  </div>
</template>

<script>
import Mixins from '@/Mixins'
import GaleryService from '@/service/galeryService'
import ModalGaleryComments from './ModalGaleryComments'

export default {
  mixins: [Mixins],

  name: 'ModalBody',

  components: { ModalGaleryComments },

  props: {
    body: {
      type: Object,
      default: () => {}
    },

    isCdn: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      softwares: [],
      loadedAlbum: 0,
      loadSoftwares: false
    }
  },

  created () {
    this.GaleryService = new GaleryService(this.$resource)
    this.getSoftwares()
  },

  methods: {
    async getSoftwares () {
      const prepareMap = ({ id, extensaoImagem }) => ({ id, img: this.mountImagePath('software', id, extensaoImagem) }) || []

      if (this.isCdn) {
        this.softwares = this.body.softwares?.map(prepareMap)
        return
      }
      const idGalery = this.$route.params.album
      if (!idGalery || this.loadedAlbum === idGalery) return

      this.loadedAlbum = idGalery
      this.loadSoftwares = true
      this.album = this.$route.params.album
      const { body: softwares } = await this.GaleryService.getSoftwares({ idGalery })
      this.softwares = softwares.map(prepareMap)
      this.loadSoftwares = false
    }
  },

  watch: {
    '$route.params.album': {
      deep: true,
      handler () {
        this.getSoftwares()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.scroll-body::-webkit-scrollbar
  width: 2px !important
.scroll-body
  height: 100% !important
  overflow-y: scroll
  overflow-x: hidden
.modal-softwares
  display: inline-flex
  flex-direction: row
  overflow-x: scroll
  max-width: 100%
  &::-webkit-scrollbar
    height: 0px !important
    cursor: pointer
  .soft
    img
      width: 30px

.descricao-text
  line-height: 15px
</style>
