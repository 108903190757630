const SET_ALBUM = 'SET_ALBUM'
const SET_GALERY = 'SET_GALERY'
const SET_COMMENTS = 'SET_COMMENTS'
const CHANGE_ALBUM = 'CHANGE_ALBUM'
const REMOVE_COMMENT = 'REMOVE_COMMENT'
const SET_ALBUM_LIKED = 'SET_ALBUM_LIKED'
const SET_GALERY_EMPTY = 'SET_GALERY_EMPTY'
const SET_ANSWER_COMMENT = 'SET_ANSWER_COMMENT'
const SET_COMMENTS_EMPTY = 'SET_COMMENTS_EMPTY'

const state = {
  album: {},
  galery: [],
  comments: []
}

const actions = {
  setAlbum ({ commit }, album) {
    commit(SET_ALBUM, { album })
  },

  setLikedAlbum ({ commit }, value) {
    commit(SET_ALBUM_LIKED, value)
  },

  nextAlbum ({ commit }) {
    commit(CHANGE_ALBUM, 1)
  },

  previousAlbum ({ commit }) {
    commit(CHANGE_ALBUM, -1)
  },

  setGalery ({ commit }, galery) {
    commit(SET_GALERY, galery)
  },

  setGaleryEmpty ({ commit }) {
    commit(SET_GALERY_EMPTY)
  },

  setComments ({ commit }, comments) {
    commit(SET_COMMENTS, comments)
  },

  setEmptyComments ({ commit }) {
    commit(SET_COMMENTS_EMPTY)
  },

  removeComment ({ commit }, id) {
    commit(REMOVE_COMMENT, id)
  },

  setAnswerComment ({ commit }, comment) {
    commit(SET_ANSWER_COMMENT, comment)
  }
}

const getters = {
  getAlbum: (state) => state.album,

  getGalery: (state) => state.galery,

  getComments: (state) => state.comments.length ? state.comments : state.album?.messages || []
}

const mutations = {
  [SET_ALBUM] (state, album) {
    Object.assign(state, album)
  },

  [REMOVE_COMMENT] (state, commentId) {
    const comments = state.comments.map(item => {
      item.answers = item.answers.filter(({ id }) => id !== commentId)
      return item
    })
    state.comments = comments.filter(({ id }) => id !== commentId)
  },

  [SET_COMMENTS] (state, comments) {
    const commentsToSet = Array.isArray(comments) ? comments : [comments]
    state.comments = [...commentsToSet, ...state.comments]
  },

  [SET_COMMENTS_EMPTY] (state) {
    state.comments = []
  },

  [SET_ALBUM_LIKED] (state, value) {
    state.album.likes = Number(state.album.likes) + (value ? 1 : -1)
    state.album.liked = value
  },

  [CHANGE_ALBUM] (state, order) {
    const galery = state.galery
    const { albumId: currentId } = state.album
    const indexAlbum = galery.findIndex(album => album.albumId === currentId)

    let nextInd = indexAlbum + order
    if (nextInd < 0) nextInd = galery.length - 1

    const album = galery[nextInd] || galery[0]
    Object.assign(state, { album })
  },

  [SET_GALERY] (state, galery) {
    state.galery = [...state.galery, ...galery]
  },

  [SET_ANSWER_COMMENT] (state, comment) {
    state.comments.find(({ id }) => id === comment.idComentary).answers.push(comment)
  },

  [SET_GALERY_EMPTY] (state) {
    state.galery = []
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
