<template>
  <div>
    <div class="banner py-5">
      <div class="row justify-content-center">
        <div class="col-md-4 col-10 text-center">
          <img src="@/assets/images/logo.png" class="mb-3" />
          <input
            type="text"
            v-model="search"
            class="form-control input-search"
            placeholder="Qual arquivo você está procurando?"
          />
        </div>
      </div>
    </div>

    <div class="scroll">
      <LoadingComp v-if="isLoading" />

      <div v-else-if="filteredArquivos.length" class="enter-effect">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <h6 class="mb-1 mt-4 font-14" style="color: #646464">
              Filtrar por:
            </h6>

            <div class="row">
              <div class="col-auto mb-1 mb-md-0">
                <button
                  @click="filterBy(filter === true ? null : true)"
                  :class="[
                    'bg-btn2 px-4 py-1',
                    filter === true ? 'btn-fill' : 'btn-transparent',
                  ]"
                >
                  Pro
                </button>
              </div>

              <div class="ps-0 col-auto">
                <button
                  @click="filterBy(filter === false ? null : false)"
                  :class="[
                    'bg-btn2 px-4 py-1',
                    filter === false ? 'btn-fill' : 'btn-transparent',
                  ]"
                >
                  Free
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row justify-content-start">
              <CardArquivos
                :arquivo="arquivo"
                v-for="(arquivo, ind) in filteredArquivos"
                :key="`ind-arquivo-${ind}`"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-else class="row justify-content-center">
        <img
          class="sem-conquistas my-4"
          src="@/assets/images/wins/baby_yoda.png"
        />
        <p class="text-center secondary">Nenhum Arquivo cadastrado.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/Mixins";
import { mapGetters } from "vuex";
import CardArquivos from "./CardArquivos";
import SharedService from "@/service/sharedServices";

export default {
  mixins: [Mixins],

  name: "ArquivosComp",

  components: {
    CardArquivos,
  },

  data() {
    return {
      search: "",
      filter: null,
    };
  },

  methods: {
    filterBy(filter) {
      this.filter = filter;
    },
  },

  async created() {
    if (this.isLoading) return;
    this.SharedService = new SharedService(this.$resource);

    this.$store.dispatch("arquivos/setIsLoading", true);
    const { body: arquivos } = await this.SharedService.arquivos();
    this.$store.dispatch("arquivos/setIsLoading", false);

    const orderedArquivos = arquivos
      .map((arquivo) => ({
        ...arquivo,
        titulo: arquivo.titulo,
        link: arquivo.link
          .replaceAll(`<p style="max-width: 300px">`, "")
          .replaceAll(`<p style="max-width: 500px">`, "")
          .replaceAll("</p>", ""),
        isPro: arquivo.acesso === "P",
        imagem: this.mountImagePath(
          "arquivos",
          arquivo.id,
          arquivo.extensaoImagem
        ),
      }))
      .sort((a, b) => (a.order < b.order ? 1 : -1));

    this.$store.dispatch("arquivos/setArquivos", orderedArquivos);
  },

  computed: {
    ...mapGetters({
      arquivos: "arquivos/getArquivos",
      isLoading: "arquivos/isLoading",
    }),

    filteredArquivos() {
      const list =
        this.filter === null
          ? this.arquivos
          : this.arquivos.filter((i) => this.filter === i.isPro);

      if (!this.search) return list;

      return list.filter(
        ({ nome, descricao }) =>
          nome.toLowerCase().indexOf(this.search.toLowerCase()) >= 0 ||
          descricao.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style lang="sass" scoped>
.scroll
  max-height: calc(100vh - 200px)
.banner
  background-image: url("@/assets/images/bg-lives.png")
  background-position: center
  background-size: cover
.min-height
  min-height: 70vh !important
  display: inline-flex
  align-items: center
  width: 100%
  justify-content: center
.opacity-25
  transition: all .15s
  opacity: .35 !important
  &:hover
    opacity: .85 !important
.sem-conquistas
  width: 200px
.input-search
  border-radius: 15px
  height: 30px
.sem-conquistas
  opacity: .85 !important
  width: 200px
.bg-btn2
  color: white
  text-transform: uppercase
  border-radius: 5px
  line-height: normal
  align-items: center
  display: inline-flex
  justify-content: center
  font-size: 12px !important
@media (max-width: 600px)
  .scroll
    max-height: auto !important
</style>
