export default class usuarioService {
  constructor (resource) {
    const acoesCustomizadas = {
      logar: { method: 'GET', url: 'acesso-publico/login/plataforma-conteudo' },
      decodificar: { method: 'GET', url: 'acesso-publico/token-decodificar' },
      acessoPopup: { method: 'POST', url: 'plataforma-conteudo/registrar-acesso-popup{/id}' },
      decrypt: { method: 'GET', url: 'acesso-publico/encript-decript?action=decrypt' },
      encripty: { method: 'GET', url: 'acesso-publico/encript-decript?action=encrypt' },
      editEducando: { method: 'PUT', url: 'plataforma-conteudo/educando' },
      editSenhaEducando: { method: 'PUT', url: 'plataforma-conteudo/educando/senha' },
      certificados: { method: 'GET', url: 'plataforma-conteudo/educando/sessao/certificados' },
      openCourse: { method: 'POST', url: 'plataforma-conteudo/curso{/id}/acessou-curso' },
      hours: { method: 'GET', url: 'plataforma-conteudo/educando/tempo-total-conteudo-consumido' },
      setLikeCourse: { method: 'PUT', url: 'plataforma-conteudo/curso{/id}/curtir{/status}' },
      finalizarCurso: { method: 'PUT', url: 'plataforma-conteudo/acesso-curso{/id}/concluido' },
      getPercentual: { method: 'GET', url: 'plataforma-conteudo/curso{/id}/percentual-concluido' },
      watchVideo: { method: 'POST', url: 'plataforma-conteudo/acesso-curso{/idVinculo}/temporada-video{/idVideo}' },
      removeWatchVideo: { method: 'DELETE', url: 'plataforma-conteudo/acesso-curso{/idVinculo}/temporada-video{/idVideo}' },
      buscarTemporadasAssistidas: { method: 'GET', url: 'plataforma-conteudo/acesso-curso{/id}/temporadas-concluidas' },
      conquistas: { method: 'GET', url: 'plataforma-conteudo/educando/sessao/conquistas' },
      recuperarSenha: { method: 'GET', url: 'educando/recuperacao-senha{/email}' },
      setConquistas: { method: 'POST', url: 'plataforma-conteudo/conquista{/idConquista}' },
      deleteConquistas: { method: 'DELETE', url: 'plataforma-conteudo/conquista{/idConquista}' },
      buscarVideosAssistidos: { method: 'GET', url: 'plataforma-conteudo/acesso-curso{/id}/temporada{/idTemporada}/videos-assistidos' },
      buscarDados: { method: 'GET', url: 'plataforma-conteudo/educando/sessao/meus-dados' },
      setToBun: { method: 'POST', url: 'plataforma-conteudo/registrar-comportamento-inadequado' },
    }
    this.resource = resource('', {}, acoesCustomizadas)
  }

  buscarDados () {
    return this.resource
      .buscarDados()
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  setConquistas (dados) {
    return this.resource
      .setConquistas(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  acessoPopup (dados) {
    return this.resource
      .acessoPopup(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  setToBun (dados) {
    return this.resource
      .setToBun(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  deleteConquistas (dados) {
    return this.resource
      .deleteConquistas(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  recuperarSenha (dados) {
    return this.resource
      .recuperarSenha(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  buscarTemporadasAssistidas (dados) {
    return this.resource
      .buscarTemporadasAssistidas(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  buscarVideosAssistidos (dados) {
    return this.resource
      .buscarVideosAssistidos(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  finalizarCurso (dados) {
    return this.resource
      .finalizarCurso(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  encripty (dados) {
    return this.resource
      .encripty(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  decrypt (dados) {
    return this.resource
      .decrypt(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  getPercentual (dados) {
    return this.resource
      .getPercentual(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  editSenhaEducando (dados) {
    return this.resource
      .editSenhaEducando(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  editEducando (dados) {
    return this.resource
      .editEducando(dados)
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  watchVideo (dados) {
    return this.resource
      .watchVideo(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  removeWatchVideo (dados) {
    return this.resource
      .removeWatchVideo(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  certificados (dados) {
    return this.resource
      .certificados(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  hours (dados) {
    return this.resource
      .hours(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  setLikeCourse (dados) {
    return this.resource
      .setLikeCourse(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  openCourse (dados) {
    return this.resource
      .openCourse(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  logar (dados) {
    return this.resource
      .logar(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        return err
      })
  }

  decodificar () {
    return this.resource
      .decodificar()
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  conquistas (dados) {
    return this.resource
      .conquistas(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }
}
