<template>
  <div
    class="social-media"
    v-if="midias.length"
  >
    <p class="upper dark-grey-2 font-11 mb-2">
      Na web:
    </p>

    <div
      class="row"
      :key="midia.type"
      v-for="midia in midias"
    >
      <a
        v-if="midia"
        target="_blank"
        :href="midia.link"
        rel="noopener noreferrer"
        class="link-midias mb-2 col pt-1 mx-2 font-13"
      >
        <component
          :is="icones[midia.type]"
          class="mb-1 me-1"
        />
        {{ midia.type }}
      </a>
    </div>
  </div>
</template>

<script>
import Instagram from '@/assets/icons/Instagram'
import Twitter from '@/assets/icons/Twitter'
import Youtube from '@/assets/icons/Youtube'
import Tiktok from '@/assets/icons/Tiktok'
import Linkedin from '@/assets/icons/Linkedin'

export default {
  name: 'SoftwaresComp',

  props: {
    midias: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      icones: {
        instagram: Instagram,
        twitter: Twitter,
        youtube: Youtube,
        tiktok: Tiktok,
        linkedin: Linkedin
      }
    }
  }
}
</script>

<style lang="sass">
.social-media
  padding: 0px 2px
  .link-midias
    padding-top: 5px !important
    padding-bottom: 3px
    width: 100%
    text-align: center
    border-radius: 15px
    background-color: #151515
    text-transform: capitalize

</style>
