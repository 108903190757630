<template>
  <div class="py-2 px-1 px-md-5">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-10">
        <input id="base-focus" />
        <FieldTextQuill
          label="Escreva seu comentário"
          placeholder="Compartilhe algo"
          v-model="message"
          :isDisabled="isPro"
          :key-press="true"
          has-image
          :enable-key="false"
          @keyPressed="keyPressed"
        />
      </div>

      <form @submit.prevent="saveComment" class="col-sm-12 col-md-10">
        <div class="row justify-content-end">
          <div class="col-auto">
            <button
              :disabled="isPro"
              class="btn bg-btn-pink font-14 mb-1"
              id="gerarResposta"
            >
              Enviar
            </button>
          </div>
        </div>
      </form>
      <div class="col-sm-12 col-md-10">
        <hr />
      </div>

      <div class="col-sm-12 col-md-10">
        <CommentCard
          :comment="comment"
          v-for="comment in comments"
          :key="comment.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CommentCard from "./comments/CommentCard";
import CommentsService from "@/service/commentsService";
import FieldTextQuill from "@/components/shared/forms/FieldTextQuill";
import { mapGetters } from "vuex";

export default {
  name: "CommentsNav",

  components: {
    FieldTextQuill,
    CommentCard,
  },

  created() {
    this.loadComentarios();
  },

  data() {
    this.CommentsService = new CommentsService(this.$resource);

    return {
      message: "",
      activeNotification: false,
      comments: [],
      isLoading: false,
    };
  },

  watch: {
    $route: {
      deep: true,
      handler() {
        this.loadComentarios();
      },
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      allVideos: "temporadas/getAllVideos",
    }),

    currentVideo() {
      return Number(this.$route.params.video);
    },

    isPro() {
      return this.allVideos.some(
        ({ isPro, id }) => isPro && id === this.currentVideo && !this.user.isPro
      );
    },
  },

  methods: {
    async loadComentarios() {
      const params = {
        idTemporadaVideo: Number(this.$route.params.idTemporadaVideo),
      };
      if (isNaN(params.idTemporadaVideo)) return;

      this.isLoading = true;
      const { body } = await this.CommentsService.comentarios(params);

      const comments = body
        ?.filter(({ idComentarioPai }) => !idComentarioPai)
        .map((params) => {
          const newResp = mountComment(params, this.$baseApiURL);
          newResp.respostas =
            params?.respostas?.map((res) =>
              mountComment(res, this.$baseApiURL)
            ) || [];
          return newResp;
        });

      this.comments = Object.values(comments || []);
      this.isLoading = false;
    },

    keyPressed() {
      document.getElementById("base-focus").focus();
      setTimeout(() => {
        document.getElementById("gerarResposta").click();
      }, 200);
    },

    saveComment() {
      const params = {
        idTemporadaVideo: Number(this.$route.params.idTemporadaVideo),
        comentario: this.message.replace(/\n/g, "<br>"),
      };

      const paramsToList = {
        extensaoImagem: this.user.extensaoImagem,
        idEducando: this.user.id,
        nome: this.user.nome,
        ocupacao: this.user.ocupacao,
        id: uuidv4(),
        idComentarioPai: null,
        comentario: params.comentario,
        createdAt: new Date(),
        exibicoes: 0,
        curtidas: 0,
      };

      this.message = "";
      this.comments.unshift(mountComment(paramsToList, this.$baseApiURL));
      this.CommentsService.save(params);
    },
  },
};

const mountComment = (params, url) => ({
  usuario: {
    imagem: `${url}/acesso-arquivo?caminho=educando&extensao=${params.extensaoImagem}&identificador=${params.idEducando}`,
    nome: params.nome,
    profissao: params.ocupacao,
    idEducando: params.idEducando,
    hasImage: Boolean(params.extensaoImagem),
  },
  id: params.id,
  idComentarioPai: params.idComentarioPai,
  texto: params.comentario,
  dataComentario: moment(params.createdAt)
    .locale("pt-br")
    .format("DD MMM YYYY"),
  visualizacoes: params.exibicoes,
  likes: params.curtidas,
});
</script>

<style lang="sass" scoped>
.btn
  border-radius: 3px !important
.form-check-input
  border-radius: 2px !important
  &:focus
    box-shadow: none
#base-focus
  height: 0
  padding: 0
  opacity: 0
  position: absolute
</style>
