<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.563"
    height="15.625"
    viewBox="0 0 15.563 15.625"
    class="senha"
  >
    <path
      id="Forma_1"
      data-name="Forma 1"
      class="cls-1"
      d="M59.469,329.3a0.974,0.974,0,1,1-.973.974A0.974,0.974,0,0,1,59.469,329.3Zm4.334,1.761a8.872,8.872,0,0,0-1.914-3.009A8.676,8.676,0,0,0,58.9,326.17a2.446,2.446,0,0,0-2.594.551L53.919,329.1a2.435,2.435,0,0,0-.508,2.7c0.125,0.282.262,0.558,0.407,0.824l-5.243,5.245a0.608,0.608,0,0,0-.178.43v2.677a0.609,0.609,0,0,0,.608.609h2.677a0.609,0.609,0,0,0,.608-0.609v-1.095h1.095a0.609,0.609,0,0,0,.608-0.609v-1.095h1.095a0.609,0.609,0,0,0,0-1.217h-1.7a0.609,0.609,0,0,0-.608.609v1.095h-1.1a0.608,0.608,0,0,0-.608.608v1.1h-1.46v-1.817l5.394-5.4a0.609,0.609,0,0,0,.09-0.746,9.8,9.8,0,0,1-.576-1.1,1.211,1.211,0,0,1,.255-1.342l2.389-2.378a1.228,1.228,0,0,1,1.3-.276,7.445,7.445,0,0,1,2.563,1.6A7.657,7.657,0,0,1,62.67,331.5a1.209,1.209,0,0,1-.273,1.3l-2.418,2.407a1.227,1.227,0,0,1-1.339.261,8.034,8.034,0,0,1-1.095-.559,0.608,0.608,0,1,0-.636,1.037,9.02,9.02,0,0,0,1.264.645,2.436,2.436,0,0,0,2.664-.522l2.418-2.407A2.424,2.424,0,0,0,63.8,331.056Z"
      transform="translate(-48.406 -325.969)"
    />
  </svg>
</template>
<script>
export default {
  name: 'PasswordComp'
}
</script>
<style lang="sass" scoped>
.senha
  fill: #646464
</style>
