<template>
  <div class="column">
    <div
      class="col label-modal"
      v-if="label"
    >
      <label>{{ label }}</label>
    </div>

    <div class="col mb-2">
      <select
        v-model="selectedOption"
        :class="['select-modal', classe]"
      >
        <option
          v-for="(item, indItem) in list"
          :key="indItem"
          :value="item.value || item.id || item"
        >
          {{ item.name || item.label || item }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},

    label: {
      type: String
    },

    classe: {
      type: String
    },

    list: {
      default: () => []
    }
  },
  data () {
    return {
      selectedOption: this.value
    }
  },

  watch: {
    value: {
      handler (value) {
        if (value === this.selectedOption) return
        this.selectedOption = value
      }
    },

    selectedOption: {
      handler (selectedOption) {
        if (selectedOption === this.value) return
        this.$emit('input', selectedOption)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
select:focus
  color: #c5c5c5 !important

.select-modal
  border-radius: 2px
  border: 1px solid #606060 !important
  padding: 8px 10px 5px 10px !important
  &:focus
    border: 1px solid #ff4163 !important
</style>
