<template>
  <section
    id="parceiros"
    :class="[menuIsOpen ? 'px-1' : 'px-0 px-md-5']"
    v-if="isLoading || parceiros.length"
  >
    <LoadingComp v-if="isLoading" />
    <div v-else-if="parceiros.length">
      <h5 class="dark-grey-2 text-center mb-0 mx-2">
        Marcas que apoiam o desenvolvimento e a educação
      </h5>

      <Slider
        :slide-default="false"
        :images="parceiros"
        :settings-props="settings"
        :has-link="true"
        :slides-to-show="slidesToShow"
      />
    </div>
  </section>
</template>

<script>
import SharedService from '@/service/sharedServices'
import Slider from '@/components/shared/Slider'
import { mapGetters } from 'vuex'

export default {
  name: 'ParceirosComp',
  components: {
    Slider
  },

  async created () {
    if (this.isLoading || this.parceiros.length) return
    this.SharedService = new SharedService(this.$resource)
    this.$store.dispatch('parceiros/setIsLoading', true)
    const { body: parceiros } = await this.SharedService.parceiros()
    this.$store.dispatch('parceiros/setIsLoading', false)
    this.$store.dispatch('parceiros/setParceiros', parceiros.map(parceiro => ({
      id: parceiro.id,
      link: parceiro.linkSite,
      image: this.mountImg(parceiro)
    })))
  },

  methods: {
    mountImg ({ id, extensaoLogo }) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=parceiro&identificador=${id}&extensao=${extensaoLogo}`
    }
  },

  computed: {
    slidesToShow () {
      if (this.width < 600) return 3
      if (this.width < 800) return 4
      return 4
    },

    settings () {
      return {
        speed: 300,
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: true,
        slidesToShow: this.slidesToShow,
        cssEase: 'linear',
        autoplaySpeed: 5000
      }
    },

    ...mapGetters({
      menuIsOpen: 'configs/menuIsOpen',
      width: 'configs/getWidth',
      parceiros: 'parceiros/getParceiros',
      isLoading: 'documentarios/isLoading'
    })
  }
}
</script>

<style lang="sass" scoped>
@media (max-width: 600px)
  h5
    font-size: 16px
    margin-inline: 20px !important
</style>
