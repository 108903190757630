<template>
  <ul class="ul-links mt-3 pb-2 px-1 px-md-5">
    <li class="text-center list-none" v-if="links.length === 0">
      Não existem materiais do Curso
    </li>
    <li v-for="link in links" :key="`${link.id}-link`">
      <a :href="link.link" target="_blank" class="upper">
        {{ link.titulo }}
      </a>
    </li>
  </ul>
</template>

<script>
import CursoService from "@/service/cursoService";

export default {
  data: () => ({ links: [] }),

  async created() {
    this.CursoService = new CursoService(this.$resource);
    const id = this.$route.params.id;
    const { body: links } = await this.CursoService.cursoLinksUteis({ id });
    this.links = links || [];
    this.$emit("input", links?.length);
  },
};
</script>

<style lang="sass" scoped>
.list-none
  list-style: none
</style>
