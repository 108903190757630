import Home from '../components/modules/home/Home'
import Senha from '../components/modules/senha/Senha'
import Curso from '../components/modules/curso/Curso'
import Cursos from '../components/modules/cursos/Cursos'
import Token from '../components/modules/token/Token'
import Login from '../components/modules/login/Login'
import Perfil from '../components/modules/perfil/Perfil'
import Galeria from '../components/modules/galeria/Galeria'
import Empresas from '../components/modules/empresas/Empresas'
import Arquivos from '../components/modules/arquivos/Arquivos.vue'
import Recover from '../components/modules/recoverPassword/Recover'
import Conquistas from '../components/modules/conquistas/Conquistas'
import Certificados from '../components/modules/certificados/Certificados'
import MainContainer from '../components/shared/mainContainer/MainContainer'
import DadosPessoais from '../components/modules/dadosPessoais/DadosPessoais'

export default [
  // Home
  {
    path: '/',
    name: 'default',
    component: MainContainer,
    children: [
      // cafe
      { path: '/cafe/:user?/:album?/:image?', name: 'cafe', component: Home },

      // cursos
      { path: '/home', name: 'home', component: Cursos },

      // senha
      { path: '/senha', component: Senha, name: 'senha' },

      // arquivos
      { path: '/arquivos', component: Arquivos, name: 'arquivos' },

      // dados
      { path: '/dados', component: DadosPessoais, name: 'dados' },

      // conquistas
      { path: '/conquistas', component: Conquistas, name: 'conquistas' },

      // Recover
      { path: '/nova-senha/:email', component: Recover, name: 'recover' },

      // certificados
      { path: '/certificados', component: Certificados, name: 'certificados' },

      // perfil
      { path: '/perfil/:user/:album?/:image?', component: Perfil, name: 'perfil' },

      // galeria
      { path: '/galeria/:user?/:album?/:image?', component: Galeria, name: 'galeria' }
    ]
  },

  // Login
  { path: '/login', component: Login, name: 'login' },

  // Home curso
  { path: '/curso/:id', component: Curso, name: 'curso' },

  // Empresas
  { path: '/empresas', component: Empresas, name: 'empresas' },

  // Token
  { path: '/token/:token/:uuid', component: Token, name: 'token' },

  // Home vídeo
  { path: '/curso/:id/modulo/:modulo/video/:video/:idTemporadaVideo', component: Curso, name: 'video' }
]
