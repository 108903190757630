<template>
  <section class="bg-dark border-radius-10 mb-3" v-if="campanha.image">
    <a target="_blank" :href="campanha.linkExterno">
      <img class="border-radius-10 with-events" :src="campanha.image" />
    </a>
  </section>
</template>

<script>
import CursoService from "@/service/cursoService";
import { mapGetters } from "vuex";

export default {
  name: "CampaignComp",

  async created() {
    if (this.campanhas.length) return;

    this.CursoService = new CursoService(this.$resource);
    const id = this.user.isPro ? 3 : 5;
    let { body: campanhas3 } = await this.CursoService.cursosCampanha({ id });
    campanhas3 = campanhas3.map((item) => this.mountImage(item, 3));
    this.$store.dispatch("campanhas/setCampanhas", campanhas3);
  },

  methods: {
    ext(item) {
      return this.isMobile
        ? item.extensaoImagemMobile || item.extensaoImagem
        : item.extensaoImagem;
    },
    exp(item) {
      if (!item.extensaoImagemMobile) return "";
      return this.isMobile ? "&expressao=mobile" : "";
    },
    mountImage(item, origin) {
      const vue = this;
      return {
        image: `${
          this.$baseApiURL
        }/acesso-arquivo?caminho=campanha&identificador=${
          item.id
        }&extensao=${vue.ext(item)}${vue.exp(item)}`,
        linkExterno: item.linkRedirecionamento || item.linkVideo,
        origin,
      };
    },
  },

  computed: {
    ...mapGetters({
      campanhas: "campanhas/getCampanhas3",
      user: "registration/getUser",
    }),

    campanha() {
      return this.campanhas?.length
        ? this.campanhas.find(({ origin }) => origin === 3)
        : {};
    },
  },
};
</script>
<style lang="sass" scoped>
img
  user-select: none
  width: 100% !important
.bg-dark
  background-color: rgba(0,0,0,0) !important
</style>
