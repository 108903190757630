const SET_DOCUMENTARIOS = 'SET_DOCUMENTARIOS'
const SET_EPISODIOS = 'SET_EPISODIOS'
const SET_IS_LOADING = 'SET_IS_LOADING'

const state = {
  documentarios: [],
  episodios: [],
  isLoading: false
}

const actions = {
  setDocumentarios ({ commit }, { documentarios }) {
    commit(SET_DOCUMENTARIOS, documentarios)
  },
  setEpisodios ({ commit }, { episodios }) {
    commit(SET_EPISODIOS, episodios)
  },
  setIsLoading ({ commit }, isLoading) {
    commit(SET_IS_LOADING, isLoading)
  }
}

const getters = {
  getDocumentarios: (state) => {
    return state.documentarios
  },
  getEpisodios: (state) => {
    return state.episodios
  },
  isLoading (state) {
    return state.isLoading
  }
}

const mutations = {
  [SET_DOCUMENTARIOS] (state, documentarios) {
    state.documentarios = documentarios
  },
  [SET_EPISODIOS] (state, episodios) {
    state.episodios = episodios
  },
  [SET_IS_LOADING] (state, isLoading) {
    state.isLoading = isLoading
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
