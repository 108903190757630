export default class usuarioService {
  constructor (resource) {
    const acoesCustomizadas = {
      validarCodigo: { method: 'GET', url: 'acesso-publico/codigo-corporativo' },
      registrarAluno: { method: 'POST', url: 'acesso-publico/educando/codigo-corporativo' }
    }
    this.resource = resource('', {}, acoesCustomizadas)
  }

  validarCodigo (dados) {
    return this.resource
      .validarCodigo(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  registrarAluno (dados) {
    return this.resource
      .registrarAluno(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }
}
