<template>
  <div
    v-if="!isCdn || canShow"
    :class="['modal-comment-card inline-flex flex-column mb-2 pb-1', isLoadingDelete ? 'isDeleting-comment-card' : '']"
  >
    <div
      v-if="isAnswer"
      class="d-flex flex-row mt-3 mb-2"
    >
      <div class="line me-2" /> <span class="col font-12 answers fst-italic">Respostas</span>
    </div>

    <div class="d-inline-flex align-items-center">
      <router-link :to="{ name: 'perfil', params: { user: item.idEducando } }">
        <img
          class="img-perfil me-2"
          :src="item.aluno.img"
          v-if="item.aluno.img"
        >
        <div
          v-else
          class="img-perfil text-center bg-black float-start me-2"
        >
          <span class="white font-18">{{ item.aluno.name[0] }}</span>
        </div>
      </router-link>

      <label class="me-2 font-13">{{ item.aluno.name || '' }}</label>

      <label class="dark-grey-2 font-13 me-1 fst-italic">{{ item.time }}.</label>

      <span
        v-if="!isAnswer && !isCdn"
        data-toggle="collapse"
        :id="`btn-collapse-answer-pic-${item.id}`"
        :data-target="`#collapse-answer-pic-${item.id}`"
        class="dark-grey-2 font-13 fst-italic hovered pointer"
      >
        Responder
      </span>

      <TrashIcon
        classe="small"
        v-if="canDelete"
        class="ms-2 opacity-50 pointer"
        @click.native="deleteComment()"
      />
    </div>

    <div
      v-html="item.comment"
      class="dark-grey-2 font-13 ps-3 mb-2"
    />

    <div
      v-if="!isAnswer"
      class="collapse px-2"
      :id="`collapse-answer-pic-${item.id}`"
    >
      <div class="row align-items-end border-left-modal-comment-card mt-2">
        <div class="col">
          <input
            :id="`base-focus-${item.id}`"
            class="base-focus"
          >
          <TextArea
            label=""
            :key-press="true"
            v-model="comment"
            @keyPressed="keyPressed(item.id)"
            :is-disabled="isLoadingComent"
          />
        </div>
        <div class="mb-2 col-auto">
          <div
            v-if="isLoadingComent"
            class="loading-btn mb-1"
          />

          <span
            v-else
            :id="`gerarResposta-${item.id}`"
            @click="gerarComentario()"
            class="pointer opacity-75"
          >
            <SendIcon />
          </span>
        </div>
      </div>
    </div>

    <CommentCard
      class="ps-5"
      :item="answer"
      :key="answer.id"
      :is-answer="true"
      v-for="answer in item.answers"
    />
  </div>
</template>

<script>
import Mixins from '@/Mixins'
import { mapActions, mapGetters } from 'vuex'
import SendIcon from '@/assets/icons/SendIcon'
import FeedService from '@/service/feedService'
import TrashIcon from '@/assets/icons/TrashIcon'
import GaleryService from '@/service/galeryService'
import TextArea from '@/components/shared/forms/FieldTextQuill'

export default {
  name: 'CommentCard',

  mixins: [Mixins],

  components: { TextArea, TrashIcon, SendIcon },

  data () {
    this.GaleryService = new GaleryService(this.$resource)

    return {
      comment: '',
      isLoadingDelete: false,
      isLoadingComent: false
    }
  },

  computed: {
    ...mapGetters({
      album: 'galery/getAlbum',
      user: 'registration/getUser',
      excludedItems: 'feed/getExcludedItems'
    }),

    canDelete () {
      const currentUser = Number(this.user.id)
      const commentsIdEducando = Number(this.item.idEducando)

      return (commentsIdEducando === currentUser)
    },

    paramsFeed () {
      const idFeed = this.$route.params.album
      const idFeedComment = this.item.id
      return { idFeed, idFeedComment }
    },

    comentKey () {
      return `${this.paramsFeed.idFeed}-${this.paramsFeed.idFeedComment}`
    },

    canShow () {
      return !this.excludedItems.includes(this.comentKey)
    }
  },

  methods: {
    ...mapActions({
      removeComment: 'galery/removeComment',
      setAnswerComment: 'galery/setAnswerComment'
    }),

    keyPressed (id) {
      document.getElementById(`base-focus-${id}`).focus()
      setTimeout(() => {
        document.getElementById(`gerarResposta-${id}`).click()
      }, 200)
    },

    async gerarComentario () {
      const params = {
        idTeaching: this.user.id,
        idComentary: this.item.id,
        comentario: this.comment,
        idGalery: this.$route.params.album
      }

      document.getElementById(`btn-collapse-answer-pic-${this.item.id}`).click()

      this.comment = ''
      const message = {
        id: '',
        time: '0min',
        idEducando: this.user.id,
        idComentary: this.item.id,
        comment: params.comentario,
        aluno: {
          name: this.user.nome,
          img: this.mountImagePath('educando', this.user.id, this.user.extensaoImagem)
        }
      }

      this.setAnswerComment(message)
      const { status, body } = await this.GaleryService.answerGaleryJobComments(params)
      if (status >= 300) return
      message.id = body
    },

    async deleteComment () {
      const { isConfirmed } = await this.$sweet.sweetQuestionPersonalizado('Deseja excluir o comentário?', '<p>Uma vez excluído, não terá como recupera-lo.</p>')
      if (!isConfirmed) return

      if (this.isCdn) {
        this.feedService = new FeedService(this.$resource)
        this.$store.dispatch('feed/setExcludedItems', this.comentKey)
        this.feedService.removerComentario(this.paramsFeed)
        return
      }

      this.isLoadingDelete = true
      const params = {
        idComentary: this.item.id,
        idGalery: this.$route.params.album
      }

      const { status } = await this.GaleryService.deleteGaleryJobComments(params)
      this.isLoadingDelete = false
      if (status >= 300) return

      this.removeComment(this.item.id)
    }
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },

    isAnswer: {
      type: Boolean,
      default: false
    },

    isCdn: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
@keyframes piscar
  0%
    opacity: .15
  50%
    opacity: .35
  100%
    opacity: .15

.isDeleting-comment-card
  animation: piscar 1300ms infinite

.border-left-modal-comment-card
  border-left: 1px solid #444
.comentarios
  .white
    color: #fff !important
  .answers
    color: #8e8e8e
  .line
    width: 50px
    border-bottom: 1px solid #444
    margin-bottom: 10px !important
  .img-perfil
    width: 27px
    height: 27px
    display: grid
    object-fit: cover
    border-radius: 50%
    place-items: center
.dark-grey-2
  p
    color: #646464 !important
.base-focus
  height: 0
  padding: 0
  opacity: 0
  position: absolute
</style>
