<template>
  <div>
    <a
      v-if="lockVideo"
      target="_blank"
      href="https://pay.hotmart.com/A65673843L?off=c7rht0ef&checkoutMode=10&split=12"
    >
      <img class="with-events" src="@/assets/images/thumb-closed.png" />
    </a>

    <div
      v-else-if="curso.quantidadeTemporadas > 0"
      :class="['flex-video', isSmall ? 'p-54' : 'p-55']"
    >
      <div class="loading-video" v-if="videoLoading || loadError">
        <LoadingComp />
      </div>

      <video
        controls
        class="frame"
        frameborder="0"
        id="video-aula"
        disablePictureInPicture
        :src="mountVideoURL(video)"
        oncontextmenu="return false;"
        allow="fullscreen; picture-in-picture"
        v-if="!videoLoading && video.id && !loadError && video.extensao"
      />

      <iframe
        class="frame"
        allowfullscreen
        frameborder="0"
        :src="mountVideoURL(video)"
        allow="fullscreen; picture-in-picture"
        v-if="!videoLoading && video.id && !loadError && !video.extensao"
      />
    </div>

    <img v-else :src="mountImg(curso)" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "VideoComp",

  props: {
    video: {},
    videoLoading: {},
  },

  data() {
    return {
      player: {},
      videoFrame: {},
      loadError: false,
      nextAula: false,
      isYoutube: {},
    };
  },

  created() {
    this.createStructure();
  },

  methods: {
    createStructure() {
      const vue = this;
      setInterval(() => {
        this.videoFrame = document.getElementById("video-aula");
        this.videoFrame?.setAttribute("disablePictureInPicture", "true");
      }, 2000);

      window.addEventListener("message", (event) => {
        const isError =
          event?.data?.event === "error" &&
          event?.data?.data?.method === "ready";
        if (!isError) return;

        setTimeout(() => {
          vue.loadError = true;
        }, 2000);
        setTimeout(() => {
          vue.loadError = false;
        }, 5000);
      });

      this.handleStartVideo();
    },

    handleStartVideo() {
      setTimeout(async () => {
        this.nextAula = false;
        this.isYoutube = this.video?.link?.indexOf("youtu") > -1;
        if (this.isYoutube) return this.startYoutube();

        this.isDigitalOcean = true;
        this.startDigitalOcean();
      }, 3000);
    },

    startDigitalOcean() {
      const current = localStorage.getItem(this.$route.path);
      if (Number(current) > 60) this.videoFrame.currentTime = current;

      this.videoFrame?.addEventListener("timeupdate", this.saveTimeVideo);
      this.videoFrame?.addEventListener("ended", this.endWatchVideo, false);

      if (this.videoFrame?.paused) this.videoFrame.play();
    },

    saveTimeVideo() {
      if (!this.videoFrame) return;

      localStorage.setItem(this.$route.path, this.videoFrame.currentTime);
    },

    endWatchVideo() {
      this.$root.persistVideo();

      this.nextAula = true;
      this.videoFrame.currentTime = 0;
      this.$store.dispatch("temporadas/escolherVideo", 1);
      setTimeout(() => (this.nextAula = false), 3000);
    },

    startYoutube() {
      this.$root.persistVideo();
    },

    mountVideoURL(videoObj) {
      const { linkVideo, extensao } = videoObj;
      if (extensao) {
        let { id, modulo, video } = this.$route.params;
        if (video < 10) video = `0${video}`;

        let path = "cursos";
        if (this.$store.getters["cursos/getCurso"].workshop === "S")
          path = "workshops";
        if (this.$store.getters["cursos/getCurso"].documentario === "S")
          path = "documentarios";

        return `${this.$space}/${path}/${id}/${modulo}/${video}.mp4`;
      }
      if (!linkVideo) return "";
      if (linkVideo.indexOf("youtu") > -1)
        return this.handleYoutubeLink(linkVideo);
      return linkVideo;
    },

    handleYoutubeLink(link) {
      const barSplited = link.split("/");
      const lastSplited = barSplited.pop();
      const codeSplited = lastSplited.split("=");
      return `https://www.youtube.com/embed/${codeSplited.pop()}`;
    },

    mountImg(curso) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=curso&identificador=${curso.id}&extensao=${curso.extensaoBanner}&expressao=horizontal`;
    },
  },

  computed: {
    ...mapGetters({
      isMobile: "configs/isMobile",
      user: "registration/getUser",
      allVideos: "temporadas/getAllVideos",
    }),

    currentVideo() {
      return Number(this.$route.params.video);
    },

    lockVideo() {
      return this.allVideos.some(
        ({ isPro, id }) => isPro && id === this.currentVideo && !this.user.isPro
      );
    },

    isSmall() {
      return this.$store.getters.getWidth < 2100;
    },

    curso() {
      return this.$store.getters["cursos/getCurso"];
    },
  },

  watch: {
    "$route.path": {
      handler() {
        this.createStructure();
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.p-54
  padding-bottom: 56%
.p-55
  padding-bottom: 56%
.flex-video
  position: relative
  height: 0
  margin: 0 auto
  .frame
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    .vp-center
      align-items: baseline !important
img
  width: 100%
.loading-video
  padding-top: 40vh

video::-internal-media-controls-download-button
  display: none

video::-webkit-media-controls-enclosure
  overflow: hidden

video::-webkit-media-controls-panel
  width: calc(100% + 30px)
</style>
