<template>
  <section class="bg pm-none">
    <div class="row pm-none">
      <div
        @scroll="scroll()"
        id="main-scroll"
        :class="[
          'col height-100vh scroll position-relative',
          isFullPage ? 'px-0' : '',
        ]"
      >
        <div
          id="main-container"
          :class="[
            'container pm-sm-none position-relative',
            isFullPage ? 'px-0 pt-0' : 'pt-4',
          ]"
        >
          <router-view :direction="direction">
            <template #parceiros v-if="!isMobile">
              <Parceiros />
            </template>
          </router-view>
        </div>
        <div v-if="isHome && !isMobile" class="box-seta">
          <span @click="scrolTo()" :class="setaStyle()" />
        </div>
      </div>

      <div class="width-20 height-100vh menu-direito bg-black pt-3">
        <Followers v-if="user.id" />
        <Campaing v-if="user.id" />
      </div>

      <Parceiros v-if="isMobile" class="my-2" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Parceiros from "@/components/modules/home/Parceiros";
import Campaing from "@/components/shared/mainContainer/Campaing";
import Followers from "@/components/shared/mainContainer/Followers";

export default {
  components: {
    Campaing,
    Followers,
    Parceiros,
  },

  data() {
    return {
      direction: "down",
    };
  },

  created() {
    if (this.$route.name === "default") this.$router.push({ name: "home" });

    if (this.$enable) {
      setInterval(() => {
        this.$checkIfDebuggerEnabled().then((result) => {
          if (result) this.logut();
        });
      }, 2000);
    }
  },

  methods: {
    logut() {
      localStorage.setItem("tokenPlataformaEducando", false);
      this.$store.dispatch("registration/logOut");
      this.$router.push({ name: "login" });
      location.reload();
    },

    scrolTo() {
      const dist = document.getElementById("main-container");
      dist.scrollIntoView({
        block: this.direction === "up" ? "start" : "end",
        behavior: "smooth",
      });
    },

    setaStyle() {
      const styleDirection = this.direction === "up" ? "seta-up" : "";
      return `seta pointer ${styleDirection}`.trim();
    },

    scroll() {
      const alturaScroll = document.getElementById("main-container");
      const scrollHeight = alturaScroll.parentNode.scrollHeight;
      const windowHeight = window.innerHeight;
      const scroll = alturaScroll.parentNode.scrollTop;
      const position = scroll + windowHeight;
      this.direction = position >= scrollHeight - 100 ? "up" : "down";
    },
  },

  computed: {
    ...mapGetters({
      isMobile: "configs/isMobile",
      isLoadingCurso: "cursos/isLoading",
      user: "registration/getUser",
      isLoadingDoc: "documentarios/isLoading",
    }),

    isFullPage() {
      return [
        "home",
        "cafe",
        "cursos",
        "arquivos",
        "tipos",
        "tipo",
        "forum",
        "topico",
        "galeria",
        "perfil",
        "perfis",
      ].includes(this.$route.name);
    },

    isHome() {
      const fullPage = this.$route.name === "home";
      const loading = !this.isLoadingCurso && !this.isLoadingDoc;
      return fullPage && loading;
    },
  },
};
</script>
<style lang="sass">
.menu-direito
  display: grid !important
  grid-template-rows: auto 292px
.box-seta
  z-index: 9
  position: sticky
  bottom: 0
  transform: translateX(-10px)
  width: calc(100% + 20px)
  height: 50px
  background-image: linear-gradient(transparent, black 80%)
  .seta
    right: calc(50% - 10px)
    transform: rotate(45deg)
    width: 15px
    height: 15px
    top: 40%
    position: absolute
    transition: all .25s
    border-right: 3px solid #ddd
    border-bottom: 3px solid #ddd
  .seta-up
    top: 50%
    transform: rotate(-135deg) !important
.bg
  background-color: rgba(21,21,21,1)
.scroll
  max-height: 100vh
  overflow: scroll
  overflow-x: hidden
.scroll::-webkit-scrollbar
  width: 3px
  background: #222 !important
  border-radius: 3px
.scroll::-webkit-scrollbar-track
  border-radius: 3px
.scroll::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff4163
  max-height: 50px
#main-container
  min-height: 100vh !important
.height-100vh
  height: 100vh !important
.width-20
  width: 300px
@media (min-width: 1400px)
  #main-container
    min-width: 100% !important
@media (max-width: 600px)
  .pm-none
    // margin: calc(1.5rem * .5) !important
    // padding: calc(1.5rem * .5) !important
  .scroll
    overflow: visible !important
  #main-container
    min-height: auto !important
  .pm-sm-none
    margin: 0px !important
    padding: 0px !important
  .height-100vh, .scroll
    height: 100% !important
    max-height: 100% !important
  .width-20
    width: max-content !important
  .bg-black
    background-color: #161616 !important
  .menu-direito
    width: 100% !important
    grid-template-rows: auto auto !important
</style>
