<template>
  <section class="w-100 mb-3">
    <p
      v-if="label"
      class="font-14 mb-1 white-2"
    >
      {{ label }}
    </p>

    <input
      :required="true"
      :type="type"
      v-model="text"
      v-mask="mask"
      @blur="blur()"
      :placeholder="placeholder"
      :class="['border-r-3 input-empresa p-0 w-100', isInvalid ? 'is-invalid' : '']"
    >
  </section>
</template>

<script>
export default {
  name: 'InputComp',

  props: {
    value: {},

    type: {
      default: 'text'
    },

    mask: {
      default: ''
    },

    isInvalid: {
      type: Boolean,
      default: false
    },

    placeholder: {},

    label: {}
  },

  data () {
    return {
      text: this.value || ''
    }
  },

  methods: {
    blur () {
      this.$emit('input', this.text)
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        if (value === this.text) return
        this.text = value
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.is-invalid
  border: 1px solid #ff4163 !important
  animation: is-invalid 400ms
.input-empresa
  border-radius: 5px
  border: 1px solid #777
  color: #999 !important
  outline: none !important
  padding: 5px 10px !important
  background-color: transparent
  padding-left: 10px !important
  &::-webkit-input-placeholder
    opacity: .65 !important
  &:focus
    border: 1px solid white
.white-2
  color: #ddd
</style>
