<template>
  <section class="row">
    <div class="col-8 p-0 scrolling-modulos">
      <div class="row">
        <div class="col-12">
          <img class="curso-em-breve"
            :src="`${this.$baseApiURL}/acesso-arquivo?caminho=curso&extensao=${curso.extensaoBanner}&expressao=horizontal&identificador=${curso.id}`" />
        </div>

        <div class="col-12">
          <div class="pt-4 py-3 px-4 bg-dark">
            <div class="row justify-content-between">
              <div class="col-auto pink font-20">
                {{ curso.nome }}
              </div>
              <div class="col-auto font-13 opacity-75">Em Breve</div>
            </div>

            <div class="row align-items-center mb-3">
              <div class="col-auto p-0">
                <div class="img-coord" v-if="curso.extensaoFotoCoordenador && curso.idCoordenador">
                  <img
                    :src="`${this.$baseApiURL}/acesso-arquivo?caminho=curso&identificador=${curso.idCoordenador}&extensao=${curso.extensaoFotoCoordenador}&expressao=${curso.exp}`" />
                </div>
              </div>
              <div class="col">
                <span class="font-14 infos extra-light secondary me-1 me-md-4">
                  <span class="dark-grey-2 me-2">Coordenador: </span>
                  {{ curso.nomeCoordenador }}
                </span>
              </div>
            </div>

            <div class="font-14 infos extra-light secondary col-auto mt-1"
              v-for="(item, ind) in Object.keys(corpoModal.informacoes)" :key="`infos-${ind}`">
              <component :is="icons[item]" class="me-2" />

              <span class="dark-grey-2 me-2">
                {{ split(0, corpoModal.informacoes, item) }}:
              </span>
              {{ split(1, corpoModal.informacoes, item) }}
            </div>

            <div class="font-14 infos extra-light secondary me-1 me-md-4 mt-2">
              <span class="dark-grey-2 me-2">Descrição:</span>
              {{ corpoModal.descricao }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-4 scrolling-modulos">
      <div class="py-3 px-3 bg-dark border-radius-10 mt-2">
        <div class="bonus pt-3">
          <h4 class="pink upper font-18">Bônus</h4>
          <ul class="list-modal">
            <li v-for="(bonus, indDetalheBonus) in corpoModal.bonus" :key="`${bonus}-bonus-${indDetalheBonus}`"
              class="secondary font-14">
              {{ bonus }}
            </li>
          </ul>
        </div>

        <div class="modulos mt-3">
          <h4 class="pink upper mb-2 font-18 pointer" data-toggle="collapse" data-target="#collapse-modal"
            @click="isOpen = !isOpen">
            {{ isOpen ? "-" : "+" }} Módulos do curso
          </h4>
          <div class="collapse show p-2" id="collapse-modal">
            <div v-for="(modulo, indModulos) in corpoModal.modulos"
              :key="`collapse-modal-${indModulos}-${modulo.titulo}`">
              <h6 class="mb-1 white">
                {{ modulo.titulo }}
              </h6>
              <div class="items pb-3">
                <ul class="list-modal">
                  <li v-for="(bonus, indBonus) in modulo.items" :key="`${bonus}-${indBonus}`" class="secondary font-14">
                    {{ bonus }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ClockIcon from "@/assets/icons/Clock";
import WhatsAppIcon from "@/assets/icons/WhatsApp";
import CheckCircleIcon from "@/assets/icons/CheckCircle";
import UserGraduateIcon from "@/assets/icons/UserGraduate";
import FacebookCircleIcon from "@/assets/icons/FacebookCircle";
import ChalckboardTeacherIcon from "@/assets/icons/ChalckboardTeacher";

export default {
  name: "EmBreve",

  components: {
    ClockIcon,
    ChalckboardTeacherIcon,
    UserGraduateIcon,
    FacebookCircleIcon,
    CheckCircleIcon,
  },

  data() {
    return {
      isOpen: true,

      icons: {
        whatsApp: WhatsAppIcon,
        cargaHoraria: ClockIcon,
        garantia: CheckCircleIcon,
        facebook: FacebookCircleIcon,
        aulas: ChalckboardTeacherIcon,
        certificado: UserGraduateIcon,
      },
    };
  },

  methods: {
    split(position, item, key) {
      return item?.[key]?.split(":")?.[position] || "";
    },
  },

  computed: {
    corpoModal() {
      return JSON.parse(this.curso?.detalhesCorpoModal || "{}");
    },

    curso() {
      return this.$store.getters["cursos/getCurso"];
    },
  },
};
</script>
<style lang="sass">
::-webkit-scrollbar
  width: 3px
  background: #222 !important
  border-radius: 3px
::-webkit-scrollbar-track
  border-radius: 3px
::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff4163
  max-height: 50px
.scrolling-modulos
  max-height: 100vh
  overflow: scroll
  overflow-x: hidden
svg
  fill: #ff4163 !important
  opacity: .75
.curso-em-breve
  width: 100%

.img-coord
  background-color: #fff
  border-radius: 100%
  width: 30px !important
  height: 30px !important
  overflow: hidden

  img
    border-radius: 100%
    width: 30px !important
    height: 30px !important
</style>
