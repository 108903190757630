import { render, staticRenderFns } from "./Conquistas.vue?vue&type=template&id=8dc73ce8&scoped=true&"
import script from "./Conquistas.vue?vue&type=script&lang=js&"
export * from "./Conquistas.vue?vue&type=script&lang=js&"
import style0 from "./Conquistas.vue?vue&type=style&index=0&id=8dc73ce8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dc73ce8",
  null
  
)

export default component.exports