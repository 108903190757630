export default {
  methods: {
    mountImagePath (caminho, id, ext) {
      if (!caminho || !id || !ext) return false
      return `${this.$baseApiURL}/acesso-arquivo?caminho=${caminho}&identificador=${id}&extensao=${ext}`
    },

    validateHtml (text) {
      text = text.replaceAll('</p><p>', ' </p> <p> ')
      const palavras = text.split(' ')
      const words = palavras.map(word => {
        try {
          let newWord = word
          const hasInitialTagP = newWord.includes('<p>') ? '<p>' : ''
          const hasFinalTagP = newWord.includes('</p>') ? '</p>' : ''

          if (hasInitialTagP) newWord = newWord.replace('<p>', '')
          if (hasFinalTagP) newWord = newWord.replace('</p>', '')

          const isUrl = isValidUrl(newWord)
          if (!isUrl) return word

          word = `${hasInitialTagP} <a href="${newWord}" rel="noopener noreferrer" target="_blank">${newWord}</a> ${hasFinalTagP}`

          const isYoutube = newWord.includes('youtu')
          if (isYoutube) {
            const { searchParams, pathname } = new URL(newWord)
            const v = searchParams.get('v')
            const code = v || pathname.replace('/', '')
            const iframe = `
              <iframe 
                width="288" 
                height="162" 
                src="https://www.youtube.com/embed/${code}"
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
              >
              </iframe>
            `
            word = `${word}<br>${iframe}<br>`
          }
        } catch (_) {}
        return word
      })

      return words.join(' ')
    }
  }
}

const isValidUrl = urlString => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$', 'i')
  return !!urlPattern.test(urlString)
}
