<template>
  <div class="row justify-content-between align-items-center w-100">
    <div class="col-auto self-center mt-1 ps-0">
      <label class="upper dark-grey-2 font-20">
        Chave
      </label>
    </div>

    <div class="col px-0">
      <input
        type="text"
        class="input-model w-100"
        placeholder="Insira a chave de acesso do seu gift card"
        v-model="key"
      >
    </div>

    <div class="col-auto pe-0">
      <p
        class="btn upper dark-grey-2"
        @click.stop="checkKey()"
      >
        Ok
      </p>
    </div>
    <LoadingComp v-if="isLoading" />
    <p
      v-if="error"
      class="text-center mt-3 is-invalid pink"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import Service from '@/service/empresasService'
export default {
  data () {
    this.ResourceEmpresa = new Service(this.$resource)
    return {
      key: '',
      error: '',
      isLoading: false
    }
  },

  methods: {
    async checkKey () {
      this.isLoading = true
      this.error = ''
      const { status, body } = await this.ResourceEmpresa.validarCodigo({ codigo: this.key })
      this.isLoading = false

      if (status === 202) {
        this.error = ''
        this.$emit('input', { code: this.key, id: Number(body) })
      } else {
        // this.$emit('input', { code: '', id: '' })
        this.error = typeof body === 'string' ? body : 'Código inválido'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.self-center
  align-self: center !important
.btn
  background-color: #fff
  border-radius: 10px
h2
  letter-spacing: 2px
  margin-top: 5px
.bar
  font-size: 50px
  margin-top: -12px
  font-style: italic
.logo
  width: 150px !important
.input-model
  height: 38px
  border-radius: 10px
  font-size: 14px
  padding-left: 15px
  outline: none !important
</style>
