<template>
  <div class="footer pt-2 align-content-end">
    <div class="row align-items-end">
      <div class="col-auto mb-2">
        <img
          :src="perfilImage"
          class="img-perfil-current"
          @error="hasError = true"
          v-if="user.extensaoImagem && !hasError"
        >
        <div
          class="image-work bold img-perfil-current bg-black text-center pt-2"
          data-toggle="modal"
          data-target="#modalImageProfile"
          v-else
        >
          {{ user.nome ? user.nome[0] : '' }}
        </div>
      </div>

      <div class="col ps-0">
        <input id="base-focus">
        <TextArea
          label=""
          :key-press="true"
          @keyPressed="keyPressed()"
          :is-disabled="isLoading"
          v-model="comment"
        />
      </div>

      <div class="col-auto mb-2 ps-0 pointer disabled">
        <div
          class="loading-btn mb-1"
          v-if="isLoading"
        />

        <span
          v-else
          id="gerarComentario"
          @click="gerarComentario()"
        >

          <SendIcon />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@/Mixins'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters, mapActions } from 'vuex'
import { months } from '@/assets/js/functions'
import SendIcon from '@/assets/icons/SendIcon'
import FeedService from '@/service/feedService'
import UserService from '@/service/userService'
import GaleryService from '@/service/galeryService'
import TextArea from '@/components/shared/forms/FieldTextQuill'

export default {
  name: 'ModalHeader',

  mixins: [Mixins],

  components: {
    TextArea,
    SendIcon
  },

  props: {
    aluno: {
      type: Object,
      default: () => {}
    },
    isCdn: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      album: 'galery/getAlbum',
      user: 'registration/getUser'
    }),

    perfilImage () {
      return this.mountImagePath('educando', this.user.id, this.user.extensaoImagem)
    }
  },

  data () {
    this.FeedService = new FeedService(this.$resource)
    this.UserService = new UserService(this.$resource)
    this.GaleryService = new GaleryService(this.$resource)

    return {
      comment: '',
      hasError: false,
      isLoading: false
    }
  },

  methods: {
    ...mapActions({
      setComments: 'galery/setComments'
    }),

    keyPressed () {
      document.getElementById('base-focus').focus()
      setTimeout(() => {
        document.getElementById('gerarComentario').click()
      }, 200)
    },

    newMessage (id, msg, isAnswer, idComentario) {
      const now = new Date()
      const conquistas = this.wins.map(win => ({
        src: win.image,
        titulo: win.titulo
      }))

      return {
        id,
        isAnswer,
        likes: 0,
        views: '0',
        imagens: [],
        messages: [],
        descricao: msg,
        isLiked: false,
        idAluno: this.user.id,
        isLoadingImagens: false,
        ocupacao: this.user.ocupacao,
        conquistasEducando: conquistas,
        idComentario: idComentario || '',
        hasImage: Boolean(this.user.extensaoImagem),
        aluno: `${this.user.nome} ${this.user.sobrenome}`,
        img: this.mountImagePath('educando', this.user.id, this.user.extensaoImagem),
        data: `${Number(now.getDate())} ${months[now.getMonth()]} ${now.getFullYear()}`
      }
    },

    async gerarComentario () {
      if (this.isCdn) {
        const params = {
          idFeed: this.$route.params.album,
          idFeedComment: this.$route.params.album,
          mensagem: this.validateHtml(this.comment),
          comentario: this.validateHtml(this.comment)
        }

        this.comment = ''
        const message = this.$parent.$parent.newMessage(params.idFeed, params.mensagem, true, uuidv4())
        this.$parent.$parent.inserirMensagem(this.album, message)
        const paramsGalery = {
          id: message.idComentario,
          aluno: {
            name: message.aluno,
            img: message.img
          },
          comment: params.mensagem,
          idEducando: message.idAluno,
          time: message.data,
          answers: []
        }
        this.setComments(paramsGalery)

        this.$sweet.sweetWait('Estamos inserindo')
        setTimeout(() => this.$sweet.sweetClose(), 10)

        const { body } = await this.FeedService.comentarFeed(params)
        paramsGalery.id = Number(body)
        message.idComentario = Number(body)

        const userService = new UserService(this.$resource)
        userService.setConquistas({ idConquista: 6 })
        return
      }

      const idGalery = this.$route.params.album
      if (!idGalery || this.isLoading) return

      const params = {
        idGalery,
        idTeaching: this.user.id,
        comentario: this.comment
      }

      if (!idGalery || !params.idTeaching || !params.comentario) return

      const message = {
        id: '',
        time: '0min',
        answers: [],
        comment: params.comentario,
        aluno: {
          name: this.user.nome,
          img: this.mountImagePath('educando', this.user.id, this.user.extensaoImagem)
        }
      }

      this.comment = ''
      this.setComments(message)
      const { body } = await this.GaleryService.postGaleryJobComments(params)
      message.id = body
    }
  }
}
</script>

<style lang="sass" scoped>
.footer
  border-top: 1px solid #444
  .img-perfil-current
    object-fit: cover
    border-radius: 50%
    width: 40px !important
    height: 40px !important
  #base-focus
    height: 0
    padding: 0
    opacity: 0
    position: absolute
</style>
