<template>
  <div
    @click="goTo(idTemporada, video.idVideo, video.idTemporadaVideo)"
    :class="[
      'mb-2 pt-2 pb-1 video pointer',
      $route.params.video == video.idVideo ? 'is-current-video' : '',
    ]"
  >
    <div class="d-inline-flex link relative">
      <div class="d-inline-flex">
        <CheckIcon class="checkIcon" v-if="video.videoVisto === 'S'" />

        <div :class="['position-relative', isLocked && 'locked']">
          <LockIcon class="lock" v-if="isLocked" />

          <img
            :src="imgPng"
            class="thumbnail"
            v-if="!errorPng"
            @error="errorPng = true"
          />

          <img
            :src="imgJpg"
            class="thumbnail"
            v-else-if="!errorJpg"
            @error="errorJpg = true"
          />

          <img
            class="thumbnail"
            :src="defaultCourse"
            v-else-if="!errorCapa"
            @error="errorCapa = true"
          />
        </div>

        <div class="ms-2">
          <p class="dark-grey-2 font-13">
            <img
              class="me-1 clock"
              :src="require('@/assets/images/clock-2.png')"
            />
            {{ mountTime(video.duracaoVideo) }}
          </p>

          <p
            class="font-13 title-video"
            :id="`${idTemporada}-${video.idVideo}-scroll-thumb`"
          >
            {{ mountTitle(video.tituloVideo) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CheckIcon from "@/assets/icons/Check";

export default {
  components: { CheckIcon },

  props: {
    video: {},
    idTemporada: {},
  },

  data() {
    return {
      errorJpg: false,
      errorCapa: false,
      errorPng: false,
    };
  },

  methods: {
    goTo(modulo, video, idTemporadaVideo) {
      if (this.isLocked) return;

      const curso = this.$route.params.id;
      this.$router
        .push({
          name: "video",
          params: { id: curso, modulo, video, idTemporadaVideo },
        })
        .catch((e) => e);
    },

    mountTime(time) {
      const curr = `${time}`;
      if (curr.length === 1) return `0${curr}:00`;
      if (curr.length === 2) return `${curr}:00`;
      return time;
    },

    mountTitle(tituloVideo) {
      const titulo = `${tituloVideo}`.split("-");
      titulo.shift();
      return titulo.join("-").trim();
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
    }),

    curso() {
      return this.$store.getters["cursos/getCurso"];
    },

    isLocked() {
      return this.video.isPro && !this.user.isPro;
    },

    imgPng() {
      return `https://privado.sfo3.cdn.digitaloceanspaces.com/thumbnails/${this.video.idVideo}.png`;
    },

    imgJpg() {
      return `https://privado.sfo3.cdn.digitaloceanspaces.com/thumbnails/${this.video.idVideo}.jpg`;
    },

    defaultCourse() {
      const { id, extensaoBanner } = this.curso;
      return `${this.$baseApiURL}/acesso-arquivo?caminho=curso&identificador=${id}&extensao=${extensaoBanner}&expressao=horizontal`;
    },
  },
};
</script>

<style lang="sass" scoped>
.is-current-video
  background: #1a1a1a

.video
  padding-left: 30px
  padding-right: 20px
  &:hover
    background: #171717
.locked
  img
    filter: brightness(.5)
.link
  &:hover
    .dark-grey-2
      color: #646464 !important

    .title-video
      color: #fff !important

.thumbnail
  width: 80px
  object-fit: contain

.relative
  position: relative

.checkIcon
  width: 13px
  left: -23px
  position: absolute
  top: 50%
  transform: translateY(-50%)

.accordion
  max-height: 500px

a
  text-decoration: none

::-webkit-scrollbar
  width: 3px
  background: #222 !important
  border-radius: 3px

::-webkit-scrollbar-track
  border-radius: 3px

::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff4163
  max-height: 50px

.scrolling-templates
  max-height: calc(100vh - 115px - 180px)
  overflow: scroll
  overflow-x: hidden

.bg-black
  border-radius: 3px
  border: 1px solid #222

.temp-header
  display: inline-flex
  justify-content: space-between
  padding-right: 20px
  padding-left: 30px

.chevron
  transition: all .25s

.chevron-down
  transform: rotate(180deg)

.clock
  width: 13px
  margin-top: -3px

.collapsed
  opacity: 0
  max-height: 0px
  overflow: hidden
  transition: all .5s

.show
  opacity: 1
  padding-top: 10px
  padding-bottom: 10px
  transition: max-height 2s, opacity 1s, padding-top .5s !important
  max-height: 10000px !important

.lock
  top: 50%
  left: 50%
  z-index: 1
  position: absolute
  transform: translateX(-50%) translateY(-50%)

@media (max-width: 500px)
  .temp-header
    &:after
      width: 15px
      height: 15px
      left: -2px

  .scrolling-templates
    overflow: visible !important

  ::-webkit-scrollbar
    width: 3px

  h5
    font-size: 14px !important

  li
    font-size: 14px !important
    margin-bottom: 3px !important
</style>
