<template>
  <div>
    <button
      v-if="showButton"
      data-toggle="modal"
      data-target="#modal-new-post"
      id="btn-modal-new-post"
      class="btn-fill white border-0"
    >
      Criar novo projeto
    </button>

    <div>
      <button
        class="d-none"
        data-toggle="modal"
        @click="closeModal()"
        data-target="#modal-new-post"
        id="btn-modal-new-post"
      />

      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        id="modal-new-post"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content modal-content-new-post">
            <div class="modal-body p-4 pb-3">
              <div
                @submit.prevent="publicar"
                class="d-block d-md-flex flex-column height-100 height-sm-auto"
              >
                <div class="d-block d-md-flex flex-row height-100 height-sm-auto border-grey">
                  <div class="col-12 col-md-5 border-grey-end height-100 height-sm-auto mt-3 mt-md-0">
                    <FieldFiles
                      ref="field-files"
                      v-model="form.images"
                      :capa.sync="form.capa"
                      :ind-capa.sync="form.indCapa"
                    />
                  </div>

                  <div class="col-12 col-md-7 height-100 height-sm-auto p-3 pb-0 pe-0 overflow-scroll">
                    <div class="d-grid pe-3">
                      <div
                        class="mb-3"
                        v-if="form.capa"
                      >
                        <p class="font-14">
                          Capa do projeto
                        </p>

                        <img
                          :src="form.capa"
                          class="new-capa bg-dark"
                        >
                      </div>

                      <div v-if="softwares">
                        <p class="font-14">
                          Ferramentas utilizadas
                        </p>

                        <MultSelect
                          :list="softwares"
                          classe="min-height"
                          v-model="form.softwares"
                        />
                      </div>

                      <div
                        class="mt-3"
                        v-if="showButton"
                      >
                        <p class="font-14">
                          Tags do projeto
                        </p>

                        <FieldTags v-model="form.tags" />
                      </div>

                      <div class="mt-3">
                        <p class="font-14 mb-0">
                          Descrição do trabalho
                        </p>

                        <FieldTextQuill
                          label=""
                          classe="min-height"
                          v-model="form.descricao"
                        />
                      </div>

                      <div
                        class="mt-3"
                        v-if="!showButton"
                      >
                        <input
                          id="minha-galeria"
                          type="checkbox"
                          class="checkmark"
                          v-model="form.myGalery"
                        >
                        <label
                          for="minha-galeria"
                          class="user-select-none ms-2 font-14 dark-grey-2 pointer"
                        >
                          Mostrar imagem no meu perfil
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-end mt-3">
                  <span
                    @click="closeModal()"
                    data-dismiss="modal"
                    id="close-modal-new-album"
                    class="pointer me-5 cancel"
                  >
                    Cancelar
                  </span>

                  <button
                    :class="['btn-fill', !form.images.length && 'opacity-50']"
                    @click="publicar"
                    :disabled="!form.images || !form.images.length"
                  >
                    Publicar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Mixins from '@/Mixins'
import { mapGetters, mapActions } from 'vuex'
import GaleryService from '@/service/galeryService'
import SoftwaresService from '@/service/softwaresService'
import FieldTags from '@/components/shared/forms/FieldTags'
import MultSelect from '@/components/shared/forms/MultSelect'
import FieldFiles from '@/components/shared/forms/FieldFiles'
import FieldTextQuill from '@/components/shared/forms/FieldTextQuill'

const initialParams = {
  capa: '',
  titulo: '',
  descricao: '',
  tags: [],
  images: [],
  softwares: [],
  indCapa: null,
  myGalery: false,
  isLoading: false
}

export default {
  mixins: [Mixins],

  components: {
    FieldTags,
    MultSelect,
    FieldFiles,
    FieldTextQuill
  },

  props: {
    showButton: {
      defaut: true,
      type: Boolean
    },

    callback: {
      type: Function,
      default: () => {}
    },

    hasCallback: {
      type: Boolean,
      default: false
    }
  },

  created () {
    if (this.softwares.length) return

    this.GaleryService = new GaleryService(this.$resource)
    this.SoftwaresService = new SoftwaresService(this.$resource)
    this.getSoftwares()
  },

  data () {
    this.GaleryService = new GaleryService(this.$resource)
    this.SoftwaresService = new SoftwaresService(this.$resource)

    return {
      count: 0,
      idGalery: null,
      hasError: false,
      form: { ...initialParams }
    }
  },

  methods: {
    ...mapActions({ setAllSoftwares: 'softwares/setAll' }),

    closeModal () {
      this.$refs['field-files']?.remove()
      this.form.tags = []
      this.form.softwares = []
      this.form.descricao = ''
      this.form.myGalery = false
    },

    async getSoftwares () {
      if (this.softwares.length) return
      const { body: softwares } = await this.SoftwaresService.get()
      this.setAllSoftwares(softwares.map(({ id, nome, extensaoImagem }) => ({ id, name: nome, img: this.mountImagePath('software', id, extensaoImagem) })))
    },

    async publicar () {
      const { images, descricao, softwares, capa } = this.form
      const isValidParams = images && descricao && softwares && capa
      if (!isValidParams) return (this.$sweet.sweetMensagem('Por favor, insira todos os dados'))

      if (this.hasCallback) return (this.callback(this.form))

      this.hasError = false
      this.GaleryService = new GaleryService(this.$resource)
      this.SoftwaresService = new SoftwaresService(this.$resource)
      this.$sweet.sweetWait('Aguarde enquanto estamos preparando sua galeria')
      this.hasError = false
      const idGalery = await this.createGalery()
      this.idGalery = idGalery

      this.count = 0
      this.setSoftwares(idGalery)
      this.vincularTags(idGalery)
      this.criarTrabalhos(idGalery)
      this.criarCapa(idGalery)
      this.form.isLoading = true
      setTimeout(() => { if (this.form.isLoading) this.concluir() }, 10000)
    },

    async createGalery () {
      const { titulo, descricao } = this.form
      const { data } = await this.GaleryService.postMyGalery({ titulo, descricao })
      return data
    },

    async setSoftwares (idGalery) {
      const idsSoftwares = this.form.softwares
      this.count = this.count + 1
      const { status } = await this.GaleryService.putGalerySoftwares({ idGalery, idsSoftwares })
      this.checkError(status, 'setSoftwares')
    },

    async vincularTags (idGalery) {
      for (const tag of this.form.tags) {
        this.count = this.count + 1
        this.sendTags(idGalery, tag)
      }
    },

    async sendTags (idGalery, tag) {
      const { status } = await this.GaleryService.postGaleryTag({ idGalery, tag })
      this.checkError(status, `vincularTags - ${tag}`)
    },

    async criarCapa (idGalery) {
      this.count = this.count + 1
      const caminho = 'galerias'
      const action = 'criarCapa'
      const campo = 'extensao_capa'
      this.postImage(this.form.images[this.form.indCapa], idGalery, caminho, campo, action)
    },

    async criarTrabalhos (idGalery) {
      for (const [ind, image] of this.form.images.entries()) {
        this.count = this.count + 3
        this.createJob(ind, idGalery, image)
      }
    },

    async createJob (ind, idGalery, image) {
      const campo = 'extensao'
      const caminho = 'trabalhos'
      const action = `criarTrabalhos - ${ind}`

      const { data: identificador, status } = await this.GaleryService.postGaleryJob({ idGalery })
      this.checkError(status, action)
      const route = `/plataforma-conteudo/minha-galeria/${idGalery}/trabalho/${identificador}/arquivo`

      this.postImage(image, identificador, caminho, campo, action, route)
    },

    async postImage (image, identificador, caminho, campo, action, route) {
      const headers = { 'Content-Type': 'multipart/form-data' }
      const dataForm = this.prepareForm(image, identificador, caminho, route)
      const path = route || '/acesso-arquivo'
      const { body: valor, status: statusPic } = await this.$http.post(`${Vue.http.options.root}${path}`, dataForm, { headers }).catch(err => err)
      this.checkError(statusPic, action)
      if (route) return (this.count = this.count - 1)

      const data = { identificador, caminho, tabela: `galeria.${caminho}`, campo, valor }
      const { status } = await this.$http.put(`${Vue.http.options.root}/atualiza-campo`, data).catch(err => err)
      this.checkError(status, action)
    },

    prepareForm (image, id, caminho, route) {
      const dataForm = new FormData()
      dataForm.append(route ? 'trabalho' : 'arquivo', image)
      if (!route) dataForm.append('identificador', id)
      if (!route) dataForm.append('caminho', caminho)
      return dataForm
    },

    checkError (status, step) {
      this.count = this.count - 1
      if (this.hasError) return

      if (status >= 200 && status < 300) {
        if (this.count === 0) this.concluir()
        return
      }

      this.hasError = true
      this.form.isLoading = false
      throw new Error(`Erro ao cadastrar galeria: step - ${step}`)
    },

    concluir () {
      this.form = { ...initialParams }
      this.$sweet.sweetSuccessResp('<h4>Galeria criada com sucesso!')
      this.$emit('listBy')
      document.getElementById('btn-modal-new-post').click()
    }
  },

  watch: {
    hasError: {
      deep: true,
      handler (hasError) {
        if (hasError) {
          this.$sweet.sweetError()
          if (!this.idGalery) return
          this.GaleryService.deleteMyGalery({ idGalery: this.idGalery })
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      softwares: 'softwares/getAll'
    })
  }
}
</script>

<style lang="sass">
#btn-modal-new-post
  background-color: #009a00
.modal-content-new-post
  background-color: #1f1f1f
  .ql-container
    background: transparent
  .ql-editor
    border: 1px solid #606060
    border-radius: 3px
    p
      color: #c5c5c5
  .ql-editor.ql-blank::before
    color: #646464
  .ql-toolbar
    padding-top: 0px !important
  .d-grid
    max-height: 73vh
    grid-template-rows: auto 1fr auto
  ::-webkit-scrollbar
    width: 0px
  .min-height
    height: 28px !important
  .height-100
    height: 100%
  .modal-body
    height: 90vh
  .border-grey
    border: 1px solid #444
  .border-grey-end
    border-right: 1px solid #444
  .btn-fill
    width: 100px
  .new-capa
    object-fit: contain
    object-position: left
    width: 100%
    height: 130px
@media (max-width: 600px)
  .height-sm-auto
    height: auto !important
  .modal-body
    height: auto !important
  .cancel
    font-size: 13px !important
  .new-capa
    height: 80px !important
</style>
