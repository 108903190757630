<template>
  <div class="header mb-1 pt-md-2 pt-0 mb-md-3 px-md-3 pb-2 px-0">
    <div
      v-if="aluno"
      class="d-inline-flex"
    >
      <div class="pe-0">
        <router-link :to="{ name: 'perfil', params: { user: aluno.id } }">
          <img
            :src="aluno.img"
            v-if="!errorImage && aluno.img && !isLoading"
            class="img-perfil me-2"
            @error="replaceByDefault"
          >
          <div
            v-else
            class="img-perfil me-2"
          >
            <span>{{ aluno.nome[0] }}</span>
          </div>
        </router-link>
      </div>

      <div class="col-auto">
        <div class="item-center align-items-center">
          <router-link :to="{ name: 'perfil', params: { user: aluno.id } }">
            <h6 :class="['mb-0 pb-0 font-14 elipse', calcWidth]">
              {{ aluno.nome }}
            </h6>
          </router-link>

          <p :class="['dark-grey-2 mb-0 pb-0 font-13 elipse', calcWidth]">
            {{ aluno.ocupacao }}
          </p>

          <div :class="['conquests-modal overflow-hidden', calcWidth]">
            <div :class="['d-inline-flex overflow-scroll mt-1', calcWidth]">
              <div
                class="pe-1 ps-0"
                :key="conquista.titulo"
                v-for="conquista in conquistas"
              >
                <img
                  class="conquests"
                  :src="conquista.src || conquista.img"
                  :title="conquista.titulo"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <div class="d-inline-flex">
        <div
          class="dropdown dropleft"
          v-if="isCurrentUser"
          title="Excluir galeria"
        >
          <span
            id="dropdownMenuButton"
            class="dropleft pointer bg-dark border-radius-50 p-1"
            data-toggle="dropdown"
          >
            <VerticalDots />
          </span>

          <div class="dropdown-menu bg-dark">
            <div
              class="ms-3 pointer"
              @click="excluirGaleria()"
            >
              <TrashIcon class="me-2" />
              <span class="white font-14 hovered">Excluir</span>
            </div>
          </div>
        </div>

        <div
          v-else
          class="text-center me-3"
        >
          <div
            class="loading-btn mb-1"
            v-if="isLoadingLike"
          />
          <HeartIcon
            v-else-if="!liked"
            classe="big"
            class="pointer"
            @click.native="giveLike()"
          />
          <HeartIconFill
            v-else
            classe="big"
            class="pointer"
            @click.native="giveLike()"
          />
          <p
            class="dark-grey-2 text-center font-12"
            v-if="album.likes"
          >
            {{ countLikes }}
          </p>

          <p class="dark-grey-2 text-center font-12">
            {{ liked ? 'Descurtir' : 'Curtir' }}
          </p>
        </div>

        <div
          class="col-auto"
          v-if="!isCurrentUser"
        >
          <button
            @click="followPerson()"
            :class="['pink font-12 py-0', following || isLoadingFollower ? 'btn-transparent' : 'btn-fill']"
          >
            <div
              class="loading-btn mb-1"
              v-if="isLoadingFollower"
            />
            <span v-else>
              {{ following ? 'Deixar de seguir' : 'Seguir' }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@/Mixins'
import { mapActions, mapGetters } from 'vuex'
import FeedService from '@/service/feedService'
import HeartIcon from '@/assets/icons/HeartIcon'
import TrashIcon from '@/assets/icons/TrashIcon'
import VerticalDots from '@/assets/icons/VerticalDots'
import FollowService from '@/service/followService'
import GaleryService from '@/service/galeryService'
import SharedResource from '@/service/sharedServices'
import HeartIconFill from '@/assets/icons/HeartIconFill'

export default {
  name: 'ModalHeader',

  mixins: [Mixins],

  components: {
    HeartIcon,
    TrashIcon,
    VerticalDots,
    HeartIconFill
  },

  props: {
    aluno: {
      type: Object,
      default: () => {}
    },

    isCdn: {
      type: Boolean,
      default: false
    },

    album: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    this.feedService = new FeedService(this.$resource)
    this.followService = new FollowService(this.$resource)
    this.galeryService = new GaleryService(this.$resource)
    this.sharedResource = new SharedResource(this.$resource)

    return {
      conquistas: [],
      loadedUser: 0,
      loadedLiked: 0,
      currentLikes: 0,
      loadedFollowing: 0,
      liked: false,
      isLoading: false,
      following: false,
      isLoadingLike: false,
      errorImage: false,
      isLoadingFollower: false
    }
  },

  created () {
    if (this.isCdn) return (this.setCdn())

    this.getConquests()
    this.iHaveLiked()
    this.iFollowingStudent()
  },

  methods: {
    ...mapActions({
      setLikedAlbum: 'galery/setLikedAlbum',
      setFollowingPerfil: 'perfil/setFollowing',
      setIsFollowing: 'followers/setIsFollowing',
      setFollowingsCount: 'perfil/setFollowingsCount'
    }),

    setCdn () {
      this.liked = this.album?.liked
      this.following = this.album.following
      this.conquistas = this.album.aluno?.conquistas || this.album?.conquistasEducando
    },

    replaceByDefault () {
      this.errorImage = true
    },

    async excluirGaleria () {
      const { isConfirmed } = await this.$sweet.sweetQuestionPersonalizado('Deseja excluir?', '<p>Uma vez excluído, não terá como recuperar.</p>')
      if (!isConfirmed) return

      if (this.isCdn) {
        const idFeed = this.album.idFeed
        this.$store.dispatch('feed/setExcludedItems', `${idFeed}-${this.album.idComentario}`)
        this.feedService.removerFeed({ idFeed })
        setTimeout(() => document.getElementById('btn-modal-picture').click(), 200)
        return
      }

      this.$sweet.sweetWait('Estamos deletando seu projeto')
      const { status } = await this.galeryService.deleteMyGalery({ idGalery: this.$route.params.album })
      if (status >= 300) return (this.$sweet.sweetMensagem('Ops, houve um erro ao deletar o projeto'))

      document.getElementById('btn-modal-picture').click()
      this.$parent?.$parent?.listBy()
      this.$sweet.sweetSuccessResp('<h4>Galeria removida com sucesso!</h4>')
    },

    async iHaveLiked () {
      const idGalery = this.$route.params.album
      if (!idGalery || this.loadedLiked === idGalery) return

      this.isLoading = true
      setTimeout(() => (this.isLoading = false), 300)

      this.isLoadingLike = true
      this.loadedLiked = idGalery
      const { body: liked } = await this.galeryService.iLiked({ idGalery })
      this.isLoadingLike = false
      this.liked = Boolean(liked.length)
    },

    async giveLike () {
      this.liked = !this.liked
      this.setLikedAlbum(Boolean(this.liked))

      if (this.isCdn) {
        this.$parent?.$parent?.$refs[`card-message-${this.$route.params.album}`]?.[0]?.handleLike(this.album)
        return
      }

      const idGalery = this.$route.params.album
      const action = !this.liked ? 'removeLike' : 'giveLike'

      this.galeryService[action]({ idGalery })
    },

    async followPerson () {
      const idEducando = Number(this.$route?.params?.user) || Number(this.album?.idAluno)
      if (!idEducando) return

      const action = this.following ? 'unfollow' : 'follow'

      if (this.isCurrentPerfil && !this.isCurrentUser) this.setFollowingsCount(this.perfil.following)

      if (!this.isCdn) this.setFollowingPerfil(!this.perfil.following)
      const params = this.isCdn ? { ...this.album.aluno, following: !this.following } : this.perfil
      this.setIsFollowing(params)
      this.following = !this.following
      this.followService[action]({ idEducando })
    },

    async getConquests () {
      const idEducando = Number(this.$route.params.user)
      if (!idEducando || this.loadedUser === idEducando) return
      this.loadedUser = idEducando

      const { body: conquistas } = await this.sharedResource.conquistasEducandos({ idEducando })
      const conquistasMap = conquistas.map(item => ({
        titulo: item.titulo,
        src: this.mountImagePath('conquista', item.id, item.extensaoImagem)
      }))
      this.conquistas = conquistasMap
    },

    async iFollowingStudent () {
      const idGalery = this.$route.params.album
      if (!idGalery || this.loadedFollowing === idGalery) return

      this.loadedFollowing = idGalery
      this.isLoadingFollower = true
      const id = this.$route.params.user
      const { body } = await this.followService.following({ id })
      this.isLoadingFollower = false
      this.following = body && body.length
    }
  },

  computed: {
    ...mapGetters({
      perfil: 'perfil/getPerson',
      user: 'registration/getUser'
    }),

    countLikes () {
      return Number(this.album.likes) + Number(this.currentLikes)
    },

    isCurrentUser () {
      return Number(this.user.id) === Number(this.$route.params.user) || Number(this.user.id) === Number(this.aluno?.id)
    },

    calcWidth () {
      return this.isCurrentUser ? 'max-width-230px' : 'max-width-120px'
    }
  },

  watch: {
    '$route.params.user': {
      deep: true,
      handler () {
        this.getConquests()
      }
    },

    '$route.params.album': {
      deep: true,
      handler () {
        if (this.isCdn) return

        this.iHaveLiked()
        this.iFollowingStudent()
      }
    },

    album: {
      deep: true,
      handler () {
        if (this.isCdn) return (this.setCdn())
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.header
  display: inline-flex
  flex-direction: row
  justify-content: space-between
  width: 100%
  border-bottom: 1px solid #444
  ::-webkit-scrollbar
    height: 0px
    background: #222 !important
    border-radius: 3px
  .max-width-120px
    max-width: 120px
  .max-width-230px
    max-width: 230px
  h6, p
    line-height: 15px

  .img-perfil
    object-fit: cover
    border-radius: 50%
    width: 50px !important
    height: 50px !important
    background-color: #000
    display: grid
    place-items: center
  .btn-transparent
    border-radius: 2px !important

  .conquests
    width: 25px
    height: 25px
    border-radius: 50%

  .elipse
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  .btn-fill
    border-radius: 3px !important
@media (max-width: 600px)
  .btn-fill
    width: 80px !important
  .btn-transparent
    width: 80px !important
</style>
