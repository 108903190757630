<template>
  <div class="height-100 d-flex w-100 flex-column content-field-file p-2 pointer">
    <div
      class="w-100 drag"
      @drop="changeFile"
      @click="selectFile"
      @dragover="handlingFile"
      @dragleave="handlingFile"
    >
      <input
        multiple
        required
        ref="file"
        type="file"
        id="new-post-file"
        @change="changeFile"
        style="display: none"
        accept="image/jpg, image/jpeg, image/png"
      >
      <div class="w-100 justify-content-around d-flex align-items-end">
        <label
          class="text-center pointer"
        >
          <p class="white font-14 text-center">
            Imagem
          </p>
          <PictureIcon />
          <p class="white font-13 text-center">
            (Imagem única)
          </p>
        </label>

        <label class="text-center pointer">
          <p class="white font-14 text-center">
            Carrossel
          </p>
          <PicturesIcon />
          <p class="white font-13 text-center">
            (Mais de uma imagem)
          </p>
        </label>
      </div>
      <p class="mt-2 dark-grey-2 font-12 text-center">
        Tamanho limite de 2Mb por imagem
      </p>
    </div>

    <div
      class="w-100 position-relative"
      v-if="links.length"
    >
      <span
        title="Anterior"
        class="arrow arrow-left pointer"
        @click="goToNext(-1)"
        v-if="links.length > 1"
      />

      <span
        title="Próximo"
        class="arrow arrow-right pointer"
        @click="goToNext(1)"
        v-if="links.length > 1"
      />

      <div class="images">
        <div
          v-for="(img, ind) in links"
          :key="`${ind}-new-post-file`"
          class="new-post-file"
          :id="`new_image_${ind}`"
        >
          <img
            v-if="img"
            :src="img"
            @click="selecionarImagemCapa(ind)"
            :class="['img-new-post pointer', selected === ind ? 'selected' : '']"
          >
        </div>
      </div>

      <p class="font-13 text-start mt-2 dark-grey-2">
        {{ labelCount }}
      </p>
    </div>
  </div>
</template>

<script>
import PictureIcon from '@/assets/icons/Picture'
import PicturesIcon from '@/assets/icons/Pictures'

const mb = 1000000
const limit = mb * 2

export default {
  components: {
    PictureIcon,
    PicturesIcon
  },

  props: {
    value: {}
  },

  data () {
    return {
      links: [],
      attachments: [],
      selected: null
    }
  },

  methods: {
    selectFile () {
      document.getElementById('new-post-file').click()
    },

    remove () {
      document.getElementById('new-post-file').value = ''
      this.links = []
      this.attachments = []
      this.$emit('update:capa', '')
      this.$emit('update:indCapa', null)
    },

    handlingFile (event) {
      event.preventDefault()
    },

    goToNext (val) {
      const nextVal = this.selected + val
      if (nextVal < 0 || nextVal === this.links.length) return

      this.selected = nextVal
      this.go(nextVal)
      this.selecionarImagemCapa(nextVal)
    },

    go (nextVal) {
      try {
        const to = `new_image_${nextVal}`
        document.getElementById(to).scrollIntoView({ behavior: 'smooth' }, true)
      } catch (error) {}
    },

    selecionarImagemCapa (ind) {
      this.selected = ind
      this.go(ind)
      this.$emit('update:capa', this.links[ind])
      this.$emit('update:indCapa', ind)
    },

    async changeFile (event) {
      event?.preventDefault()
      if (event?.dataTransfer) {
        this.$refs.file.files = event.dataTransfer.files
      }
      await new Promise(resolve => setTimeout(() => resolve(true), 200))

      let hasInvalidFile = false
      const files = Object.values(document.getElementById('new-post-file').files)
      if (!files.length) return

      this.links = []
      this.attachments = []
      for (const [ind, file] of files.entries()) {
        const enable = file.size < limit
        if (!enable) {
          if (!hasInvalidFile) this.$sweet.sweetMensagem('<h3 style="font-size: 20px">Atenção!</h3><h6 style="color:#646464; font-size: 15px">Imagens maiores do que <span style="color: #ff4163"><b>2MB</b></span><br> serão desconsideradas!</h6>')
          hasInvalidFile = true
          continue
        }
        this.attachments.push(file)
        const reader = new FileReader()
        reader.onloadend = () => this.links.push(reader.result)
        if (this.attachments.length) reader.readAsDataURL(this.attachments[ind])
      }
      setTimeout(() => this.selecionarImagemCapa(0), 200)
    }
  },

  computed: {
    labelCount () {
      const qtd = this.links.length
      if (qtd > 1) return `${qtd} Imagens selecionadas`
      return `${qtd} Imagem selecionada`
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        if (isEqual(value, this.attachments)) return
        this.attachments = value
        this.links = value
      }
    },

    attachments: {
      deep: true,
      handler (attachments) {
        if (isEqual(attachments, this.value)) return
        this.$emit('input', attachments)
      }
    }
  }
}

const isEqual = (val1, val2) => val1 === val2
</script>

<style lang="sass" scoped>
.arrow-left
  left: 5% !important
  transform: translateY(-50%) rotate(135deg)

.arrow-right
  right: 5% !important
  transform: translateY(-50%) rotate(-45deg)

.arrow
  top: calc(50% - 15px)
  z-index: 1
  width: 15px
  height: 15px
  position: absolute
  border: solid #ff4163
  opacity: 1 !important
  border-width: 0 3px 3px 0

.images
  max-width: 100%
  position: relative
  overflow: hidden
  overflow-x: scroll
  padding-right: 10px
  display: inline-flex
  .new-post-file
    padding-top: 10px
    padding-bottom: 20px
    background-color: #151515
    .img-new-post
      width: 225px
      height: 140px
      margin-left: 10px
      margin-right: 10px
      object-fit: contain

  &::-webkit-scrollbar
    height: 1px !important
    cursor: pointer

.selected
  transition: all .25s
  box-shadow: 0px 0px 3px 3px rgba(255, 65, 99, .4)
  border-radius: 5px
  transform: scale(1.02)

.content-field-file
  position: relative
  display: flex !important
  flex-direction: column !important
  flex-wrap: wrap
  align-content: space-between
  display: flex
  flex-direction: column
  justify-content: space-evenly
.drag
  flex: 1
  display: flex
  height: 300px
  width: 300px
  transition: all .25s
  flex-direction: column
  justify-content: center
  border: 1px solid transparent
  &:hover
    border: 1px dashed rgba(60,60,60,.4) !important

</style>
