<template>
  <section id="section-print">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :filename="`Certificado ${certificado ? certificado.curso || certificado.nome : ''}`"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
      @startPagination="startPagination()"
      @hasDownloaded="hasDownloaded()"
    >
      <div
        slot="pdf-content"
        class="pdf-content"
      >
        <img
          class="img-certificado"
          src="@/assets/images/certificado.png"
        >
        <h3 class="position-absolute text-center dark-color extra-Regular">
          {{ certificado.nome }}
        </h3>
        <h4 class="position-absolute text-center secondary extra-Regular">
          <span class="upper secondary me-3">
            {{ certificado.curso }}
          </span>
          <br>
          <span class="secondary me-3">
            NO DIA {{ $f.converterDatas(certificado.dataConclusao) }}
          </span>
          <span class="secondary me-3">
            CARGA HORÁRIA: {{ certificado.cargaHoraria }}h
          </span>
        </h4>
      </div>
    </vue-html2pdf>
  </section>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'ImpressaoComp',
  components: {
    VueHtml2pdf
  },

  props: {
    certificado: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    generatePdf () {
      this.$refs.html2Pdf.generatePdf()
    },

    startPagination () {
      this.$sweet.sweetWait('Aguarde enquanto estamos gerando seu certificado')
    },

    hasDownloaded () {
      this.$sweet.sweetClose()
    }
  }
}
</script>
<style lang="sass">
.img-certificado
  width: 100% !important
.pdf-content
  position: relative
  width: 100%
  .secondary
    color: #9c9d9f
  .dark-color
    color: #000
  .extra-Regular
    font-family: Myriad-Pro-Regular !important
    font-weight: 600
  h3
    top: 300px
    left: 52%
    font-size: 40px
    transform: translateX(-50%)
  h4
    bottom: 37%
    left: 50%
    font-weight: 600
    font-size: 25px
    transform: translateX(-50%)
  .position-absolute
    position: absolute
  .vue-html2pdf
    .layout-container
      background-color: #fff !important

    .content-wrapper
      background-color: #fff !important
</style>
