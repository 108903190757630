const base = 'plataforma-conteudo/feed'
const baseFeed = `${base}{/idFeed}`
const baseFeedComment = `${baseFeed}/comentario{/idFeedComment}`

export default class notificationService {
  constructor (resource) {
    const acoesCustomizadas = {
      listarGeral: { method: 'GET', url: `${base}/geral/listar{/qtd}/pulando{/skip}` },
      listarSeguindo: { method: 'GET', url: `${base}/seguindo/listar{/qtd}/pulando{/skip}` },

      adicionarFeed: { method: 'POST', url: base },
      removerFeed: { method: 'DELETE', url: baseFeed },
      arquivo: { method: 'POST', url: `${baseFeed}/arquivo` },
      curtirFeed: { method: 'POST', url: `${baseFeed}/curtida` },
      descurtirFeed: { method: 'DELETE', url: `${baseFeed}/curtida` },
      incrementarExibicaoFeed: { method: 'PUT', url: `${baseFeed}/incrementar-exibicao` },

      removerComentario: { method: 'DELETE', url: baseFeedComment },
      comentarFeed: { method: 'POST', url: `${baseFeed}/comentario` },
      curtirComentario: { method: 'POST', url: `${baseFeedComment}/curtida` },
      descurtirComentario: { method: 'DELETE', url: `${baseFeedComment}/curtida` },
      incrementarExibicaoComentario: { method: 'PUT', url: `${baseFeedComment}/incrementar-exibicao` }
    }
    this.resource = resource(base, {}, acoesCustomizadas)
  }

  adicionarFeed (dados) {
    return this.resource
      .adicionarFeed(dados)
      .then(res => res, (err) => {
        throw new Error(`Não foi possível criar o feed ${err}`)
      })
  }

  removerFeed (params) {
    return this.resource
      .removerFeed(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  removerComentario (params) {
    return this.resource
      .removerComentario(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  listarGeral (params) {
    return this.resource
      .listarGeral(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  listarSeguindo (params) {
    return this.resource
      .listarSeguindo(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  incrementarExibicaoFeed (params) {
    return this.resource
      .incrementarExibicaoFeed(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  curtirFeed (params) {
    return this.resource
      .curtirFeed(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  descurtirFeed (params) {
    return this.resource
      .descurtirFeed(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  arquivo (params) {
    return this.resource
      .arquivo(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  comentarFeed (params) {
    return this.resource
      .comentarFeed(params, { emulateJSON: true })
      .then(res => res, (err) => err)
  }

  incrementarExibicaoComentario (params) {
    return this.resource
      .incrementarExibicaoComentario(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  curtirComentario (params) {
    return this.resource
      .curtirComentario(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  descurtirComentario (params) {
    return this.resource
      .descurtirComentario(params, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }
}
