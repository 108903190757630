const SET_PARCEIROS = 'SET_PARCEIROS'

const state = {
  parceiros: [],
  isLoading: false
}

const actions = {
  setParceiros ({ commit }, parceiros) {
    commit(SET_PARCEIROS, { parceiros })
  },
  setIsLoading ({ commit }, isLoading) {
    commit(SET_PARCEIROS, { isLoading })
  }
}

const getters = {
  getParceiros: (state) => {
    return state.parceiros
  },
  isLoading: (state) => {
    return state.isLoading
  }
}

const mutations = {
  [SET_PARCEIROS] (state, parceiros) {
    Object.assign(state, parceiros)
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
