export default class softwareService {
  constructor (resource) {
    this.resource = resource('plataforma-conteudo/software', {}, {})
  }

  get (dados) {
    return this.resource
      .get(dados)
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  update (dados) {
    return this.resource
      .update(dados)
      .then(res => res, err => err)
  }
}
