<template>
  <svg
    width="512"
    height="416"
    viewBox="0 0 512 416"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M459.37 103.716C459.695 108.264 459.695 112.813 459.695 117.361C459.695 256.081 354.112 415.919 161.137 415.919C101.685 415.919 46.457 398.7 0 368.813C8.447 369.787 16.568 370.112 25.34 370.112C74.395 370.112 119.553 353.544 155.614 325.28C109.482 324.305 70.822 294.092 57.502 252.508C64 253.482 70.497 254.132 77.32 254.132C86.741 254.132 96.163 252.832 104.934 250.559C56.853 240.812 20.791 198.579 20.791 147.574V146.275C34.76 154.072 51.005 158.945 68.222 159.594C39.958 140.751 21.441 108.589 21.441 72.203C21.441 52.711 26.638 34.843 35.735 19.249C87.39 82.924 165.035 124.507 252.1 129.056C250.476 121.259 249.501 113.138 249.501 105.016C249.501 47.188 296.283 0.0820007 354.435 0.0820007C384.648 0.0820007 411.937 12.752 431.105 33.219C454.82 28.671 477.561 19.899 497.704 7.879C489.906 32.245 473.338 52.712 451.572 65.706C472.689 63.433 493.156 57.584 511.998 49.463C497.706 70.254 479.837 88.771 459.37 103.716V103.716Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'TwitterIcon'
}
</script>

<style lang="sass" scoped>
svg
  width: 15px
  height: 15px
</style>
