import Vue from 'vue'
import Vuex from 'vuex'

import feed from './modules/feed'
import lives from './modules/lives'
import cursos from './modules/cursos'
import foruns from './modules/foruns'
import galery from './modules/galery'
import perfil from './modules/perfil'
import configs from './modules/configs'
import novidades from './modules/novidades'
import campanhas from './modules/campanhas'
import arquivos from './modules/arquivos'
import parceiros from './modules/parceiros'
import followers from './modules/followers'
import softwares from './modules/softwares'
import registration from './modules/students'
import temporadas from './modules/temporadas'
import notificacoes from './modules/notificacoes'
import documentarios from './modules/documentarios'

Vue.use(Vuex)

const modules = {
  feed,
  lives,
  galery,
  cursos,
  foruns,
  perfil,
  configs,
  arquivos,
  followers,
  novidades,
  campanhas,
  parceiros,
  softwares,
  temporadas,
  registration,
  notificacoes,
  documentarios
}

export default new Vuex.Store({ modules })
