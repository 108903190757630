<template>
  <div class="column field-text-quill">
    <div class="col mb-0 font-14 label-quill" v-if="label">
      <label>{{ label }}</label>
    </div>

    <div class="col position-relative">
      <div class="open-modal" v-if="hasGalery" @click="$emit('clickGalery')" />
      <Editor
        v-if="!isPiii"
        v-model="text"
        :required="required"
        :disabled="isDisabled"
        :placeholder="placeholder"
        :class="text ? 'focused' : ''"
        :editor-toolbar="customToolbar"
      />
    </div>
  </div>
</template>

<script>
import { VueEditor as Editor } from "vue2-editor";

export default {
  components: {
    Editor,
  },

  props: {
    value: {},

    label: {
      type: String,
      default: "Poste uma resposta:",
    },

    hasGalery: {
      type: Boolean,
      default: false,
    },

    hasImage: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    required: {
      default: false,
    },

    keyPress: {
      default: false,
    },

    enableKey: {
      default: true,
    },

    placeholder: {
      default: "Compartilhe algo",
    },
  },

  data() {
    return {
      text: this.value,
      isPiii: false,
    };
  },

  computed: {
    customToolbar() {
      const customToolbar = [
        ["bold"],
        ["italic"],
        ["link"],
        ["blockquote"],
        [{ list: "ordered" }],
        [{ list: "bullet" }],
        [{ color: [] }],
      ];

      if (this.hasGalery || this.hasImage) customToolbar.unshift(["image"]);
      return customToolbar;
    },
  },

  watch: {
    value: {
      handler(value) {
        if (value === this.text) return;
        this.text = value;
      },
    },

    text: {
      handler(text) {
        if (text === this.value) return;

        this.$emit(
          "input",
          text
            .replace("<p><br></p>", "")
            .replace("<p>&lt;iframe", "<iframe")
            .replace("&gt;&lt;/iframe&gt;</p>", "></iframe>")
        );

        if (!this.keyPress || !text) return;
        if (text.includes("<p><br></p>") && this.enableKey)
          this.$emit("keyPressed");
      },
    },
  },
};
</script>

<style lang="sass">
@media (max-width: 500px)
  .label-quill
    opacity: .9
    font-size: 13px !important
    line-height: 16px !important
.field-text-quill
  .ql-formats
    position: relative
    z-index: 8
    .ql-picker-options
      z-index: 8
  .label-quill
    color: #ddd
  .ql-editor.ql-blank::before
    font-style: normal !important
    cursor: text
    color: #aaa
    font-size: 14px

  .focused
    .ql-editor
      min-height: 120px !important

  .ql-container
    border: none !important
    background-color: #fff
    border-radius: 3px
    margin-bottom: 10px !important

  .quillWrapper .ql-snow .ql-stroke
    stroke: #646464 !important
  .ql-toolbar
    border: none !important
    padding-left: 0px !important
    margin-left: -8px !important
  .ql-editor
    min-height: 60px !important
    transition: all .25s
    &:focus
      box-shadow: -0px 4px 4px -2px rgba(255, 65, 99, .2)
      border: 1px solid #ff4163 !important
      outline: none !important
      border-radius: 3px !important
      min-height: 120px !important

    blockquote
      color: #656565
      font-size: 14px !important
    strong
      font-weight: bold
      font-size: 14px
      color: #000
    .ql-syntax
      font-size: 12px !important

    p, span, div, li, ul, a, h1, h2, h3, h4, h5, h6, pre
      color: #646464
      font-size: 14px !important

  .open-modal
    top: 0px
    left: -10px
    z-index: 10
    width: 30px
    height: 40px
    cursor: pointer
    position: absolute
</style>
