<template>
  <div
    class="about-me"
    v-if="descricao"
  >
    <p class="mb-3 font-14">
      Sobre mim:
    </p>
    <p class="font-14">
      {{ descricao }}
    </p>
  </div>
</template>

<script>

export default {
  props: {
    descricao: {
      default: '',
      type: String
    }
  }
}
</script>
