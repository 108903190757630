<template>
  <section class="position-relative">
    <ModalConcluido v-if="cursoConcluido" />

    <div class="position-absolute info">
      <div class="row position-relative">
        <div class="col">
          <div class="column">
            <div class="col mb-1">
              <h1 class="upper bold">
                {{ curso.nome }}
              </h1>
            </div>

            <div class="col mb-1">
              <div class="row align-items-center">
                <div class="col-auto pe-0">
                  <div class="img-coord" v-if="curso.extensaoFotoCoordenador">
                    <img
                      :src="
                        mountImg(
                          curso,
                          curso.extensaoFotoCoordenador,
                          'extensao_foto_coordenador'
                        )
                      "
                    />
                  </div>
                </div>

                <div class="col">
                  <span class="font-16 infos extra-light me-1 me-md-4">{{
                    curso.nomeCoordenador
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-3 col-sm-12 col-lg-3">
          <div class="position-absolute circulo-box">
            <VueCircle :percentual="percentual" />
          </div>
        </div>
      </div>
    </div>

    <div class="width-100">
      <div class="linear-gradient" />

      <img
        class="w-100"
        :src="mountImg(curso, curso.extensaoBanner, 'horizontal')"
      />
    </div>
  </section>
</template>

<script>
import VueCircle from "./ProgressCircle";
import UserService from "@/service/userService";
import ModalConcluido from "@/components/shared/modal/ModalConcluido";

export default {
  components: {
    VueCircle,
    ModalConcluido,
  },

  data() {
    return {
      cursoConcluido: false,

      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },

  methods: {
    mountImg(curso, ext, exp) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=curso&identificador=${curso.id}&extensao=${ext}&expressao=${exp}`;
    },
  },

  computed: {
    percentual() {
      const vidoes = this.$store.getters["temporadas/getAllVideos"];
      const watched = vidoes.filter(({ visto }) => visto).length;
      const allVideos = vidoes.length;

      let percentual = Math.round((watched * 100) / allVideos);
      if (percentual > 100) percentual = 100;
      return isNaN(percentual) ? 0 : percentual;
    },

    horas() {
      return this.$store.getters["registration/getHours"];
    },

    curso() {
      return this.$store.getters["cursos/getCurso"];
    },

    pendente() {
      const temporadas = this.$store.getters["temporadas/getTemporadas"];
      return temporadas.some((i) => !i.dataConclusao);
    },
  },

  watch: {
    percentual() {
      const isFinished = this.curso && this.pendente && this.percentual === 100;
      if (!isFinished) return;

      this.UserService = new UserService(this.$resource);
      const idVinculo = this.$store.getters["temporadas/getIdVinculo"];
      this.UserService.finalizarCurso({ id: idVinculo })
        .then(() => (this.cursoConcluido = true))
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style lang="sass" scoped>
.percent
  position: absolute
  width: 55px
  height: 55px
  margin: auto
  border-radius: 50%
  z-index: 99

.bar1
  border: 3px solid #ff4163
  border-radius: 50%
  width: 55px
  height: 55px
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  position: absolute

.linear-gradient
  bottom: 0px
  content: ''
  position: absolute
  z-index: 9 !important
  width: 100%
  height: 100%
  background-image: linear-gradient(transparent, transparent, transparent, black)

.image-banner
  width: 100% !important
  div
    height: 180px
    width: 100%
    background-size: cover
    background-repeat: no-repeat
    background-position: top center

.info
  bottom: 20px
  width: 100%
  padding: 0px 15px
  .font-25
    font-size: 25px

  .font-16
    font-size: 16px

  h1
    font-size: 20px

.arrow-right
  right: 150px !important
  top: 50%

.arrow-left
  z-index: 1
  left: 150px !important

.image-banner
  width: 100%
  height: 180px

@media (max-width: 500px)
  .circulo-box
    bottom: -10px !important
    transform: scale(.75)

  .min
    min-height: auto

  .btn-trailer
    font-size: 14px

  .slick-dots
    .custom-dot
      bottom: 15px

  svg
    transform: scale(0.8) !important

  .font-24
    font-size: 16px !important

  .font-25
    font-size: 17px !important

  .descricao
    font-size: 14px
    max-width: 300px !important

  .image-banner
    height: auto
    .linear-gradient
      bottom: 25px
      position: absolute
      z-index: 12 !important
      width: 100%
      height: 50px
      background-image: linear-gradient(transparent, black)

  .arrow-left
    left: 10px !important

  .arrow-right
    right: 10px !important

  .slick-dots
    .custom-dot:before
      width: 6px
      height: 6px

@media (max-width: 960px) and (min-width: 720px)
  .circulo-box
    left: calc(50% - 22.5px) !important
    top: -100% !important
    bottom: auto !important
    right: auto !important

.circulo-box
  z-index: 9
  bottom: -5px
  right: 15px
  .circulo
    width: 55px
    height: 55px
    transform: rotate(180deg)

  .percent
    position: absolute
    content: ''
    top: 50%
    left: 50% !important
    transform: translateX(-50%) translateY(-60%)

.img-coord
  background-color: #fff
  border-radius: 100%
  width: 30px !important
  height: 30px !important
  overflow: hidden

  img
    border-radius: 100%
    width: 30px !important
    height: 30px !important

.width-100
  width: 100%

.opacity-none
  max-width: 100%
  opacity: none !important
</style>
