<template>
  <section
    :class="['menu-container min max pb-3', menuIsOpen ? 'max-width' : '']"
  >
    <ToggleMenu
      @click.native="toggleMenu()"
      v-if="width > 700 || notificacoesIsOpen"
      :notificacoes-is-open="notificacoesIsOpen"
    />

    <Notificacoes :is-open="notificacoesIsOpen" @closeCollapse="toggleMenu()" />

    <div :class="['scroll', blockScroll ? 'no-scroll' : '']">
      <div class="menu-content">
        <Header />

        <div
          v-for="item in orderedMenu"
          :key="item.name"
          :class="[isMobile ? 'mb-3' : '']"
        >
          <div @click="openCollapse(item)" v-if="item.linkTo">
            <router-link
              :to="{
                name: item.isBloqued ? 'home' : item.linkTo,
                params: item.params,
              }"
            >
              <MenuItem
                :notificacoes-is-open="notificacoesIsOpen"
                :item="item"
              />
            </router-link>
          </div>

          <div v-else>
            <div @click="openCollapse(item)">
              <MenuItem
                :notificacoes-is-open="notificacoesIsOpen"
                :item="item"
              />
            </div>
          </div>

          <div
            v-if="item.collapse && !item.right"
            :class="[
              'ps-4 pb-2',
              isOpenCollapse ? 'open-menu-collapse' : 'close-menu-collapse',
            ]"
          >
            <div
              v-for="(subitem, id) in item.collapses"
              :key="`subitem-${id}`"
              @click="openCollapse(subitem)"
            >
              <router-link
                :to="{ name: subitem.isBloqued ? 'home' : subitem.linkTo }"
              >
                <MenuItem
                  :notificacoes-is-open="notificacoesIsOpen"
                  :item="subitem"
                />
              </router-link>
            </div>
          </div>
        </div>

        <p
          @click="logOut()"
          :class="[
            'dark-grey-2 font-12 mt-4 hovered pointer mb-2',
            menuIsOpen ? 'desconectar' : 'desconectar-closed',
          ]"
        >
          Sair
        </p>

        <span class="font-10 dark-grey-2"> v. {{ $version }} </span>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/shared/menu/Header";
import ToggleMenu from "@/components/shared/menu/ToggleMenu";
import MenuItem from "@/components/shared/menu/MenuItem";
import Notificacoes from "@/components/shared/menu/Notificacoes";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MenuComp",
  components: {
    Header,
    MenuItem,
    ToggleMenu,
    Notificacoes,
  },

  data() {
    return {
      blockScroll: false,
      notificacoesIsOpen: false,
      isOpenCollapse: false,
      menu: [
        {
          class: "home",
          linkTo: "home",
          name: "Home",
          src: "house.png",
          isBloqued: false,
        },
        {
          class: "cafe",
          linkTo: "cafe",
          name: "Café",
          src: "cafe.png",
          isBloqued: false,
        },
        {
          class: "arquivo",
          linkTo: "arquivos",
          name: "Arquivos",
          src: "nuvem.png",
          isBloqued: false,
        },
        {
          class: "notificacoes",
          name: "Notificações",
          src: "bell.png",
          isBloqued: false,
          collapse: true,
          right: true,
        },
        {
          class: "perfil",
          linkTo: "perfil",
          name: "Perfil",
          src: "login.png",
          isBloqued: false,
          links: ["dados", "perfil"],
        },
        {
          class: "certificados",
          linkTo: "certificados",
          name: "Certificados",
          src: "checked.png",
          isBloqued: false,
        },
        {
          class: "senha",
          linkTo: "senha",
          name: "Senha",
          src: "padlock.png",
          isBloqued: false,
        },
        {
          class: "conquistas",
          linkTo: "conquistas",
          name: "Conquistas",
          src: "premium.png",
          isBloqued: false,
        },
        {
          class: "galeria",
          linkTo: "galeria",
          name: "Galeria",
          src: "galery.png",
          isBloqued: false,
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      logOutStore: "registration/logOut",
    }),

    openCollapse(item) {
      if (item.collapses) this.isOpenCollapse = !this.isOpenCollapse;

      if (item.right) {
        if (!this.notificacoesIsOpen) this.toggleMenu(false);
        setTimeout(() => {
          this.notificacoesIsOpen = !this.notificacoesIsOpen;
        }, 50);
        return;
      }

      this.notificacoesIsOpen = false;
    },

    toggleMenu(state) {
      if (this.notificacoesIsOpen)
        return (this.notificacoesIsOpen = !this.notificacoesIsOpen);
      if (this.menuIsOpen === state) return;

      this.blockScroll = true;
      const empty = [undefined, null];
      const toggleTo = empty.includes(state) ? !this.menuIsOpen : state;
      this.$store.dispatch("configs/setMenuState", toggleTo);
      setTimeout(() => {
        this.blockScroll = false;
      }, 250);
    },

    logOut() {
      localStorage.setItem("tokenPlataformaEducando", false);
      this.logOutStore();
      this.$router.push({ name: "login" });
      location.reload();
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      isMobile: "configs/isMobile",
      menuIsOpen: "configs/menuIsOpen",
      notificacoes: "notificacoes/getNotificacoes",
      width: "configs/getWidth",
    }),

    orderedMenu() {
      return copy(this.menu)
        .sort((x, y) => x.isBloqued < y.isBloqued)
        .map((item) => {
          if (item.class === "notificacoes") item.notify = this.hasNotification;
          if (item.linkTo === "perfil") {
            item.isBloqued = !this.user?.id;
            item.links = this.isCurrentPerfil;
            item.params = { user: this.user?.id };
          }
          return item;
        });
    },

    hasNotification() {
      return this.notificacoes.some((item) => !item.readed);
    },

    isCurrentPerfil() {
      const name = this.$route.name;

      const links = ["perfil", "dados"];
      if (name === "dados") return links;

      const isPerfilRoute = name === "perfil" || name === "dados";
      const isCurrentId = this.user.id === Number(this.$route.params.user);
      return isPerfilRoute && isCurrentId ? links : [];
    },
  },
};

const copy = (item) => JSON.parse(JSON.stringify(item));
</script>

<style lang="sass">
.cafe
  width: 25px !important
</style>

<style lang="sass" scoped>
@media (max-width: 500px)
  .min
    min-height: 100vh
    height: 100%
.max-width
  max-width: 400px !important
  min-width: 250px !important
  width: 100px
.menu-container
  border-right: 1px solid rgba(50, 50, 50, .3)
  width: 80px
  text-align: center
  transition: all .5s
  position: sticky
  top: 0px
  z-index: 9
  background: #000
  max-width: 80px
  min-width: 0px
  box-shadow: 1px 1px 25px 10px rgba(0,0,0, 0.6)
  .menu-content
    position: sticky
    top: 0px

%desconnect
  margin: auto
  text-transform: uppercase
  transition: all .75s
  border: 1px solid #444 !important
  border-radius: 15px
  max-width: 100px
  padding: 3px 10px
  &:hover
    border-color: #fff !important
    background-color: #fff
    color: #000 !important
    opacity: .8

.desconectar
  @extend %desconnect

.desconectar-closed
  @extend %desconnect
  margin: 0px 5px
  font-size: 11px !important
  text-transform: capitalize
  padding: 1px 3px
  border-radius: 8px !important

::-webkit-scrollbar
  width: 0px !important

.no-scroll
  overflow: hidden !important

.open-menu-collapse
  animation: collapse .5s

.close-menu-collapse
  transition: all .75s
  animation: close-collapse .5s
  opacity: 0
  position: absolute
  visibility: hidden

@keyframes close-collapse
  0%
    position: relative
    margin-top: 0px
    visibility: visible
    opacity: 1
  60%
    opacity: 0
  99%
    position: relative
    visibility: visible
  100%
    visibility: hidden
    display: none
    margin-top: -130%

@keyframes collapse
  0%
    opacity: 0
    margin-top: -100%
  100%
    opacity: 1
</style>
