<template>
  <div class="mb-5">
    <ModalGalery is-cdn />

    <div class="row justify-content-center">
      <NewAlbum has-callback :show-button="false" :callback="criarFeed" />

      <div class="col-sm-12 col-md-10 px-sm-0 px-3 px-lg-3">
        <!-- Nova mensagem -->
        <div class="my-4 mb-2">
          <form class="d-flex flex-column" @submit.prevent="criarFeed()">
            <div class="col">
              <FieldTextQuill
                has-galery
                @clickGalery="clickGalery()"
                v-model="message"
                label="Compartilhe alguma dúvida, experiência, projeto, etc... Aqui o microfone é seu!"
              />
            </div>

            <div class="col justify-content-end text-end">
              <button
                :disabled="!message"
                class="btn bg-btn-pink font-14 mb-1"
                style="padding: 0.1rem 2rem !important"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>

        <!-- Filtro de Feed -->
        <div class="row justify-content-center">
          <div class="column mt-3 mb-2">
            <h6 class="mb-1 font-14" style="color: #646464">Filtrar por:</h6>

            <div class="row justify-content-start">
              <div class="col">
                <div class="d-inline-flex buttons">
                  <div class="col-auto mb-2 mb-lg-0">
                    <button
                      class="upper"
                      @click="filterBy('listarGeral')"
                      :class="[
                        orderBy === 'listarGeral'
                          ? 'px-5 py-1 btn-fill bg-btn2'
                          : 'px-5 py-1 bg-btn2 btn-transparent',
                      ]"
                    >
                      Geral
                    </button>
                  </div>

                  <div class="col">
                    <button
                      @click="filterBy('listarSeguindo')"
                      class="upper"
                      :class="[
                        orderBy === 'listarSeguindo'
                          ? 'px-4 py-1 btn-fill bg-btn2'
                          : 'px-4 py-1 bg-btn2 btn-transparent',
                      ]"
                    >
                      Seguindo
                    </button>
                  </div>
                </div>
              </div>

              <div class="procurar col-12 col-lg-3" v-if="showFilter">
                <InputLinks
                  v-model="search"
                  icon="search"
                  placeholder="Procure por alguma #TAG"
                  personal-styles="width: 10px; margin: 0 !important; fill: #fff !important"
                  color="white"
                  :has-padding="false"
                  :has-bars="false"
                  class="px-4"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Mensagens -->
        <div
          class="enter-effect"
          :key="`content-${ind}`"
          v-for="(item, ind) in content"
        >
          <CardMessage
            :item="item"
            :ref="`card-message-${item.id}`"
            :inserir-mensagem="inserirMensagem"
            :conquistas-educandos="conquistasEducandos"
          />

          <PropagandaFree
            :banner="(ind % 5) + 1"
            class="my-4"
            v-if="!user.isPro && ind % 4 === 0"
          />
        </div>

        <div v-if="!content.length && !loading">
          <p class="text-center font-14 dark-grey-2 mt-5">
            Nenhuma postagem localizada!
          </p>
        </div>

        <!-- Load More -->
        <div
          v-if="!loading && shouldLoadMores"
          class="row justify-content-center mt-4"
        >
          <div
            class="col-auto box-plus"
            alt="Carregar mais"
            title="Carregar mais"
            @click="buscarMensagens(jump)"
          >
            <p class="col-auto pink">+</p>
          </div>
        </div>
      </div>
    </div>

    <LoadingComp v-if="loading" class="py-5" />
  </div>
</template>

<script>
import Vue from "vue";
import Mixins from "@/Mixins";
import { v4 as uuidv4 } from "uuid";
import CardMessage from "./CardMessage";
import { mapGetters, mapActions } from "vuex";
import { months } from "@/assets/js/functions";
import FeedService from "@/service/feedService";
import NewAlbum from "@/components/modules/perfil/NewAlbum";
import FieldTextQuill from "@/components/shared/forms/FieldTextQuill";
import ModalGalery from "@/components/shared/galeria/modal/ModalGalery";

import InputLinks from "@/components/shared/forms/InputLinks";

export default {
  name: "MessageComp",

  mixins: [Mixins],

  components: {
    NewAlbum,
    InputLinks,
    CardMessage,
    ModalGalery,
    FieldTextQuill,
  },

  props: {
    comments: {
      type: Array,
      default: () => [],
    },

    showFilter: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.FeedService = new FeedService(this.$resource);
    this.buscarMensagens();
  },

  data() {
    return {
      content: [],
      orderBy: "listarGeral",
      message: "",
      respostas: 0,
      mensagens: [],
      loading: false,
      shouldLoadMores: true,

      search: "",
      conquistasEducandos: [],

      qtd: 15,
      jump: 0,
    };
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      wins: "registration/getWins",
      isMobile: "configs/isMobile",
    }),
  },

  methods: {
    ...mapActions({ setAlbumStore: "galery/setAlbum" }),

    clickGalery() {
      document.getElementById("btn-modal-new-post")?.click();
    },

    async inserirMensagem(item, newMessage) {
      item.messages.unshift(newMessage);
      document.getElementById(`btn-message-${item.id}`)?.click();
    },

    async filterBy(value) {
      if (this.orderBy !== value) this.jump = 0;
      this.orderBy = value;
      this.buscarMensagens();
    },

    async buscarMensagens(jump) {
      const shoudRequest = !this.loading || !this.mensagens.length;
      if (!shoudRequest) return;

      this.loading = true;

      if (!jump) {
        this.content = [];
        this.jump = 0;
        this.shouldLoadMores = true;
      }

      const params = {
        qtd: this.qtd,
        skip: this.jump,
      };
      const { body } = await this.FeedService[this.orderBy](params);
      this.jump = this.jump + this.qtd;
      this.loading = false;

      const notMore = !body.length || body.length < this.qtd;
      if (notMore) this.shouldLoadMores = false;
      this.content = [
        ...this.content,
        ...body.map((item) => this.mountMessage(item)).filter((i) => i),
      ];
    },

    mountMessage(item, isAnswer) {
      const idAluno = item.idEducando;
      const idPost = item.idPost || item.id;
      const idGaleria = item?.anexos?.find((i) => !!i.idGaleria)?.idGaleria;
      const mountParmas = ({ id, extensao }) => ({
        id,
        idPost,
        idAluno,
        extensao,
        idGaleria,
      });

      const msg = {
        idAluno,
        isAnswer,
        idGaleria,
        id: idPost,
        likes: item.curtidas,
        views: item.exibicoes,
        descricao: (item.mensagem || item.comentario).replaceAll("style=", ""),
        softwares: item.softwares,
        ocupacao: item.ocupacaoEducado,
        isPro: item.assinatura === "P",
        aluno: item.nomeEducando || item.educando,
        following: item.jaSigoEsseEducando !== "N",
        descricao: item.mensagem || item.comentario,
        idComentario: isAnswer ? item.id : "",
        imagens: item?.anexos?.map((i) => ({
          ...i,
          img: this.$mountPathSpace(mountParmas(i)),
        })),
        conquistasEducando: item.conquistasEducando?.map((item) => ({
          titulo: item.titulo,
          src: this.mountImagePath("conquista", item.id, item.extensaoImagem),
        })),
        isLiked:
          item?.euCurtiEssaPostagem === "S" ||
          item?.euCurtiEsseComentario === "S",
        img: this.mountImagePath(
          "educando",
          item.idEducando,
          (item.extensaoImagemEducando || item.extensaoFotoEducando)?.replace(
            "-",
            ""
          )
        ),
        messages: Array.isArray(item.comentarios)
          ? item.comentarios
              .map((msg) =>
                this.mountMessage({ ...msg, idPost: item.idPost }, true)
              )
              .reverse()
          : [],
        data: `${Number(this.$f.day(item.createdAt))} ${this.$f.month(
          item.createdAt
        )} ${this.$f.year(item.createdAt)}`,
      };

      return msg;
    },

    newMessage(id, msg, isAnswer, idComentario) {
      const now = new Date();
      const conquistas = this.wins.map((win) => ({
        src: win.image,
        titulo: win.titulo,
      }));

      return {
        id,
        isAnswer,
        likes: 0,
        views: "0",
        imagens: [],
        messages: [],
        descricao: msg,
        isLiked: false,
        idAluno: this.user.id,
        isLoadingImagens: false,
        ocupacao: this.user.ocupacao,
        conquistasEducando: conquistas,
        idComentario: idComentario || "",
        hasImage: Boolean(this.user.extensaoImagem),
        aluno: `${this.user.nome} ${this.user.sobrenome}`,
        img: this.mountImagePath(
          "educando",
          this.user.id,
          this.user.extensaoImagem
        ),
        data: `${Number(now.getDate())} ${
          months[now.getMonth()]
        } ${now.getFullYear()}`,
      };
    },

    async criarFeed(newAlbum) {
      const auxMessage = this.message;
      this.message = "";

      const aux = { ...newAlbum };
      const message = aux?.descricao || auxMessage;
      const params = {
        mensagem: this.validateHtml(message),
        idsSoftwares: [...(aux?.softwares || [])],
        vinculoGaleria: aux?.myGalery ? "S" : "N",
      };

      const uuid = uuidv4();
      const newMessage = this.newMessage(
        uuid,
        this.validateHtml(message),
        false
      );

      const img = newMessage.img;
      newMessage.img = "";
      newMessage.hasImage = false;
      newMessage.isLoadingImagens = aux?.images?.length;

      this.content.unshift(newMessage);
      this.$sweet.sweetWait("Aguarde um instante.");
      setTimeout(() => this.$sweet.sweetClose(), 10);
      document.getElementById("close-modal-new-album").click();

      const { body } = await this.FeedService.adicionarFeed(params);
      newMessage.img = img;
      newMessage.hasImage = true;
      newMessage.id = Number(body);

      if (!aux?.images?.length) return;

      const resp = await this.saveImage(aux.images, body);
      const images = resp.map(({ body }) => ({
        id: body.id,
        idPost: newMessage.id,
        img: `${this.$space}/postagens/${this.user.id}/${newMessage.id}/${body.id}.${body.extensao}`,
        extensao: body.extensao,
      }));
      newMessage.imagens = images;
      newMessage.isLoadingImagens = false;
    },

    async saveImage(images, id) {
      const setImage = async (image) => {
        const headers = { "Content-Type": "multipart/form-data" };
        const dataForm = new FormData();
        dataForm.append("arquivo", image);
        return this.$http
          .post(
            `${Vue.http.options.root}/plataforma-conteudo/feed/${id}/arquivo`,
            dataForm,
            { headers }
          )
          .catch((err) => err);
      };

      return Promise.all(images.map((img) => setImage(img))).then((res) => res);
    },
  },
};
</script>

<style lang="sass" scoped>
.box-plus
  background-color: #000
  border-radius: 50%
  width: 34px
  height: 34px
  display: inline-flex
  flex-direction: row
  justify-content: center
  align-items: center
  font-size: 22px !important
  cursor: pointer

.border-t
  border-top: 1px solid rgba(256,256,256, .25) !important
  padding: 10px 0px 15px 0px
.border-b
  border-bottom: 1px solid rgba(256,256,256, .25) !important

.center
  align-items: center
.moderador-img
  width: 30px
  height: 30px
  border-radius: 50%
.img-perfil
  border-radius: 50%
  width: 40px
  height: 40px
@media (max-width: 1128px) and (min-width: 768px)
  .buttons
    margin-top: 0px !important
@media (max-width: 600px)
  .btn-fill, .bg-btn2, .bg-btn-pink
    padding-top: 4px !important
    padding-bottom: 1px !important
    padding-inline: 25px !important
    font-size: 11px !important
  .bg-black
    background: #000 !important
  .btn-answer
    height: 17px
    font-size: 10px !important
    margin-top: 10px
  .buttons
    gap: auto
    width: 100%
    justify-content: space-between
.bg-btn2
  color: white
  border-radius: 5px
  line-height: normal
  align-items: center
  display: inline-flex
  justify-content: center
  font-size: 11px !important
.buttons
  gap: 10px
</style>
