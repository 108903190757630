const SET_WIDTH = 'SET_WIDTH'
const SET_MENU_STATE = 'SET_MENU_STATE'

const state = {
  width: 0,
  menuIsOpen: false
}

const getters = {
  getWidth: (state) => state.width,
  isMobile: (state) => state.width < 768,
  isLittleMobile: (state) => state.width < 400,
  isTablet: (state) => state.width >= 768 && state.width < 1024,
  menuIsOpen: (state) => state.menuIsOpen
}

const actions = {
  setWidth ({ commit }, args) {
    commit(SET_WIDTH, args)
  },

  setMenuState ({ commit }, args) {
    commit(SET_MENU_STATE, args)
  }
}

const mutations = {
  [SET_WIDTH] (state, width) {
    Object.assign(state, { width })
  },
  [SET_MENU_STATE] (state, isOpen) {
    state.menuIsOpen = isOpen
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
