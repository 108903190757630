<template>
  <section>
    <LoadingComp v-if="isLoadingHours" />

    <div v-else-if="!isOpen">
      <p class="dark-grey-2 font-20">{{ hours }}hs</p>
      <p class="assistidas dark-grey-2 font-12">Assistidas</p>
    </div>

    <div
      :class="['box-journey d-inline-flex p-2 position-relative', boxJourney]"
    >
      <div class="hours col">
        <h1>{{ hours }}hs</h1>
        <p class="secondary font-12 text-start ms-4">de conteúdo assistido</p>

        <p
          class="start-journey secondary position-absolute font-12 dark-grey-2"
        >
          Sua jornada conosco começou em {{ mountDate(user.createdAt) }}
        </p>
      </div>

      <div class="clock col-auto">
        <img src="@/assets/images/clock.png" />
      </div>
    </div>
  </section>
</template>
<script>
import UserService from "@/service/userService";
import { mapGetters } from "vuex";

export default {
  name: "HoursComp",

  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },

    isMenu: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      isLoadingHours: Boolean(this.hours),
    };
  },

  async mounted() {
    if (this.hours) return;
    this.UserService = new UserService(this.$resource);
    const {
      body: { tempoHoras },
    } = await this.UserService.hours();
    this.$store.dispatch("registration/setHours", tempoHoras);
    this.isLoadingHours = false;
  },

  methods: {
    mountDate(date) {
      if (!date) return "";

      const [dateTime] = date.split("T");
      const [year, month, day] = dateTime.split("-");

      return `${day}/${month}/${year}`;
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      hours: "registration/getHours",
    }),

    boxJourney() {
      const opacity = this.isOpen ? "opacity-100" : "opacity-0";
      const width = !this.isMenu
        ? " w-100 mx-0 border-not-menu"
        : "border-menu";
      return `${opacity} ${width}`;
    },
  },
};
</script>

<style lang="sass" scoped>
.opacity-0
  transition: all .25s
  width: 0px
  display: none
  height: 0px
  animation: close-menu 300ms
.opacity-100
  transition: all .25s
  display: block
  animation: open-menu 300ms
.border-not-menu
  border: 1px solid #3b3e42
.border-menu
  border: 1px solid #212529
@keyframes open-menu
  0%
    width: 0%
    display: none
  60%
    display: block
  100%
    width: 100%
    position: block !important
@keyframes close-menu
  0%
    width: 100%
    position: block !important
  60%
    display: block
  100%
    width: 0px
    display: none

.box-journey
  opacity: 1
  border-radius: 15px
  margin: 0px 10px
  padding-top: 10px !important
  padding-bottom: 20px !important
  .clock
    padding-right: 10px
    img
      width: 65px
      filter: contrast(0.5)

  .hours
    h1
      text-align: left
      font-size: 30px
    p
      text-align: end
      margin-top: -8px
      font-size: 15px
      max-width: 220px

  .start-journey
    bottom: 10px
    right: 15px
    line-height: 14px
    p
      color: #646464
      font-size: 12px
@media (max-width: 500px)
  .box-journey
    min-width: auto !important
    max-width: 100% !important
  .hours
    h1
      font-size: 35px !important
  .clock
    img
      width: 75px !important
</style>
