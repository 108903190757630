const SET_CAMPANHAS = 'SET_CAMPANHAS'

const state = {
  campanhas: []
}

const actions = {
  setCampanhas ({ commit }, campanhas) {
    commit(SET_CAMPANHAS, campanhas)
  }
}

const getters = {
  getCampanhas: (state) => {
    return state.campanhas.filter(({ origin }) => origin === 2)
  },
  getCampanhas3: (state) => {
    return state.campanhas.filter(({ origin }) => origin === 3)
  }
}

const mutations = {
  [SET_CAMPANHAS] (state, campanhas) {
    state.campanhas = [...campanhas, ...state.campanhas]
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
