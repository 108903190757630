const SET_NOTIFICATION = 'SET_NOTIFICATION'
const SET_LOADING = 'SET_LOADING'

const state = {
  notificacoes: [],
  isLoading: false
}

const actions = {
  setNotificacoes ({ commit }, notificacoes) {
    commit(SET_NOTIFICATION, notificacoes)
  },

  setIsLoading ({ commit }, isLoading) {
    commit(SET_LOADING, isLoading)
  }
}

const getters = {
  getNotificacoes: (state) => {
    return state.notificacoes
  },

  isLoading: (state) => {
    return state.isLoading
  }
}

const mutations = {
  [SET_NOTIFICATION] (state, notificacoes) {
    state.notificacoes = notificacoes
  },

  [SET_LOADING] (state, isLoading) {
    state.isLoading = isLoading
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
