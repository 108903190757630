<template>
  <div>
    <div v-if="isLoadingCurso || isLoadingDoc" class="center-loading">
      <LoadingComp class="pt-5 mt-5" />
    </div>

    <div v-else class="enter-effect-opacity">
      <Campaings v-if="!isMobile && user.id" />

      <MessagesComp class="pb-0 pb-sm-5" :show-filter="false" />

      <div class="px-0 px-md-4 pt-4">
        <slot name="parceiros" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Campaings from "./Campaings";
import MessagesComp from "./Messages";
import UserService from "@/service/userService";

export default {
  name: "HomeComp",

  components: {
    Campaings,
    MessagesComp,
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      isMobile: "configs/isMobile",
      menuIsOpen: "configs/menuIsOpen",
      isLoadingCurso: "cursos/isLoading",
      isLoadingDoc: "documentarios/isLoading",
      isValidatedWins: "registration/getValidatedWins",
    }),
  },

  mounted() {
    if (this.isValidatedWins || !this.user?.id) return;

    this.load();
  },

  data() {
    return {
      comments: [],
      search: "",
      message: "",
      orderBy: "geral",
      isLoaded: false,
      loadingGalery: false,
    };
  },
  watch: {
    user: {
      handler(user) {
        if (!user?.id) return;

        this.load();
      },
    },
  },

  methods: {
    load() {
      this.UserService = new UserService(this.$resource);
      this.buscarConquistas();
      this.$router.push({ name: "cafe" }).catch((e) => e);
    },

    async buscarConquistas() {
      const { body: conquistasEducando } = await this.UserService.conquistas();
      const mapConquistas = conquistasEducando?.map((conq) => ({
        ...conq,
        image: this.mountImg(conq.id, conq.extensaoImagem),
      }));
      this.$store.dispatch("registration/setValidateWins");
      this.$store.dispatch("registration/setWins", {
        wins: mapConquistas,
        resource: this.$resource,
      });
    },

    mountImg(id, ext) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=conquista&identificador=${id}&extensao=${ext}`;
    },
  },
};
</script>
<style lang="sass" scoped>
.bg
  background-color: rgba(21,21,21,1)
.scroll
  max-height: 100vh
  overflow: scroll
  overflow-x: hidden
.scroll::-webkit-scrollbar
  width: 3px
  background: #222 !important
  border-radius: 3px
.scroll::-webkit-scrollbar-track
  border-radius: 3px
.scroll::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff4163
  max-height: 50px
.max-height-100vh
  max-height: 100vh
.center-loading
  padding-top: 35vh
</style>

<style lang="sass">
@media (max-width: 500px)
  .pm-none
    margin: 0px !important
    padding: 12px 6px 12px 5px !important
.procurar
  & > div
    border-radius: 5px
    padding-left: .5rem!important
    padding-right: 1rem!important
    background-color: hsl(0 0% 15% / 1)
  input
    color: #fff
    font-size: 11px
    margin: 0 !important
    border: none !important
    padding-left: 20px !important
    &::placeholder
      color: #fff
      font-size: 11px
    &:focus
      box-shadow: none
      border: none !important
</style>
