<template>
  <div>
    <div class="mt-4 mt-md-0" />
    <h4 class="title title-bordered mb-5">
      Senha
    </h4>

    <form @submit.prevent="saveForm">
      <div class="row justify-content-center pb-md-5 pb-3 enter-effect-opacity">
        <InputModal
          label="Senha atual:"
          v-model="password.current"
          type="password"
          :required="true"
          class="col-12"
        />
        <InputModal
          label="Nova senha:"
          v-model="password.new"
          type="password"
          :required="true"
          class="col-md-6 col-12"
        />
        <InputModal
          label="Confirme sua nova senha:"
          v-model="password.confirmNew"
          type="password"
          :required="true"
          class="col-md-6 col-12"
        />

        <p class="text-center mb-4 font-14 secondary">
          Para reforçar a sua senha utilize caracteres especiais, <br>
          letras maiúsculas e/ou números
        </p>

        <ButtonPink
          class="mb-4"
          :classe="'small'"
          label="Salvar alterações"
          type="submit"
          :is-disabled="isSaving"
        />
      </div>
    </form>
  </div>
</template>

<script>
import InputModal from '@/components/shared/forms/InputModal'
import ButtonPink from '@/components/shared/forms/ButtonPink'
import EducandoResource from '@/service/userService'

export default {
  name: 'SenhaComp',
  components: {
    InputModal,
    ButtonPink
  },

  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    this.EducandoResource = new EducandoResource(this.$resource)
    return {
      isSaving: false,
      password: {
        current: '',
        new: '',
        confirmNew: ''
      }
    }
  },

  methods: {
    saveForm () {
      if (this.password.new !== this.password.confirmNew) return this.$sweet.sweetMensagem('Nova senha e Repetir nova senha devem ser iguais!')
      this.$sweet.sweetWait('Aguarde enquanto estamos salvando sua nova senha')
      if (this.isSaving) return
      this.isSaving = true
      const dados = { senhaAtual: this.password.current, novaSenha: this.password.new }
      this.EducandoResource.editSenhaEducando(dados).then(_ => {
        this.isSaving = false
        this.$sweet.sweetSuccessResp('<h4>Senha atualizada com sucesso!</h4>')
      }).catch(_ => {
        this.isSaving = false
        this.$sweet.sweetErrorPassword()
      })
    }
  }
}
</script>
