import Piii from "piii";
import filtro from '@/assets/consts/words.json'
  
const piii = new Piii({
    repeated: true,
    filters: [...Object.values(filtro)],
});

export default class Pii {
    static filter (service, req, fluxo, key, attr = 'body') {
        const word = JSON.stringify(req[attr])
        const hasBadWord = piii.has(word)
        if (hasBadWord) {
            service.post('plataforma-conteudo/registrar-comportamento-inadequado', {
                fluxo,
                endpoint: req.url,
                observacao: req[attr][key],
            })
        }
        return JSON.parse(piii.filter(word))
    }
}