<template>
  <div class="d-flex flex-column text-center">
    <component
      class="m-auto"
      v-if="icon"
      :is="icons[icon]"
    />
    <div
      v-if="label"
      class="label"
    >
      <span
        v-html="label"
        class="dark-grey-2"
        style="white-space: pre-line;"
      />
    </div>
  </div>
</template>
<script>

import HeartIconFill from '@/assets/icons/HeartIconFill'
import HeartIcon from '@/assets/icons/HeartIcon'
import ReplyIcon from '@/assets/icons/ReplyIcon'
import OneReplyIcon from '@/assets/icons/OneReplyIcon'
import EyeIcon from '@/assets/icons/EyeIcon'

export default {
  data () {
    return {
      icons: {
        heart: HeartIcon,
        heartFill: HeartIconFill,
        eye: EyeIcon,
        reply: ReplyIcon,
        oneReply: OneReplyIcon
      }
    }
  },

  props: {
    label: {},
    icon: {}
  }
}
</script>
