const base = 'plataforma-conteudo/educando/seguidor'

export default class followService {
  constructor (resource) {
    const acoesCustomizadas = {
      followers: { method: 'GET', url: `${base}/seguidores` },
      followings: { method: 'GET', url: `${base}/seguindo` },
      follow: { method: 'POST', url: `${base}/seguir-educando{/idEducando}` },
      unfollow: { method: 'DELETE', url: `${base}/deixar-de-seguir-educando{/idEducando}` },
      following: { method: 'GET', url: `plataforma-conteudo/educando/sessao/seguidor/eu-sigo-esse-educando{/id}` },
      unfollowings: { method: 'GET', url: 'plataforma-conteudo/educando/sessao/seguidor/listar-aqueles-que-nao-sigo' },
      unfollowingsByName: { method: 'GET', url: `plataforma-conteudo/educando/sessao/seguidor/listar-educandos-filtrando-nome{/filtro}` },
    }
    this.resource = resource(base, {}, acoesCustomizadas)
  }

  followers (dados) {
    return this.resource
      .followers(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter lista de seus seguidores ${err}`)
      })
  }

  followings (dados) {
    return this.resource
      .followings(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter lista de educandos que você segue ${err}`)
      })
  }

  following (dados) {
    return this.resource
      .following(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter lista de educandos que você segue ${err}`)
      })
  }

  follow (dados) {
    return this.resource
      .follow(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  unfollow (dados) {
    return this.resource
      .unfollow(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  unfollowings (dados) {
    return this.resource
      .unfollowings(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  unfollowingsByName (dados) {
    return this.resource
      .unfollowingsByName(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }
}
