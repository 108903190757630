import { render, staticRenderFns } from "./CardMessage.vue?vue&type=template&id=3ad42224&scoped=true&"
import script from "./CardMessage.vue?vue&type=script&lang=js&"
export * from "./CardMessage.vue?vue&type=script&lang=js&"
import style0 from "./CardMessage.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./CardMessage.vue?vue&type=style&index=1&lang=sass&"
import style2 from "./CardMessage.vue?vue&type=style&index=2&id=3ad42224&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ad42224",
  null
  
)

export default component.exports