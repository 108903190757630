import { render, staticRenderFns } from "./CommentIcon.vue?vue&type=template&id=76edc49a&scoped=true&"
var script = {}
import style0 from "./CommentIcon.vue?vue&type=style&index=0&id=76edc49a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76edc49a",
  null
  
)

export default component.exports