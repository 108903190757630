<template>
  <div class="bg-dark-mobile">
    <section class="row">
      <div class="col-md-9 p-0">
        <div class="scrolling">
          <Video v-if="video" :video="video" :video-loading="videoLoading" />

          <Nav />
        </div>
      </div>

      <div class="col-md-3 px-0 bg-dark">
        <CourseBanner />
        <ChoiceVideo />
        <TemporadasList />
      </div>
    </section>
  </div>
</template>

<script>
import Nav from "./nav/Nav";
import ChoiceVideo from "./ChoiceVideo";
import CourseBanner from "./CourseBanner";
import TemporadasList from "./TemporadasList";
import UserService from "@/service/userService";
import CursoService from "@/service/cursoService";
import Video from "@/components/shared/video/Video";

export default {
  components: {
    Nav,
    Video,
    ChoiceVideo,
    CourseBanner,
    TemporadasList,
  },

  async created() {
    this.UserService = new UserService(this.$resource);
    this.CursoService = new CursoService(this.$resource);

    this.getAula();
    this.$root.persistVideo = this.persistVideo;
  },

  data() {
    return {
      video: {},
      videoLoading: true,
    };
  },

  methods: {
    async getAula() {
      this.video = {};
      const videoId = this.$route.params.video;
      if (!videoId || videoId === "search" || videoId === "-1") return;

      this.videoLoading = true;
      const { body } = await this.CursoService.cursoVideo({ id: videoId });
      if (videoId !== this.$route.params.video) return;

      this.videoLoading = false;
      this.video = body;
    },

    async persistVideo(assistido) {
      const { idTemporadaVideo, video: idVideo } = this.$route.params;
      const idVinculo = this.$store.getters["temporadas/getIdVinculo"];
      const duracao = Number(this.video.duracao);
      const method = assistido ? "removeWatchVideo" : "watchVideo";

      await this.UserService[method]({ idVinculo, idVideo: idTemporadaVideo });
      const current = Number(
        this.$store.getters["registration/getCurrentHours"]
      );

      const hours = current + ((duracao / 60) * assistido ? -1 : 1);
      this.$store.dispatch("registration/setHours", hours);
      const dispatch = assistido ? "removeWatched" : "setWatched";
      this.$store.dispatch(`temporadas/${dispatch}`, idVideo);
    },
  },

  watch: {
    "$route.params.video"() {
      this.getAula();

      const length = window.localStorage.length;
      for (let index = 0; index < length; index++) {
        const element = window.localStorage.key(index);
        const shouldRemove =
          element && element.includes("/curso") && element !== this.$route.path;
        if (shouldRemove) window.localStorage.removeItem(element);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.bg-dark
  background-color: #111 !important

@media (max-width: 500px)
  ::-webkit-scrollbar
    width: 3px
  .bg-dark
    background-color: #000 !important

::-webkit-scrollbar
  width: 3px
  background: #222 !important
  border-radius: 3px

::-webkit-scrollbar-track
  border-radius: 3px

::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff4163
  max-height: 50px

.scrolling
  max-height: calc(100vh)
  overflow: scroll
  overflow-x: hidden

.trailer
  font-size: 18px

.heart
  width: 30px

.bg-ep
  background-color: #16171c !important

.clock-icon
  width: 18px
  margin-bottom: 5px

.card-temp
  background-color: #252525
  border-radius: 15px
  padding: 2px 10px

.w-icon-mat
  width: 55px

.f-modulo
  font-size: 24px !important

.dropdown-toggle::after
  color: #ff4163 !important

ul
  list-style: none
  padding: 0px
  margin-top: 10px
@media (max-width: 500px)
  .w-icon-mat
    width: 40px

  .f-modulo
    font-size: 18px !important

  hr
    margin-top: 3px !important
</style>
