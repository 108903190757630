const SET_ALL = 'SET_ALL'

const state = {
  all: []
}

const actions = {
  setAll ({ commit }, all) {
    commit(SET_ALL, { all })
  }
}

const getters = {
  getAll: (state) => {
    return state.all
  }
}

const mutations = {
  [SET_ALL] (state, picture) {
    Object.assign(state, picture)
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
