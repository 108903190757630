<template>
  <section class="width-100">
    <p
      v-if="label"
      class="font-14"
    >
      {{ label }}:
    </p>
    <div :class="['input-group mb-3', isFocused ? 'focused': '']">
      <div class="input-group-prepend">
        <div class="bg-white py-2 pe-3 border-l-3 title-bordered slot-style">
          <slot />
        </div>
      </div>
      <input
        :required="true"
        :type="type"
        v-model="text"
        @blur="blur()"
        @focus="focused()"
        :placeholder="placeholder"
        class="form-control border-r-3 input-modal p-0"
      >
    </div>
  </section>
</template>

<script>
export default {
  name: 'InputComp',

  props: {
    value: {},
    type: {
      default: 'text'
    },
    placeholder: {},
    label: {}
  },

  data () {
    return {
      text: this.value || '',
      focus: false
    }
  },

  methods: {
    blur () {
      this.focus = false
    },

    focused () {
      this.focus = true
    }
  },

  computed: {
    isFocused () {
      return this.focus
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        if (value === this.text) return
        this.text = value
      }
    },

    text: {
      deep: true,
      handler (text) {
        if (text === this.value) return
        this.$emit('input', text)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.focused
  box-shadow: 1px 2px 5px 2px rgba(255, 65, 99, .5)
  border-bottom: 1px solid #ff4163 !important
  border-radius: 8px
.border-l-3
  border-top-left-radius: 8px !important
  border-bottom-left-radius: 8px !important
.border-r-3
  border-top-right-radius: 8px !important
  border-bottom-right-radius: 8px !important
.input-modal
  background-color: white
  border: none !important
  color: #000 !important
  padding-left: 10px !important
  &:focus
    border-bottom: none !important
    box-shadow: 0px 0px rgba(0,0,0,0) !important
.input-group-prepend
  position: relative
  &:before
    top: 50%
    right: 1px
    width: 1px
    content: ''
    height: 35%
    position: absolute
    background-color: rgba(45,45,45,.35)
    transform: translateY(-50%)
.width-100
  width: 100% !important
.slot-style
  min-height: 32px !important
</style>
