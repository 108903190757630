<template>
  <div
    class="modal fade"
    :id="id"
  >
    <div class="modal-dialog">
      <div :class="['bg-modal modal-content p-4 pt-2', classe]">
        <img
          class="pointer close-modal"
          src="@/assets/images/close.png"
          data-toggle="modal"
          :data-target="`#${id}`"
        >
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalComp',
  props: {
    id: {
      type: String,
      required: true
    },
    classe: {
      type: String
    }
  }
}
</script>
<style lang="sass">
.modal
  background-color: rgba(0,0,0,0.35)
@media (max-width: 500px)
  .close-modal
    right: 5px !important
    top: 5px !important

.close-modal
  z-index: 1
  position: absolute
  right: -40px
  top: 0px
  max-width: 25px !important

.bg-modal
  background-color: #0b0b0b

%default-input
  width: 100%
  background-color: transparent
  border: none
  border-bottom: 3px solid #171717
  font-size: 14px

.input-modal
  @extend %default-input
  color: #c5c5c5

.select-modal
  @extend %default-input
  color: #c5c5c5
  &:focus
    color: #1b1b1b !important

input::-webkit-input-placeholder
  font-size: 13px
  line-height: 3

::-webkit-calendar-picker-indicator
  font-size: 13px
  filter: invert(0.8)

.select-modal::-ms-expand
  display: none !important

</style>
