<template>
  <div :class="['mb-2 px-2', bloquedStyle(item), currentStyle(item)]">
    <div :class="['position-relative', menuIsOpen ? 'row align-items-center' : '']">
      <div :class="['col-auto', menuIsOpen ? 'img-col' : '']">
        <img
          :src="require(`@/assets/images/menu-icons/${item.src}`)"
          :class="['icons', item.class]"
        >

        <div
          class="position-absolute top-0 font-12 white count"
          v-if="item.notify"
        />

        <div :class="['position-absolute top-0 end-0 pink', menuIsOpen ? 'me-4' : 'me-md-2 me-0']">
          {{ item.isOpen ? item.closedIcon : item.openedIcon }}
        </div>
      </div>
      <div
        :class="['col-auto', menuIsOpen ? 'p-col' : '']"
        v-if="!isMobile"
      >
        <p :class="['dark-grey-2', menuIsOpen ? 'font-14 white' : 'font-12']">
          {{ item.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MenuItem',

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    notificacoesIsOpen: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    bloquedStyle (item) {
      return item.isBloqued ? 'cursor-block' : 'pointer'
    },

    currentStyle (item) {
      if (this.notificacoesIsOpen && item.class === 'notificacoes') return 'current'
      if (item.links) return item.links.includes(this.routeName) && !item.isBloqued && !this.notificacoesIsOpen ? 'current' : ''
      return this.routeName === item.linkTo && !item.isBloqued && !this.notificacoesIsOpen ? 'current' : ''
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'configs/isMobile',
      menuIsOpen: 'configs/menuIsOpen'
    }),

    routeName () {
      return this.$route.name
    }
  }
}
</script>

<style lang="sass" scoped>
@media (max-width: 500px)
  .min
    min-height: 100vh
    height: 100%
.max-width
  max-width: 400px !important
  min-width: 200px !important
.menu-content
  position: sticky
  top: 0px
.icons
  width: 21px
.senha
  width: 15px !important
.comunidade
  width: 21px !important
.home
  width: 24px !important
.cursos
  width: 24px !important
.current
  background-color: rgb(31,31,31)
  padding: 5px
  margin-right: 3px
  margin-left: 3px
  border-radius: 5px

.img-col
  width: 45px
  text-align: center !important
.p-col
  min-width: 150px
  text-align: left
  padding-top: 3px

.cursor-block
  filter: grayscale(1)
.count
  display: inline-flex
  justify-content: center
  align-items: center
  left: 36px
  border-radius: 50%
  width: 7px
  height: 7px
  background-color: #ff4163
</style>
