<template>
  <div>
    <div class="d-block">
      <label
        v-if="label"
        class="label"
        v-html="`${label} ${required ? '*' : ''}`"
      />
    </div>

    <div :class="[isInline ? 'd-inline-flex flex-wrap' : '', 'mt-1']">
      <div
        v-for="option in options"
        :key="`${id}-${option.id}`"
        class="item-flex"
      >
        <div class="pl-4 pr-2 mb-1">
          <input
            :disabled="isDisabled"
            v-model="field"
            required
            class="form-check-input"
            type="radio"
            :id="`${id}-${option.id}`"
            :value="option.id"
          >
          <label
            class="label-option text-nowrap pointer"
            :for="`${id}-${option.id}`"
            v-html="`${option.titulo} ${option.observacao || ''}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {},
    value: {},
    label: {},
    options: {},
    required: {},
    isInline: {},
    isDisabled: {}
  },

  data () {
    return {
      field: ''
    }
  },

  mounted () {
    this.field = this.options.find((item) => item.value === this.value)?.value
  },

  watch: {
    value: {
      handler (value) {
        if (this.$f.isEqual(value, this.field)) return
        this.field = this.options.find((item) => item.value === value)?.value
      }
    },

    field: {
      handler (field) {
        if (this.$f.isEqual(field, this.value)) return
        this.$emit('input', field)
      }
    }
  }
}
</script>
<style>
.form-check-input {
  width: 18px;
  height: 18px;
}
.label-option {
  padding-top: 3px;
  padding-left: 5px;
  font-size: 12px !important;
}
.form-check-input {
  border-width: 2px;
  background: #00000000;
  border-color: #ff4163 !important;
  width: 15px !important;
  height: 15px !important;
}
.form-check-input:checked {
  background-color: #ff4163 !important;
  border-color: #ff4163 !important;
  border-width: 8px;
  width: 15px !important;
  height: 15px !important;
}
.form-check-input:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border-color: #ff4163 !important;
}
.item-flex {
  flex-basis: 25%;
}
</style>
