<template>
  <ul class="ul-links mt-0 mt-md-3 px-1 px-md-5">
    <p
      class="text-center pb-2"
      v-if="materiais.length === 0"
    >
      Não existem materiais da aula
    </p>
    <li
      class="mb-3"
      v-for="material in materiais"
      :key="`${material.id}-material`"
    >
      <p class="secondary font-16">
        {{ material.titulo }}
      </p>
      <div
        v-for="link in mountLinks(material.linkAcessoArquivo)"
        :key="link"
      >
        <a
          :href="link"
          target="_blank"
          class="upper"
        >
          {{ link }}
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
import CursoService from '@/service/cursoService'

export default {
  data () {
    return {
      materiais: []
    }
  },

  created () {
    this.CursoService = new CursoService(this.$resource)
    this.getMateriais()
  },

  methods: {
    async getMateriais () {
      const id = this.$route.params.video
      if (id === 'search') return

      const { body: materiais } = await this.CursoService.materiais({ id })
      this.materiais = materiais
      this.$emit('input', materiais.length)
    },

    mountLinks (link) {
      if (!link) return ''

      const url = link.split('http').map(lin => `http${lin}`)
      if (!url.length) return link

      url.shift()
      return url
    }
  },

  watch: {
    '$route.params.video' () {
      this.getMateriais()
    }
  }
}
</script>
