<template>
  <div
    class="modal fade"
    id="modalImageProfile"
  >
    <div class="modal-dialog">
      <div class="modal-content bg-dark">
        <div class="modal-header py-0 px-2">
          <h5 class="modal-title">
            Altere sua imagem
          </h5>
          <button
            type="button"
            class="btn close white upper hovered"
            data-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
        </div>
        <form @submit.prevent="sendImage">
          <div class="modal-body">
            <div class="row">
              <FieldFile
                v-model="image"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-primary bg-pink border-none"
              type="submit"
            >
              Salvar alterações
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import FieldFile from '@/components/shared/forms/FieldFile'
import EducandoResource from '@/service/userService'

export default {
  components: {
    FieldFile
  },

  data () {
    this.EducandoResource = new EducandoResource(this.$resource)
    return {
      image: {}
    }
  },

  methods: {
    ...mapActions({ setUser: 'registration/setUser' }),

    mountImage ({ extensaoImagem, id }) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=educando&extensao=${extensaoImagem}&identificador=${id}`
    },

    sendImage () {
      const dataForm = new FormData()
      dataForm.append('arquivo', this.image)
      dataForm.append('identificador', this.user.id)
      dataForm.append('caminho', 'educando')
      const headers = { 'Content-Type': 'multipart/form-data' }
      this.$sweet.sweetWait('Aguarde enquanto estamos salvando')
      this.$http.post(`${Vue.http.options.root}/acesso-arquivo`, dataForm, { headers }).then(res => {
        const data = { identificador: this.user.id, caminho: 'educando', tabela: 'educando.educandos', campo: 'extensao_imagem', valor: res.body }
        this.$http.put(`${Vue.http.options.root}/atualiza-campo`, data).then(_ => {
          this.EducandoResource.buscarDados().then(({ body }) => {
            this.setUser({ ...body, extensaoImagem: '' })
            setTimeout(() => this.setUser(body), 200)
            this.$sweet.sweetSuccessResp('<h4>Foto atualizada com sucesso!')
          })
        }).catch(e => {
          console.log(e)
        })
      }).catch(_ => {
        this.$sweet.sweetError()
      })
    }
  },

  computed: {
    ...mapGetters({
      user: 'registration/getUser'
    })
  }
}
</script>

<style lang="sass" scoped>
#modalImageProfile
  background-color: rgba(0,0,0,0.35)
  .modal-footer
    border: none !important
  .modal-header
    border: none !important
  .close
    color: #ff4163
  .modal-title
    padding-left: 10px
    padding-top: 5px
</style>
