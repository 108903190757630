<template>
  <div :class="['toggle-menu position-absolute pointer', notificacoesIsOpen ? 'right-menu' : '']">
    <div :class="['button-toggle', menuIsOpen || notificacoesIsOpen ? 'button-toggle-opened' : '']" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    notificacoesIsOpen: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      menuIsOpen: 'configs/menuIsOpen'
    })
  }
}
</script>

<style lang="sass" scoped>
.right-menu
  right: -260px !important

.toggle-menu
  transition: all .35s
  width: 20px
  height: 20px
  top: 80px
  right: -10px
  z-index: 10
  background-color: #313131
  display: inline-flex
  justify-content: center
  align-items: center
  .button-toggle-opened
    transform: rotate(-135deg) !important
    margin-left: 3px !important
  .button-toggle
    transition: all .25s
    margin-left: -3px
    width: 8px
    height: 8px
    transform: rotate(45deg)
    border-top: 2px solid #ddd
    border-right: 2px solid #ddd
</style>
