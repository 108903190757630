import moment from 'moment'
export const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']

export default class functions {
  decriptTinyMCE (str) {
    str = str.replace(/&oacute;/g, 'ó')
    str = str.replace(/&ccedil;/g, 'ç')
    str = str.replace(/&atilde;/g, 'ã')
    str = str.replace(/&iacute;/g, 'í')
    str = str.replace(/&aacute;/g, 'á')
    str = str.replace(/&nbsp;/g, '')
    str = str.replace(/&eacute;/g, 'é')
    str = str.replace(/&uacute;/g, 'ú')
    str = str.replace(/&otilde;/g, 'õ')
    str = str.replace(/&agrave;/g, 'à')
    str = str.replace(/&ecirc;/g, 'ê')
    str = str.replace(/&acirc;/g, 'â')
    return str
  }

  decriptTinyMCEContra (str) {
    str = str.replace(/ó/gi, 'o')
    str = str.replace(/ç/gi, 'c')
    str = str.replace(/ã/gi, 'a')
    str = str.replace(/í/gi, 'i')
    str = str.replace(/á/gi, 'a')
    str = str.replace(/é/gi, 'e')
    str = str.replace(/ú/gi, 'u')
    str = str.replace(/õ/gi, 'o')
    str = str.replace(/à/gi, 'a')
    str = str.replace(/ê/gi, 'e')
    str = str.replace(/â/gi, 'a')
    return str
  }

  testaCPF (CPF) {
    if (CPF.length === 14) {
      const strCPF = CPF.replace(/[.*-]/g, '')
      let Soma
      let Resto
      Soma = 0
      if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' || strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' || strCPF === '88888888888' || strCPF === '99999999999') return false
      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      Resto = (Soma * 10) % 11

      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      Resto = (Soma * 10) % 11

      if ((Resto === 10) || (Resto === 11)) Resto = 0
      if (Resto !== parseInt(strCPF.substring(10, 11))) return false
      return true
    }
    return false
  }

  mountDate (date) {
    if (!date) return ''
    const [dateTime] = date.split('T')
    const p = dateTime.split('-')
    return `${p[2]}/${p[1]}/${p[0]}`
  }

  converterDatas (data) {
    if (!data) return ''
    const ano = data.slice(0, 4)
    const mes = data.slice(5, 7)
    const dia = data.slice(8, 10)
    return `${dia}/${mes}/${ano}`
  }

  day (data) {
    if (!data) return ''
    const dia = data.slice(8, 10)
    return `${dia}`
  }

  month (data) {
    if (!data) return ''
    const mes = data.slice(5, 7)
    return months[mes - 1]
  }

  year (data) {
    if (!data) return ''
    return data.slice(0, 4)
  }

  converterDataString (data) {
    if (!data) return ''
    const ano = data.slice(6, 10)
    const mes = data.slice(3, 5)
    const dia = data.slice(0, 2)
    return `${ano}-${mes}-${dia}`
  }

  converterDataAmericano (data) {
    const ano = data.slice(0, 4)
    const mes = data.slice(5, 7)
    const dia = data.slice(8, 10)
    return `${ano}-${mes}-${dia}`
  }

  converterDataPadrao (data) {
    const ano = data.slice(2, 4)
    const mes = data.slice(5, 7)
    const dia = data.slice(8, 10)
    return `${dia}/${mes}/${ano}`
  }

  converterDataPadraoCheio (data) {
    if (!data) return ''
    const ano = data.slice(0, 4)
    const mes = data.slice(5, 7)
    const dia = data.slice(8, 10)
    return `${dia}/${mes}/${ano}`
  }

  converterDataPonto (data) {
    const ano = data.slice(0, 4)
    const mes = data.slice(5, 7)
    const dia = data.slice(8, 10)
    return `${dia}.${mes}.${ano}`
  }

  converterDataTraco (data) {
    const ano = data.slice(0, 4)
    const mes = data.slice(5, 7)
    const dia = data.slice(8, 10)
    return `${dia}-${mes}-${ano}`
  }

  converterDataPontoAmericano (data) {
    const ano = data.slice(6, 10)
    const mes = data.slice(3, 5)
    const dia = data.slice(0, 2)
    return `${dia}.${mes}.${ano}`
  }

  isEqual (val1, val2) {
    return JSON.stringify(val1) === JSON.stringify(val2)
  }

  timeAgo (date) {
    const dateNow = moment(new Date())
    date = moment(date)
    const years = dateNow.diff(date, 'year')
    if (years) return `${years}a`

    date.add(years, 'years')
    const months = dateNow.diff(date, 'months')
    if (months) return `${months}m`

    date.add(months, 'months')
    const days = dateNow.diff(date, 'days')
    if (days) return `${days}d`

    date.add(days, 'days')
    const hours = dateNow.diff(date, 'hours')
    if (hours) return `${hours}h`

    date.add(hours, 'hours')
    const minutes = dateNow.diff(date, 'minutes')
    if (minutes) return `${minutes}min`

    date.add(minutes, 'minutes')
    const seconds = dateNow.diff(date, 'seconds')
    if (seconds) return `${seconds}seg`

    return ''
  }

  camalize (str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }
}
