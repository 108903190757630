<template>
  <div>
    <div
      class="perfil"
      ref="perfil"
    >
      <div :class="['capa', isCurrentUser ? 'has-hover' : '']">
        <img
          :src="imgCapa"
          data-target="#modalImageBanner"
          :data-toggle="isCurrentUser? 'modal' : ''"
          :class="['imagem-capa-perfil', isCurrentUser ? 'pointer' : '']"
          @error="$event.target.src = require('@/assets/images/bg-lives.png')"
        >
        <div
          class="button-capa pointer"
          v-if="isCurrentUser"
          data-target="#modalImageBanner"
          :data-toggle="isCurrentUser? 'modal' : ''"
        >
          <p class="font-13 white">
            Alterar Banner
            <UploadCloudIcon
              class="ms-1"
              color="white"
            />
          </p>
        </div>

        <ModalImageBanner />
      </div>

      <div class="row justify-content-center pb-5 enter-effect">
        <div class="col-11 max-1000">
          <div class="row pt-3 justify-content-center">
            <div :class="['col-12 col-md max-box-perfil mb-5 mb-md-0', menuIsOpen ? 'ps-0' : '']">
              <div class="bg-black w-100 box-perfil p-2">
                <LoadingComp
                  class="pt-4"
                  v-if="isLoadingData"
                />

                <div
                  v-else
                  class="content-perfil"
                >
                  <div class="row justify-content-center">
                    <div class="col-11 enter-effect">
                      <PersonalInfos
                        class="mb-2"
                        :conquistas="conquistas"
                        :dados-educando="dadosEducando"
                        :is-current-user="isCurrentUser"
                      />

                      <Statistics
                        class="mb-3"
                        :estatisticas="estatisticas"
                      />

                      <HoursPerfil
                        class="mb-3"
                        :horas-educando="horasEducando"
                      />

                      <Softwares
                        class="mb-2"
                        :softwares="softwares"
                      />

                      <SocialMedia
                        class="mb-2"
                        :midias="midiasSociais"
                      />

                      <AboutMe
                        class="mb-3"
                        :descricao="descricao"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-8 mt-3 mt-md-0 buttons">
              <div class="row justify-content-between mb-3 align-items-end">
                <div class="col-md-auto col-6 px-md-0">
                  <button
                    :disabled="loadingGalery"
                    @click="listBy('trabalhos')"
                    :class="['me-2', orderBy === 'trabalhos' ? 'btn-fill' : 'btn-transparent']"
                  >
                    Trabalhos
                  </button>

                  <button
                    :disabled="loadingGalery"
                    @click="listBy('curtidos')"
                    :class="[orderBy === 'curtidos' ? 'btn-fill' : 'btn-transparent']"
                  >
                    Curtidos
                  </button>
                </div>

                <div
                  v-if="isCurrentUser"
                  class="col-auto px-md-0"
                >
                  <NewAlbum
                    @listBy="listBy"
                    show-button
                  />
                </div>
              </div>

              <div class="row aligment-album">
                <Album
                  class="mb-1"
                  :album="album"
                  col="col-6 col-md-12 col-sm-6 col-lg-6 col-xl-4"
                  :key="`${ind}-album-galery`"
                  v-for="(album, ind) in galery"
                />
              </div>

              <p
                @click="getGalery()"
                title="Carregar mais"
                v-if="!loadingGalery && !isLoaded && isStarted"
                class="pointer dark-grey-2 text-center font-13 hovered mt-3"
              >
                Carregar mais...
              </p>

              <p
                class="text-center dark-grey-2 mt-5 font-14"
                v-if="!galery.length && !loadingGalery && isStarted"
              >
                Ainda não tem projetos cadastrados.
              </p>

              <LoadingComp v-if="loadingGalery" />

              <ModalGalery />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from '@/Mixins'
import AboutMe from './AboutMe'
import NewAlbum from './NewAlbum'
import Softwares from './Softwares'
import Statistics from './Statistics'
import SocialMedia from './SocialMedia'
import HoursPerfil from './HoursPerfil'
import PersonalInfos from './PersonalInfos'
import ModalImageBanner from './ModalImageBanner'
import PerfilService from '@/service/perfilService'
import GaleryService from '@/service/galeryService'
import SharedResource from '@/service/sharedServices'
import Album from '@/components/shared/galeria/Album'
import UploadCloudIcon from '@/assets/icons/UploadCloud'
import ModalGalery from '@/components/shared/galeria/modal/ModalGalery'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PerfilComp',

  mixins: [Mixins],

  components: {
    Album,
    AboutMe,
    NewAlbum,
    Softwares,
    Statistics,
    ModalGalery,
    HoursPerfil,
    SocialMedia,
    PersonalInfos,
    UploadCloudIcon,
    ModalImageBanner
  },

  props: {
    direction: {
      default: 'down'
    }
  },

  async created () {
    this.selectedUser = Number(this.$route.params.user)
    this.PerfilService = new PerfilService(this.$resource)
    this.GaleryService = new GaleryService(this.$resource)
    this.SharedResource = new SharedResource(this.$resource)
    this.setGaleryEmpty()
    this.getData()

    if (!this.$route.params.user || !this.currentUser.id) return
    this.getInitial()
  },

  data () {
    return {
      skip: 0,
      qtd: 15,
      isLoaded: false,
      isStarted: false,
      selectedUser: null,
      orderBy: 'trabalhos',
      loadingGalery: false
    }
  },

  methods: {
    ...mapActions({
      setAlbum: 'galery/setAlbum',
      setGalery: 'galery/setGalery',
      setPerson: 'perfil/setPerson',
      setLoaded: 'perfil/setLoaded',
      setLoading: 'perfil/setLoading',
      setGaleryEmpty: 'galery/setGaleryEmpty'
    }),

    async getInitial () {
      if (this.isStarted) return
      this.isStarted = true
      await this.getGalery()

      this.routerTo()
      this.openModal()
    },

    async getData () {
      this.setLoading(true)
      const id = this.$route.params.user
      const { body } = await this.PerfilService.getDados({ id })

      this.setLoading(false)
      this.setLoaded(true)

      body.dadosEducando[0].dataHoraCadastro = this.$f.converterDatas(body.dadosEducando?.[0]?.dataHoraCadastro)
      body.dadosEducando[0].img = this.mountImagePath('educando', id, body.dadosEducando?.[0]?.extensaoImagem)
      body.dadosEducando[0].capa = this.mountImagePath('capa', id, body.dadosEducando?.[0]?.extensaoCapaPerfil)
      this.setPerson({ ...body, id, following: false })
    },

    listBy (orderBy = this.orderBy) {
      this.skip = 0
      this.orderBy = orderBy
      this.setGaleryEmpty()
      this.isLoaded = false
      this.getGalery()
    },

    async getGalery () {
      if (this.loadingGalery || this.isLoaded) return
      this.loadingGalery = true

      const orderByJob = this.orderBy === 'trabalhos'
      const idEducando = Number(this.$route.params.user)
      const isCurrentUser = Number(this.$route.params.user) === Number(this.currentUser.id)

      let orderBy = 'getMyListLikes'
      if (!isCurrentUser && orderByJob) orderBy = 'getListUpdates'
      if (!isCurrentUser && !orderByJob) orderBy = 'getListLikes'
      if (isCurrentUser && orderByJob) orderBy = 'getMyListUpdates'

      const { body, status } = await this.GaleryService[orderBy]({ qtd: this.qtd, skip: this.skip, idEducando })
      if (status !== 200) return (this.loadingGalery = false)

      if (!body?.[1]?.galerias?.length || body[1].galerias.length < this.qtd) this.isLoaded = true
      this.skip = this.skip + this.qtd

      const [educando, galeria] = body
      const aluno = this.mountAluno(educando.dadosEducando.pop(), idEducando)

      const galeriaMap = []
      let findGalery = []
      const idGalery = Number(this.$route.params.album || 0)

      if (idGalery) {
        const isLoaded = galeria.galerias.some(item => item.galeria.id === idGalery)
        if (!isLoaded) {
          const { body: galery } = await this.GaleryService.getMyGalery({ idGalery })
          const { trabalhos } = galery
          findGalery = [{
            trabalhos,
            galeria: galery
          }]
        }
      }

      for (const { galeria: currentGalery, trabalhos } of [...galeria.galerias, ...findGalery]) {
        const idGalery = currentGalery.id

        const alreadyIsSet = this.galery.some(({ albumId }) => albumId === idGalery)
        if (!trabalhos?.length || alreadyIsSet) continue

        const params = {
          aluno,
          trabalhos,
          images: [],
          liked: false,
          softwares: [],
          albumId: idGalery,
          title: currentGalery.titulo,
          likes: currentGalery.curtidas || 0,
          views: currentGalery.exibicoes,
          descricao: currentGalery.descricao,
          capa: this.mountPath(aluno.id, idGalery, trabalhos)
        }

        galeriaMap.push(params)
      }
      this.loadingGalery = false
      this.setGalery(galeriaMap)
    },

    mountAluno (aluno, idEducando) {
      return {
        conquistas: [],
        id: idEducando,
        ocupacao: aluno.ocupacao,
        ext: aluno.extensaoImagem,
        nome: `${aluno.nome || ''} ${aluno.sobrenome || ''}`,
        img: this.mountImagePath('educando', idEducando, aluno.extensaoImagem)
      }
    },

    mountPath (idEducando, idGalery, trabalhos) {
      if (!trabalhos.length) return ''

      const { id: jobId, extensao } = trabalhos[0]
      return `${this.$space}/postagens/${idEducando}/${idGalery}/${jobId}.${extensao}`
    },

    routerTo () {
      if (!this.currentUser.id || this.$route.params.user) return

      const isPerfil = this.$route.name === 'perfil'
      if (isPerfil) this.$router.push({ name: 'perfil', params: { user: this.currentUser.id } }).catch(() => {})
    },

    openModal () {
      const album = Number(this.$route.params.album)
      if (album) {
        const item = this.galery.find(({ albumId }) => albumId === album)
        if (!item) return

        this.setAlbum(item)
        setTimeout(() => document.getElementById('btn-modal-picture')?.click(), 500)
      }
    }
  },

  computed: {
    ...mapGetters({
      galery: 'galery/getGalery',
      person: 'perfil/getPerson',
      isLoadedData: 'perfil/isLoaded',
      menuIsOpen: 'configs/menuIsOpen',
      isLoadingData: 'perfil/isLoading',
      currentUser: 'registration/getUser'
    }),

    isCurrentUser () {
      return Number(this.$route.params.user) === this.currentUser.id
    },

    conquistas () {
      return this.person?.conquistas?.map(({ id, extensaoImagem, titulo }) => {
        const src = this.mountImagePath('conquista', id, extensaoImagem)
        return { src, titulo }
      }) || []
    },

    dadosEducando () {
      const [dados] = this.person?.dadosEducando || [{}]
      return dados
    },

    descricao () {
      const [{ descricao }] = this.person?.dadosEducando || [{}]
      return descricao || ''
    },

    softwares () {
      return this.person?.softwares?.map(({ id }) => id) || []
    },

    midiasSociais () {
      const [{ linkTiktok }] = this.person?.dadosEducando || [{}]
      const [{ linkYoutube }] = this.person?.dadosEducando || [{}]
      const [{ linkTwitter }] = this.person?.dadosEducando || [{}]
      const [{ linkLinkedin }] = this.person?.dadosEducando || [{}]
      const [{ linkFacebook }] = this.person?.dadosEducando || [{}]
      const [{ linkInstagram }] = this.person?.dadosEducando || [{}]

      return [
        (linkInstagram && { type: 'instagram', link: linkInstagram }),
        (linkTwitter && { type: 'twitter', link: linkTwitter }),
        (linkYoutube && { type: 'youtube', link: linkYoutube }),
        (linkTiktok && { type: 'tiktok', link: linkTiktok }),
        (linkLinkedin && { type: 'linkedin', link: linkLinkedin }),
        (linkFacebook && { type: 'facebook', link: linkFacebook })
      ].filter(item => item)
    },

    horasEducando () {
      const horasEducando = this.person?.horasConteudoConsumido || 0
      const dataHoraCadastro = this.person?.dadosEducando?.[0]?.dataHoraCadastro || 0

      return {
        dia: dataHoraCadastro,
        horas: Math.round(horasEducando)
      }
    },

    estatisticas () {
      const [{ totalSeguindo: following }] = this.person?.seguindo || [{}]
      const [{ totalSeguidores: followers }] = this.person?.seguidores || [{}]
      const [{ totalExibicoesGalerias: projectsViews }] = this.person?.exibicoesGaleria || [{}]
      const [{ totalCurtidasGalerias: likes }] = this.person?.curtidasGaleria || [{}]

      return { likes, followers, following, projectsViews }
    },

    imgCapa () {
      if (this.isLoadingData) return require('@/assets/images/bg-lives.png')
      return this.person?.dadosEducando?.[0]?.capa || ''
    }
  },

  watch: {
    '$route.params.user': {
      deep: true,
      handler (selectedUser) {
        if (Number(selectedUser) === Number(this.person.id)) return
        this.getData()
        this.listBy(this.orderBy)
      }
    },

    'currentUser.id': {
      deep: true,
      handler () {
        this.getInitial()
      }
    },

    direction: {
      handler (direction) {
        if (direction === 'up') this.getGalery()
      }
    }
  }
}
</script>

<style lang="sass">
.perfil
  .loading
    left: 50%
    top: 50%
    transform: translateX(-50%) translateY(-50%)
  .max-box-perfil
    max-width: 320px
  .scroll
    min-height: 100vh
  .scroll::-webkit-scrollbar
    width: 2px !important
  .scroll::-webkit-scrollbar-track
    border-radius: 2px !important
  .btn-fill, .btn-transparent
    width: 130px
  .mb-1
    margin-bottom: 2px !important

  .has-hover
    &:hover
      .imagem-capa-perfil
        opacity: .5
      .button-capa
        bottom: 10px
  .capa
    position: relative
    overflow: hidden
    .button-capa
      right: 25px
      bottom: -40px
      transition: all .25s
      position: absolute
    .imagem-capa-perfil
      border: none
      user-select: none
      width: 100%
      overflow: hidden
      height: 200px
      transition: all .25s
      object-fit: cover

  .box-perfil
    top: 50px
    margin-top: -150px
    position: relative
    min-height: 100px !important

@media (max-width: 1128px) and (min-width: 768px)
  .buttons
    margin-top: 60px !important
@media (max-width: 600px)
  .aligment-album
    padding-left: 12px !important
    padding-right: 12px !important
  .perfil
    .bg-black
      background-color: #000 !important
</style>
