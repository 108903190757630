<template>
  <div :class="['show-message mt-3', isDeleted && 'hide-message']">
    <div :id="item.id" class="card-message bg-black p-3 d-inline-flex flex-column flex-lg-row position-relative">
      <div class="personal-image mb-2 mb-sm-1 mb-lg-3 position-relative">
        <router-link class="text-decoration-none" :to="{
          name: 'perfil',
          params: { user: item.idAluno || item.idEducando },
        }">
          <img v-if="item.img" :src="item.img" class="user-img with-events" />
          <div v-else-if="item.aluno" class="user-name">
            {{ item.aluno.charAt(0) }}
          </div>
        </router-link>

        <div class="tag-message">
          <TagMode :is-pro="item.isPro" />
        </div>
      </div>

      <div class="content-card-message d-grid w-100">
        <div class="row justify-content-between mb-2">
          <div class="col">
            <div class="row align-items-start">
              <div class="col-auto">
                <router-link :to="{
                  name: 'perfil',
                  params: { user: item.idAluno || item.idEducando },
                }" class="hovered text-decoration-none text-nowrap">
                  <h6 class="font-14">
                    {{ item.aluno }}
                  </h6>
                </router-link>

                <p class="dark-grey-2 font-12">
                  {{ item.ocupacao }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-auto dark-grey-2 font-12 align-self-end align-self-lg-auto">
            {{ item.data }}
          </div>

          <div class="col-auto dark-grey-2 font-14 options" v-if="
            (isCurrentUser && isNum) ||
            user.acesso === 'L0YrOTV5SXlKUEZXeXRmMGh5Qjh6UT09'
          ">
            <div class="dropdown dropleft" title="Excluir galeria">
              <span id="dropdownMenuButton" class="dropleft pointer bg-dark border-radius-50 p-1"
                data-toggle="dropdown">
                <VerticalDots />
              </span>

              <div class="dropdown-menu bg-dark">
                <div class="ps-3 pointer d-flex align-items-center h-100" @click="excluir()">
                  <TrashIcon class="me-2" />
                  <label class="white font-14 hovered mt-1 pointer">
                    Excluir
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="conquests w-100 pb-2 d-flex flex-row mb-3 width-100 overflow-hidden">
          <img :src="img.src" v-for="(img, ind) in item.conquistasEducando" :title="img.titulo"
            :key="`${ind}-${img.src}`" />
        </div>

        <div class="mb-4">
          <div v-html="item.descricao" class="dark-grey-2 font-14 v-html" />

          <div class="d-inline-flex w-100 images mt-2">
            <div class="content-loading" v-if="item.isLoadingImagens">
              <div class="loading-images" />
            </div>

            <Album is-cdn :item="item" :album="album" :ref="`albumRef${ind}`" class="album-feed"
              :key="`${ind}-album-galery`" v-for="(album, ind) in images" />

            <div v-if="showEllipse" class="pointer col content-more-images" @click="openModal()">
              <div class="more-images" />
            </div>
          </div>
        </div>

        <div class="footer-comment">
          <div class="d-flex flex-row justify-content-between align-items-end">
            <div class="statistic d-flex flex-row align-items-end">
              <IconLabel :icon="item.isLiked ? 'heartFill' : 'heart'" @click.native="handleLike"
                :class="['me-3 pointer', !isNum && 'opacity-50']" :label="item.likes || '0'" />
              <IconLabel icon="eye" :label="item.views" />
            </div>
            <div data-toggle="collapse" :id="`btn-message-${item.id}`" :data-target="`#message-${item.id}`" />
            <div class="answer pointer" @click="
              scrollTo(item.id, !(!item.isAnswer && !isHeader && isNum))
              ">
              <IconLabel icon="reply" label="Responder" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="collapse my-4 mb-2" :id="`message-${item.id}`">
        <form class="d-flex flex-column" @submit.prevent="responder()">
          <div class="col row">
            <div class="col-auto p-md-5 p-4" />
            <div class="col">
              <FieldTextQuill v-model="message" @keyPressed="responder()" />
            </div>
          </div>
          <div class="col justify-content-end text-end">
            <button class="btn bg-btn-pink font-14 mb-1">Enviar</button>
          </div>
        </form>
      </div>
    </div>

    <div v-if="item.messages && item.messages.length" class="row justify-content-end">
      <div v-for="(itemChild, ind) in item.messages" :key="`child-messages-${ind}`" class="col-11 border-left">
        <CardMessage :remover-feed="removerFeed" :item="itemChild" is-child />
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/Mixins";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import FeedService from "@/service/feedService";
import UserService from "@/service/userService";
import TrashIcon from "@/assets/icons/TrashIcon";
import Album from "@/components/shared/galeria/Album";
import VerticalDots from "@/assets/icons/VerticalDots";
import IconLabel from "@/components/shared/forms/IconLabel";
import FieldTextQuill from "@/components/shared/forms/FieldTextQuill";

export default {
  name: "CardMessage",

  mixins: [Mixins],

  components: {
    Album,
    IconLabel,
    TrashIcon,
    VerticalDots,
    FieldTextQuill,
  },

  props: {
    isChild: {
      type: Boolean,
      default: false,
    },

    item: {},

    removerFeed: {
      type: Function,
      default: () => { },
    },

    isHeader: {},

    inserirMensagem: {
      type: Function,
      default: () => { },
    },
  },

  created() {
    this.visualizar();
  },

  data() {
    this.FeedService = new FeedService(this.$resource);
    return {
      id: "",
      message: "",
    };
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      isMobile: "configs/isMobile",
      isTablet: "configs/isTablet",
      isLittleMobile: "configs/isLittleMobile",
      removedItems: "feed/getExcludedItems",
    }),

    showEllipse() {
      const images = this.item?.imagens;
      return images?.length && images?.length > 3;
    },

    images() {
      const links =
        this.item?.imagens?.map((i) => ({
          id: i.id,
          capa: i.img,
        })) || [];

      const slice = this.isLittleMobile
        ? 1
        : this.isMobile || this.isTablet
          ? 2
          : 3;

      return links?.slice(0, slice)?.map(({ capa, id }) => ({
        id,
        capa,
        liked: this.item.isLiked,
        softwares: [],
        views: this.item.views,
        likes: this.item.likes || "0",
        descricao: this.item.descricao,
        aluno: {
          img: this.item.img,
          nome: this.item.aluno,
          ocupacao: this.item.ocupacao,
          conquistas: this.item.conquistasEducando,
          id: this.item.idAluno || this.item.idEducando,
          ext: this.item?.img ? this.item?.img?.split("extensao=")?.pop() : "",
        },
      }));
    },

    isCurrentUser() {
      return Number(this.user.id) === Number(this.item.idAluno);
    },

    isDeleted() {
      return this.removedItems.includes(this.mountKey());
    },

    isNum() {
      const { idComentario, id, isAnswer } = this.item;
      return !isNaN(isAnswer ? idComentario : id);
    },
  },

  methods: {
    scrollTo(id, isAnswer) {
      const collapse = document.getElementById(`message-${id}`);
      const isOpened = collapse.className.includes("show");
      const btnCollapse = document.getElementById(`btn-message-${id}`);
      if (!isAnswer && isOpened) return btnCollapse.click();

      setTimeout(() => {
        const mainElement = document.getElementById("main-scroll");
        mainElement.scrollTo({
          top: collapse.offsetTop - 150,
          behavior: "smooth",
        });
      }, 200);

      if (isAnswer && isOpened) return;

      btnCollapse.click();
    },

    openModal() {
      this.$refs.albumRef0[0]?.openModal();
    },

    visualizar() {
      this.FeedService[
        !this.mountParams().idFeedComment
          ? "incrementarExibicaoFeed"
          : "incrementarExibicaoComentario"
      ](this.mountParams());
    },

    handleLike() {
      this.item.isLiked ? this.unlike(this.item) : this.like(this.item);
    },

    async like(item) {
      if (!this.isNum) return;
      this.handleAction(item, 1);
      const { body } = await this.FeedService[
        !item.idComentario ? "curtirFeed" : "curtirComentario"
      ](this.mountParams());
      this.handleError(body, item, -1);
    },

    async unlike(item) {
      if (!this.isNum) return;
      this.handleAction(item, -1);
      const { body } = await this.FeedService[
        !item.idComentario ? "descurtirFeed" : "descurtirComentario"
      ](this.mountParams());
      this.handleError(body, item, 1);
    },

    handleAction(item, value) {
      item.isLiked = !item.isLiked;
      item.likes = `${Number(item.likes) + value}`;
    },

    handleError(body, item, value) {
      if (!body || body !== "sucesso") {
        item.isLiked = !item.isLiked;
        item.likes = `${Number(item.likes) + value}`;
      }
    },

    async excluir() {
      const actionTitle = this.isChild ? "Comentário" : "Feed";
      const { isConfirmed } = await this.$sweet.sweetQuestionPersonalizado(
        `Deseja excluir o ${actionTitle}?`,
        "<p>Uma vez excluído, não terá como recupera-lo.</p>"
      );
      if (!isConfirmed) return;

      const action = this.isChild ? "removerComentario" : "removerFeed";
      const params = this.mountParams();
      this.$store.dispatch("feed/setExcludedItems", this.mountKey());
      this.FeedService[action](params);
    },

    async responder() {
      const params = {
        ...this.mountParams(),
        comentario: this.validateHtml(this.message),
        mensagem: this.validateHtml(this.message),
      };

      this.message = "";
      const message = this.$parent.newMessage(
        this.item.id,
        params.mensagem,
        true,
        uuidv4()
      );
      const img = message.img;
      message.img = "";
      message.hasImage = false;
      this.inserirMensagem(this.item, message);

      this.$sweet.sweetWait("Estamos inserindo");
      setTimeout(() => this.$sweet.sweetClose(), 10);

      const { body } = await this.FeedService.comentarFeed(params);
      message.idComentario = Number(body);
      message.img = img;
      message.hasImage = true;

      const userService = new UserService(this.$resource);
      userService.setConquistas({ idConquista: 6 });
    },

    mountParams() {
      return {
        idFeed: this.item.id,
        idFeedComment: this.item.idComentario,
      };
    },

    mountKey() {
      return `${this.item.id}-${this.item.idComentario}`;
    },
  },
};
</script>

<style lang="sass">
.v-html
  p, ul, li, span, div, a, h1, h2, h3, h4, h5, h6
    img
      margin-top: 10px
      display: block
      max-width: 50% !important
.album-feed
  width: 25%
  .post-image
    width: 100%
    height: 100% !important
    object-fit: contain !important
    background-color: #111 !important
.card-message
  gap: 15px
  width: 100%
  border-radius: 10px
  strong
    font-weight: 900
  a
    color: #006dd0 !important
    text-decoration: underline !important
  .text-decoration-none
    text-decoration: none !important
  p, span, div, li, ul, a, h1, h2, h3, h4, h5, h6, pre
    color: #646464 !important
.image-card-forum
  img
    width: 100%
    margin-top: 5px
</style>

<style lang="sass">
#v-html
  p, ul, li, span, div, a, h1, h2, h3, h4, h5, h6
    font-size: 14px !important
    img
      max-width: 50% !important
</style>

<style lang="sass" scoped>
.tag-message
  bottom: 0px
  right: -15px
  position: absolute
  transform: scale(.7)
.personal-image
  height: fit-content !important
%ellipses
  width: 10px
  height: 10px
  transition: all .2s
  border-radius: 10px
  background-color: #565656
.content-more-images
  &:hover
    .more-images
      background-color: #999 !important
      &::before, &::after
        background-color: #999 !important
  .more-images
    top: 50%
    margin: auto
    @extend %ellipses
    position: relative
    &::before, &::after
      @extend %ellipses
      content: ''
      position: absolute
    &::before
      left: 25px
    &::after
      right: 25px

.border-left
  border-left: 2px solid rgba(255, 255, 255, 0.15)
  padding-left: 25px
.img-perfil
  border-radius: 50%
  width: 40px
.content-btn
  margin-top: -5px
.btn-pink
  background-color: transparent
  border: 1px solid #ff4163
  border-radius: 3px
  color: #ff4163
  font-size: 10px
  padding: 1px 15px
  text-transform: uppercase
.user-name
  width: 50px
  height: 50px
  border-radius: 50%
  display: inline-flex
  justify-content: center
  align-items: center
  text-transform: uppercase
  font-size: 25px
  padding-top: 3px
  background-color: #222
.user-img
  overflow: hidden
  object-fit: cover
  width: 50px !important
  height: 50px !important
  border-radius: 50% !important
.conquests
  gap: 5px
  margin-bottom: 5px
  border-bottom: 1px solid rgba(256,256,256, .25) !important
  img
    max-width: 25px
    border-radius: 50%
    object-fit: contain
.hide-message
  opacity: 0
  overflow: hidden
  padding: 0px !important
  max-height: 0px !important
  margin-top: 0px !important
.show-message
  transition: all .45s
  max-height: 1000vh
.answer
  cursor: pointer !important
@keyframes loading-image
  0%
    opacity: .1
  50%
    opacity: .25
  100%
    opacity: .1
.images
  gap: 10px

  img, .loading-images
    width: 100%
    height: 350px
  .content-loading
    width: 30% !important
    .loading-images
      background-color: #777
      animation: loading-image 1200ms infinite

@media (max-width: 960px)
  .conquests
    width: 100% !important
    padding-bottom: 5px
    margin-bottom: 10px
    overflow-y: hidden !important
    overflow-x: scroll !important
    border-bottom: none !important
    &::-webkit-scrollbar
      height: 1px
      background: #222 !important
    &::-webkit-scrollbar-thumb
      background: #ff416377
  .bg-black
    background: #000 !important
  .border-left
    padding-left: 15px !important
  .options
    top: 13px
    right: 0px
    position: absolute
  .more-images
    width: 7px !important
    height: 7px !important
    &::before, &::after
      width: 7px !important
      height: 7px !important
    &::before
      left: 15px !important
    &::after
      right: 15px !important
@media (min-width: 500px)
  .v-html
    p, ul, li, span, div, a, h1, h2, h3, h4, h5, h6
      img
        margin-top: 10px
        display: block
        max-width: 100% !important
  #v-html > p, ul, li, span, div, a, h1, h2, h3, h4, h5, h6
      font-size: 14px !important
#v-html > p, ul, li, span, div, a, h1, h2, h3, h4, h5, h6
  font-size: 14px !important
@media (max-width: 400px)
  .album-feed
    width: 55%
@media (max-width: 960px) and (min-width: 400px)
  .album-feed
    width: 35%
</style>
