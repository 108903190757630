<template>
  <section class="pt-4">
    <h5 class="font-16">
      {{ curso.nome }}
    </h5>

    <p class="secondary font-14">
      {{ curso.textoLongoApresentacao }}
    </p>

    <div
      v-if="!isLoading && temporada"
      class="dropdown mt-2"
    >
      <span
        class="btn white bg-transparent dropdown-toggle f-modulo"
        data-toggle="dropdown"
      >
        {{ temporada.titulo }}
      </span>

      <div class="dropdown-menu">
        <button
          v-for="temp in temporadas"
          :key="`${temp.idTemporada}-temporada`"
          class="dropdown-item"
          @click="mudarTemporada(temp)"
        >
          {{ temp.descricao }}
        </button>
      </div>
    </div>

    <hr>

    <h2 class="mb-3 font-18">
      Episódios
    </h2>

    <LoadingComp v-if="isLoading" />

    <div v-else-if="temporada">
      <div
        :key="ind"
        v-for="(ep, ind) in temporada.videos"
        :class="['card-ep mb-4 bg-danger bg-ep', $route.params.video == ep.idVideo ? 'border-right' : '']"
      >
        <div class="row">
          <div class="col-auto pe-0 pt-0 position-relative">
            <LoadingComp
              class="scale"
              v-if="thumbs[ep.idVideo] === 'isLoading'"
            />

            <img
              v-else-if="thumbs[ep.idVideo]"
              :src="thumbs[ep.idVideo]"
              class="image-link pointer bg-black"
              @click="linkTo(ep.idVideo, ep.idTemporadaVideo)"
            >
          </div>

          <div class="col p-2 px-3 pb-0 scroll">
            <div class="mb-3">
              <span class="secondary card-temp me-3 font-12">T{{ formatNumber(temporada.numero) }}EP{{ formatNumber(ep.ordem) }}</span>
              <span class="secondary font-14">{{ ep.duracaoVideo }}min</span>
            </div>
            <h5 :class="['secondary font-14 pb-1 px-1', ep.descricaoVideo ? 'mb-3' : '']">
              {{ mountTitle(ep.tituloVideo) }}
            </h5>
            <p class="secondary font-12">
              {{ ep.descricaoVideo }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      thumbs: {},
      temporada: {}
    }
  },

  created () {
    this.findModule()
    this.loadThumbs()
  },

  methods: {
    mountTitle (titulo) {
      if (titulo.indexOf('-') > -1) return titulo.split('-')[1].trim()
      if (titulo.indexOf(']') > -1) return titulo.split(']')[1].trim()
      return titulo
    },

    linkTo (video, idTemporadaVideo) {
      const cursoId = this.curso.id
      const modulo = this.$route.params.modulo
      this.$router.push({ name: 'video', params: { id: cursoId, modulo, video, idTemporadaVideo } }).catch(e => e)
    },

    formatNumber (num) {
      return Number(num) < 10 ? `0${num}` : `${num}`
    },

    loadThumbs () {
      for (const video of this.temporada?.videos || []) this.getThumb(video)
    },

    async getThumb (video) {
      const videoId = video.idVideo

      if (this.thumbs[videoId]) return
      this.thumbs[videoId] = 'isLoading'
      const route = `plataforma-conteudo/curso/${this.$route.params.id}/thumbnail/video/${video.idVideo}`
      const jwtHeader = { headers: { Authorization: `Bearer ${window.localStorage.getItem('tokenPlataformaEducando')}` } }
      const { data, status } = await axios.get(route, { ...jwtHeader, responseType: 'arraybuffer' }).catch(err => err)
      if (data === 'arquivo inexistente' || status >= 300) return (this.thumbs[videoId] = false)

      await this.$nextTick()
      const typedArray = new Uint8Array(data)
      const stringChar = typedArray.reduce((data, byte) => `${data}${String.fromCharCode(byte)}`, '')
      const thumbs = { ...this.thumbs }
      this.thumbs = {}
      this.thumbs = { ...thumbs, [videoId]: `data:image/png;base64, ${btoa(stringChar)}` }
    },

    mountVideoId (video) {
      const link = video?.linkVideo.replace('https://vimeo.com/manage/videos/', '')
      const id = link.replace('https://vimeo.com/', '')
      return id.split('?').shift().split('/').shift()
    },

    async mudarTemporada (temp) {
      this.temporada = temp
    },

    async findModule () {
      const modulo = Number(this.$route.params.modulo)
      this.temporada = this.temporadas.find(({ idTemporada }) => idTemporada === modulo) || {}
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'configs/isMobile',
      isLoading: 'temporadas/isLoading',
      temporadas: 'temporadas/getTemporadas'
    }),

    curso () {
      return this.$store.getters['cursos/getCurso']
    }
  },

  watch: {
    '$route.params.modulo' () {
      this.findModule()
      this.loadThumbs()
    }
  }
}
</script>
<style lang="sass" scoped>
.scale
  padding-top: 50%
  transform: scale(.5)
img
  width: 100%
.border-right
  border-right: 2px solid #ff4163

h2
  font-size: 22px

::-webkit-scrollbar
  width: 4px
  background: #222 !important
  border-radius: 3px

::-webkit-scrollbar-track
  border-radius: 3px

::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff4163
  max-height: 50px

.scrolling
  overflow: scroll
  max-height: 152px
  overflow-x: hidden

.trailer
  font-size: 18px

hr
  margin-top: 5px !important

.heart
  width: 30px

.card-ep
  max-height: 90px
  overflow: hidden

.scroll
  overflow: auto !important
  max-height: 90px !important

.bg-ep
  background-color: #0e0e0e !important

.bg-dark
  border-radius: 1px
  background-color: #656565 !important

.clock-icon
  width: 18px
  margin-bottom: 5px

.card-temp
  background-color: #252525
  border-radius: 15px
  padding: 2px 10px

.w-icon-mat
  width: 30px

.dropdown
  padding-left: 0px !important

.dropdown-toggle::after
  font-size: 20px !important
  color: #ff4163 !important

ul
  list-style: none
  padding: 0px
  margin-top: 15px

.image-link
  width: 130px
  // height: 90px
  z-index: 99999

@media (max-width: 500px)
  .scroll
    max-height: auto !important

  .image-link
    width: 100%
    height: 100%

  .w-icon-mat
    width: 40px

  .f-modulo
    font-size: 18px !important

  hr
    margin-top: 3px !important

  .card-ep
    max-height: none !important
</style>
