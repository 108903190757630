<template>
  <div class="slider-container">
    <LoadingComp v-if="isLoading" />
    <div v-else class="slider">
      <div class="slides" @scroll="handleScroll">
        <div
          v-for="({ id, img }, ind) in orderedJobs"
          :key="`galery-${ind}-image`"
          :id="`image_${ind}`"
          class="slide"
          @dblclick="likeProject()"
          @click="goToDirectPicture(id)"
        >
          <img
            :src="img"
            :class="[
              'img-post opacity-25',
              isCurrentImage(id) ? 'opacity-100' : '',
            ]"
          />
        </div>
      </div>

      <span
        title="Anterior"
        v-if="hasMoreThanOne"
        class="arrow arrow-left pointer"
        @click="goToPrevious()"
      />

      <span
        title="Próximo"
        v-if="hasMoreThanOne"
        class="arrow arrow-right pointer"
        @click="goToNext()"
      />

      <div class="slider_nav" v-if="hasMoreThanOne">
        <span
          v-for="{ id } in orderedJobs"
          :key="`galery-${id}-links`"
          @click="goToDirectPicture(id)"
          :class="['slider_nav_link pointer', isCurrentImage(id)]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      jobs: [],
      count: 0,
      loadedAlbum: 0,
      isScrolled: true,
      isScrolling: false,
      isLoading: false,
    };
  },

  created() {
    this.getJobs();
  },

  computed: {
    ...mapGetters({ album: "galery/getAlbum" }),

    images() {
      return this.album?.images;
    },

    allJobs() {
      return this.album?.trabalhos;
    },

    orderedJobs() {
      const jobs = [...this.jobs];
      return jobs.sort((a, b) =>
        a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      );
    },

    hasMoreThanOne() {
      return this.album?.trabalhos?.length > 1;
    },
  },

  methods: {
    likeProject() {
      this.$parent.$refs.ModalGaleryHeader.giveLike();
    },

    goToDirectPicture(id) {
      this.isScrolled = false;
      this.goToPicture(id);
    },

    goToPicture(id, isCatch) {
      setTimeout(() => {
        const current = this.getCurrentRoute();
        current.params.image = Number(id);
        this.$router
          .push(current)
          .catch(() => (isCatch ? this.goToPicture(id, true) : ""));
      }, 100);
    },

    async getJobs() {
      const idGalery = this.$route.params.album;
      const idEducando = this.$route.params.user;
      if (!idGalery || idGalery === this.loadedAlbum) return;

      this.jobs = [];
      this.isLoading = true;
      this.loadedAlbum = idGalery;
      this.count = this.allJobs.length;

      this.jobs = this.allJobs.map((job, order) => ({
        order,
        img: this.mountPath(idEducando, idGalery, job.id, job.extensao),
        id: job.id,
        imgExt: job.extensao,
        idGalery: job.idGaleria,
      }));
      this.isLoading = false;
      setTimeout(() => {
        // alert()
        this.goToPicture(this.$route.params.image || this.orderedJobs?.[0]?.id);
        this.position(
          this.$route.params.image || this.orderedJobs?.[0]?.id,
          300
        );
      }, 1000);
    },

    mountPath(idEducando, galeryId, jobId, extensao) {
      return `${this.$space}/postagens/${idEducando}/${galeryId}/${jobId}.${extensao}`;
    },

    isCurrentImage(id) {
      const isCurrentImage = Number(this.$route.params?.image) === Number(id);
      return isCurrentImage ? "active" : "";
    },

    handleScroll(e) {
      const left = Math.ceil(e.target.scrollLeft);
      e.target.childNodes.forEach(({ offsetLeft, id }) => {
        if (left === offsetLeft && id !== this.$route.params.image) {
          if (!this.isScrolled) return (this.isScrolled = true);
          this.isScrolled = true;
          const ind = Number(id.replace("image_", ""));
          const idPicture = this.orderedJobs?.[ind]?.id;

          this.goToPicture(idPicture);
        }
      });
    },

    goToNext() {
      this.isScrolled = false;
      const current =
        this.indice === this.orderedJobs.length - 1 ? 0 : this.indice + 1;
      this.goToPicture(this.orderedJobs?.[current]?.id);
    },

    goToPrevious() {
      this.isScrolled = false;
      const current =
        (this.indice === 0 ? this.orderedJobs.length : this.indice) - 1;
      this.goToPicture(this.orderedJobs?.[current]?.id);
    },

    getCurrentRoute() {
      return { name: this.$route.name, params: { ...this.$route.params } };
    },

    position(id, time = 0, tentativa = 0, recursive) {
      setTimeout(() => {
        if (!id || tentativa > 3 || (this.isScrolling && !recursive)) return;

        const ind = this.orderedJobs.findIndex((job) => job.id === Number(id));
        this.indice = ind;
        const element = document.getElementById(`image_${ind}`);
        if (!element) return this.position(id, 300, tentativa + 1);

        this.isScrolling = true;
        element.scrollIntoView({ behavior: "smooth" }, true);
        const { top, left, bottom, right } = element.getBoundingClientRect();
        const isInViewport = top && left && bottom && right;
        if (!isInViewport) return this.position(id, 300, tentativa + 1, true);
        this.isScrolling = false;
      }, time);
    },
  },

  watch: {
    "$route.params.album": {
      deep: true,
      handler() {
        this.getJobs();
      },
    },

    "$route.params.image": {
      deep: true,
      handler(id) {
        this.position(id);
      },
    },
  },
};
</script>

<style lang="sass" scoped>
::-webkit-scrollbar
  height: 0px
  background: #222 !important
  border-radius: 3px
::-webkit-scrollbar-track
  border-radius: 3px
::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff4163

.slider-container
  user-select: none
  background: black
  display: flex
  align-items: center
  justify-content: center
  height: 100%

  .slider
    width: 100%
    height: 100%
    text-align: center
    overflow: hidden
    position: relative

    .slides
      height: 100%
      display: flex
      overflow-x: scroll
      position: relative
      scroll-behavior: smooth
      scroll-snap-type: x mandatory

      .slide
        display: flex
        justify-content: center
        align-items: center
        flex-shrink: 0
        width: 100%
        height: 100%
        scroll-snap-align: center
        margin-right: 0px
        box-sizing: border-box
        background: black
        transform-origin: center center
        transform: scale(1)
        background: none
        border: none

.arrow-box-right
  right: 5% !important
  transform: translateY(-50%) rotate(-45deg)

.arrow-box-left
  left: 5% !important
  transform: translateY(-50%) rotate(135deg)

.arrow
  z-index: 1
  width: 40px
  height: 40px
  background-color: #272b34
  border-radius: 50%
  display: inline-flex
  flex-direction: row
  justify-content: center
  align-items: center
  position: absolute
  top: calc(50% - 20px)
  &:before
    position: absolute
    content: ""
    border-top: 3px solid #ff4163
    border-left: 3px solid #ff4163
    height: 13px
    width: 13px
    transform: translateX(10%) translateY(10%)

@media (max-width: 600px)
  .arrow
    scale: .65
.slider_nav
  left: 50%
  width: 200px
  bottom: 5px
  position: absolute
  text-align: center
  box-sizing: border-box
  transform: translateX(-50%)
  .slider_nav_link
    width: 7px
    height: 7px
    border-radius: 50%
    margin: 0 5px 0 5px
    display: inline-block
    background-color: rgba(200,200,200,.65)
  .active
    background-color: rgba(255,65,99,1) !important

.img-post
  max-width: 100%
  max-height: 100%
  position: absolute
  top: 50%
  transition: all .5s
  left: 50%
  transform: translateY(-50%) translateX(-50%)
</style>
