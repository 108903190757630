<template>
  <svg
    width="19px"
    height="12px"
    viewBox="0 0 19 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >

    <g
      id="Icons"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Rounded"
        transform="translate(-884.000000, -2464.000000)"
      >
        <g
          id="Hardware"
          transform="translate(100.000000, 2404.000000)"
        >
          <g
            id="-Round-/-Hardware-/-keyboard_return"
            transform="translate(782.000000, 54.000000)"
          >
            <g>
              <rect
                id="Rectangle-Copy-110"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M19,8 L19,11 L5.83,11 L8.71,8.12 C9.1,7.73 9.1,7.1 8.71,6.71 C8.32,6.32 7.69,6.32 7.3,6.71 L2.71,11.3 C2.32,11.69 2.32,12.32 2.71,12.71 L7.3,17.3 C7.69,17.69 8.32,17.69 8.71,17.3 C9.1,16.91 9.1,16.28 8.71,15.89 L5.83,13 L20,13 C20.55,13 21,12.55 21,12 L21,8 C21,7.45 20.55,7 20,7 C19.45,7 19,7.45 19,8 Z"
                id="🔹-Icon-Color"
                fill="#ff4163"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ReturnIcon'
}
</script>
