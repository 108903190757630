export default class usuarioService {
  constructor (resource) {
    const acoesCustomizadas = {
      lives: { method: 'GET', url: 'plataforma-conteudo/geral/live' },
      parceiros: { method: 'GET', url: 'plataforma-conteudo/geral/parceiro' },
      conquistas: { method: 'GET', url: 'plataforma-conteudo/geral/conquista' },
      arquivos: { method: 'GET', url: 'plataforma-conteudo/geral/arquivos-plataforma' },
      savePesquisa: { method: 'POST', url: 'plataforma-conteudo/pesquisa{/id}/resposta' },
      getPesquisas: { method: 'GET', url: 'plataforma-conteudo/pesquisa/abertas-nao-respondidas' },
      conquistasEducandos: { method: 'GET', url: 'plataforma-conteudo/educando{/idEducando}/conquistas' }
    }
    this.resource = resource('', {}, acoesCustomizadas)
  }

  conquistasEducandos (dados) {
    return this.resource
      .conquistasEducandos(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  arquivos (dados) {
    return this.resource
      .arquivos(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  getPesquisas () {
    return this.resource
      .getPesquisas()
      .then(res => res, err => err)
  }

  savePesquisa (dados) {
    return this.resource
      .savePesquisa(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  conquistas (dados) {
    return this.resource
      .conquistas(dados, { emulateJSON: true })
      .then(res => res, err => err)
  }

  parceiros (dados) {
    return this.resource
      .parceiros(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  lives (dados) {
    return this.resource
      .lives(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }
}
