const SET_STATE = 'SET_STATE'

const state = {
  foruns: [],
  topics: [],
  conquistasEducandos: [],
  isLoading: false
}

const actions = {
  setForuns ({ commit }, foruns) {
    commit(SET_STATE, { foruns })
  },
  setTopics ({ commit, state }, topics) {
    commit(SET_STATE, { topics: [...topics, ...state.topics] })
  },
  setConquistasEducandos ({ commit }, conquistas) {
    commit(SET_STATE, { conquistasEducandos: conquistas })
  },
  setIsLoading ({ commit }, isLoading) {
    commit(SET_STATE, { isLoading })
  }
}

const getters = {
  getConquistasEducandos: (state) => {
    return state.conquistasEducandos
  },
  getForuns: (state) => {
    return state.foruns
  },
  getTopics: (state) => {
    return state.topics
  },
  isLoading: (state) => {
    return state.isLoading
  }
}

const mutations = {
  [SET_STATE] (state, foruns) {
    Object.assign(state, foruns)
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
