<template>
  <Modal
    :id="id"
    v-if="detalhes"
    classe="pe-2"
  >
    <div class="pt-2 scrolling-modulos">
      <p class="pb-3 dark-grey-modal font-14">
        {{ detalhes.descricao }}
      </p>

      <div class="informacoes">
        <ul
          class="list-modal"
          v-if="detalhes && detalhes.informacoes"
        >
          <li
            class="font-14 secondary mt-1"
            v-for="(item, ind) in Object.keys(detalhes.informacoes)"
            :key="`infos-${ind}`"
          >
            <component
              :is="icons[item]"
              class="me-2"
            />

            {{ detalhes.informacoes[item] }}
          </li>
        </ul>
      </div>

      <div class="bonus pt-4">
        <h4 class="pink upper font-18">
          Bônus
        </h4>
        <ul class="list-modal">
          <li
            v-for="(bonus, indDetalheBonus) in detalhes.bonus"
            :key="`${bonus}-bonus-${indDetalheBonus}`"
            class="secondary font-14"
          >
            - {{ bonus }}
          </li>
        </ul>
      </div>

      <div class="modulos mt-3">
        <h4
          class="pink upper mb-2 font-18"
          data-toggle="collapse"
          data-target="#collapse-modal"
          @click="isOpen = !isOpen"
        >
          {{ isOpen ? '-' : '+' }} Módulos do curso
        </h4>
        <div
          class="collapse show p-2"
          id="collapse-modal"
        >
          <div
            v-for="(modulo, indModulos) in detalhes.modulos"
            :key="`collapse-modal-${indModulos}-${modulo.titulo}`"
          >
            <h6 class="mb-1 white">
              {{ modulo.titulo }}
            </h6>
            <div class="items pb-3">
              <ul class="list-modal">
                <li
                  v-for="(bonus, indBonus) in modulo.items"
                  :key="`${bonus}-${indBonus}`"
                  class="secondary font-14"
                >
                  - {{ bonus }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import ClockIcon from '@/assets/icons/Clock'
import WhatsAppIcon from '@/assets/icons/WhatsApp'
import Modal from '@/components/shared/modal/Modal'
import CheckCircleIcon from '@/assets/icons/CheckCircle'
import UserGraduateIcon from '@/assets/icons/UserGraduate'
import FacebookCircleIcon from '@/assets/icons/FacebookCircle'
import ChalckboardTeacherIcon from '@/assets/icons/ChalckboardTeacher'

export default {
  props: {
    detalhes: {},
    id: {}
  },

  components: {
    ClockIcon,
    ChalckboardTeacherIcon,
    UserGraduateIcon,
    FacebookCircleIcon,
    CheckCircleIcon,
    Modal
  },

  methods: {
    split (position, item, key) {
      return item?.[key]?.split(':')[position] || ''
    }
  },

  data () {
    return {
      isOpen: true,
      icons: {
        whatsApp: WhatsAppIcon,
        cargaHoraria: ClockIcon,
        garantia: CheckCircleIcon,
        facebook: FacebookCircleIcon,
        aulas: ChalckboardTeacherIcon,
        certificado: UserGraduateIcon
      }
    }
  }
}
</script>
<style lang="sass" scoped>
::-webkit-scrollbar
  width: 3px
  background: #222 !important
  border-radius: 3px
::-webkit-scrollbar-track
  border-radius: 3px
::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff4163
  max-height: 50px
.scrolling-modulos
  max-height: 85vh
  overflow: scroll
  overflow-x: hidden

.dark-grey-modal
  font-size: 16px
  color: #6b6b6b
.list-modal
    margin: 0px
    padding: 0px
    list-style: none
.informacoes
  svg
    fill: #ff4163
</style>
