<template>
  <svg
    width="576"
    height="512"
    viewBox="0 0 576 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M549.655 124.083C543.374 100.433 524.868 81.807 501.371 75.486C458.781 64 288 64 288 64C288 64 117.22 64 74.629 75.486C51.132 81.808 32.626 100.433 26.345 124.083C14.933 166.95 14.933 256.388 14.933 256.388C14.933 256.388 14.933 345.826 26.345 388.693C32.626 412.343 51.132 430.193 74.629 436.514C117.22 448 288 448 288 448C288 448 458.78 448 501.371 436.514C524.868 430.193 543.374 412.343 549.655 388.693C561.067 345.826 561.067 256.388 561.067 256.388C561.067 256.388 561.067 166.95 549.655 124.083V124.083ZM232.145 337.591V175.185L374.884 256.39L232.145 337.591V337.591Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'YoutubeIcon'
}
</script>

<style lang="sass" scoped>
svg
  width: 15px
  height: 15px
</style>
