<template>
  <div :class="['button-open pointer p-2 pt-1', isOpen ? 'mb-2' : '']">
    <div v-if="user.extensaoImagem" class="row align-items-end">
      <div :class="['col-auto', isOpen && 'pe-0']">
        <img
          :class="[
            !isOpen ? 'photo-profile' : 'photo-profile-open',
            'with-events',
          ]"
          :src="mountImage(user)"
          data-toggle="modal"
          data-target="#modalImageProfile"
          @error="setError"
          v-if="!errorImg"
        />

        <div
          v-else
          :class="!isOpen ? 'photo-profile' : 'photo-profile-open'"
          data-toggle="modal"
          data-target="#modalImageProfile"
        >
          {{ user.nome.charAt(0) }}
        </div>
      </div>

      <div v-if="isOpen" class="col text-overflow">
        <p class="font-18">{{ user.nome }}</p>
        <p class="font-12">
          {{ user.email }}
        </p>
      </div>
    </div>

    <div
      class="image-work bold"
      data-toggle="modal"
      data-target="#modalImageProfile"
      v-else
    >
      {{ user.nome ? user.nome[0] : "" }}
      <span class="add"> + </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
    }),
  },

  data() {
    return {
      errorImg: false,
    };
  },

  methods: {
    setError() {
      this.errorImg = true;
    },

    mountImage({ extensaoImagem, id }) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=educando&extensao=${extensaoImagem}&identificador=${id}`;
    },
  },
};
</script>
<style lang="sass" scoped>
%profile
  width: 50px
  height: 50px
  transition: all .25s
  font-size: 25px
  display: inline-flex
  flex-direction: row
  justify-content: center
  align-items: center
  object-fit: cover
  border-radius: 50%
  background-color: #151515
.image-work, .photo-profile
  @extend %profile
.photo-profile-open
  @extend %profile
  width: 70px
  height: 70px
.text-overflow
  margin-top: 5px
  position: relative
  p
    overflow: hidden
    max-width: 150px
    white-space: nowrap
    text-overflow: ellipsis
.image-work
  position: relative
  overflow: hidden
  .add
    text-align: center
    position: absolute
    bottom: 0
    background-color: #1f1f1f
    opacity: .65
    font-size: 15px
    height: 15px
    padding-bottom: 20px
    width: 100%
.button-open
  transition: all 1s
  z-index: 9
  display: inline-flex
  flex-direction: row
  justify-content: center !important
  align-items: center !important
  .icon
    width: 25px
    height: 25px
  .image
    width: 55px
    height: 55px
    border-radius: 50%

@media (max-width: 500px)
  .photo-profile
    width: 30px !important
    height: 30px !important
  .button-open
    top: 5vh
    .icon
      width: 15px
      height: 15px
    .image
      width: 35px
      height: 35px
</style>
