const lives = (body) =>  body?.lives;
const course = (body) =>  body?.cursos;
const parceiros = (body) => body?.parceiros;
const campanhas = (body) => body?.campanhas;
const geral = (body) => body?.listagemGeral;
const dadosCurso = (body) => body?.dadosCurso;
const conquests = (body) =>  body?.conquistas;
const linksUteis = (body) => body?.linksUteis;
const arquivos = (body) => body?.arquivosPlataforma;
const aptidao = (body) => body?.conquistasDadosUteis;
const notificacoes = (body) =>  {
    return body?.notificacoes?.map(i => {
        const dadosDirecionamento = i.dadosFeed || i.dadosGaleria || i.dadosTopicoForum || i.dadosComentarioVideo || []
        return { ...i, dadosDirecionamento }
    })
};
const meusDados = (body) =>  body?.dadosPerfil?.[0];
const temporadaVideos = (body) =>  {
    const { temporadaVideos } = body

    return temporadaVideos?.map(i => {
        i.idTemporada = i.id
        i.videos = i.videos.map(v => {
            return { 
                ...v, 
                idTemporadaVideo: v.id, 
                thumbnailVideo: v.extensaoThumbnailVideo,
                isPro: i.acesso === "P",
            }
        })
        return i
    })
};
const dadosPerfil = (body) => {
    const { dadosPerfil } =  body;
    const [dados] = dadosPerfil;
    
    const dadosEducando = [dados]
    const softwares = dados.softwares;
    const conquistas = dados.conquistas;
    const seguindo = [{ totalSeguindo: dados.totalSeguindo }]
    const seguidores = [{ totalSeguidores: dados.totalSeguidores }]
    const horasConteudoConsumido = dados.totalHorasConteudoConsumido
    const curtidasGaleria = [{ totalCurtidasGalerias: dados.totalCurtidasGalerias }]
    const exibicoesGaleria = [{ totalExibicoesGalerias: dados.totalExibicoesGalerias }]
    
    return {
        dadosEducando,
        exibicoesGaleria,
        curtidasGaleria,
        seguindo,
        seguidores,
        conquistas,
        softwares,
        horasConteudoConsumido,
    }
};
const certificados = (body) => body?.certificados;
const listagemSeguindo = (body) => body?.listagemSeguindo;
const euSigoEsseEducando = (body) =>  body?.euSigoEsseEducando;
const listaEducandosNaoSigo = (body) =>  body?.listaEducandosNaoSigo;
const comentariosTemporadaVideo = (body) =>  body?.comentariosTemporadaVideo;
const listaEducandosFiltrandoNome = (body) =>  body?.listaEducandosFiltrandoNome?.map(i => ({ ...i, seguindo: i.jaSigoEsseEducando}));

const urlsToRedirectGoApi = {
    "plataforma-conteudo/curso": course,
    "plataforma-conteudo/geral/live": lives,
    "plataforma-conteudo/curso{/id}": dadosCurso,
    "plataforma-conteudo/geral/parceiro": parceiros,
    "plataforma-conteudo/geral/conquista": conquests,
    "plataforma-conteudo/curso{/id}/link-util": linksUteis,
    "plataforma-conteudo/educando{/id}/perfil": dadosPerfil,
    "plataforma-conteudo/geral/arquivos-plataforma": arquivos,
    "plataforma-conteudo/geral/campanha/tipo{/id}": campanhas,
    "plataforma-conteudo/educando/sessao/meus-dados": meusDados,
    "plataforma-conteudo/educando/sessao/conquistas": conquests,
    "plataforma-conteudo/educando/sessao/certificados": certificados,
    "plataforma-conteudo/educando{/idEducando}/conquistas": conquests,
    "plataforma-conteudo/feed/geral/listar{/qtd}/pulando{/skip}": geral,
    "plataforma-conteudo/curso{/id}/temporadas-videos": temporadaVideos,
    "plataforma-conteudo/feed/seguindo/listar{/qtd}/pulando{/skip}": listagemSeguindo,
    "plataforma-conteudo/educando/sessao/conquistas-dados-uteis-validar-aptidao": aptidao,
    "plataforma-conteudo/comunidade/notificacao/listar{/qtd}/pulando{/skip}": notificacoes,
    "plataforma-conteudo/educando/sessao/seguidor/eu-sigo-esse-educando{/id}": euSigoEsseEducando,
    "plataforma-conteudo/educando/sessao/seguidor/listar-aqueles-que-nao-sigo": listaEducandosNaoSigo,
    "plataforma-conteudo/educando/sessao/seguidor/listar-educandos-filtrando-nome{/filtro}": listaEducandosFiltrandoNome,
    "plataforma-conteudo/comunidade/comentario-temporada-video{/idTemporadaVideo}/comentarios": comentariosTemporadaVideo,
}

export default (request) => {
    if (request.method !== "GET") return null;

    // console.log(request.url);
    // console.log(request.body);
    return urlsToRedirectGoApi[request.url];
}