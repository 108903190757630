<template>
  <div class="box-journey d-inline-flex p-2 position-relative w-100 mx-0">
    <div class="clock">
      <img src="@/assets/images/clock.png">
    </div>

    <div class="hours col">
      <h1>{{ horasEducando.horas }}hs</h1>
      <p :class="['secondary font-12 text-start', menuIsOpen ? 'ms-0' : 'ms-4']">
        de conteúdo assistido
      </p>

      <p :class="['start-journey secondary position-absolute font-12 dark-grey-2', menuIsOpen ? 'line-height-12' : '']">
        Sua jornada conosco começou em {{ horasEducando.dia }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    horasEducando: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    ...mapGetters({
      menuIsOpen: 'configs/menuIsOpen'
    })
  }
}
</script>

<style lang="sass" scoped>
.box-journey
  border-radius: 15px
  border: 1px solid #3b3e42
  padding-top: 10px !important
  padding-bottom: 15px !important
  .clock
    padding-right: 10px
    img
      width: 80px
      filter: contrast(0.3)

  .hours
    h1
      margin-top: 5px
      font-size: 35px
    p
      margin-top: -8px

  .start-journey
    bottom: 13px
    left: 15px
  .line-height-12
    line-height: 12px
</style>
