<template>
  <div class="bg-black">
    <div class="px-4 px-md-0 col-11 col-md-10 mt-3 mt-md-0 pt-2 m-auto">
      <div class="row justify-content-center mt-1">
        <div class="col-6 pe-1">
          <button
            @click="escolherAula(-1)"
            class="btn bg-btn-pink font-14 mb-1"
          >
            <Chevron class="chevron me-1 chevron-left" />
            <div class="label">
              {{ labels.previousLabel }}
            </div>
          </button>
        </div>

        <div class="col-6 ps-1">
          <button @click="escolherAula(1)" class="btn bg-btn-pink mb-1 font-14">
            <div class="label">
              {{ labels.nextLabel }}
            </div>
            <Chevron class="chevron ms-1 chevron-right" />
          </button>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12">
          <button
            :disabled="isPro"
            id="persistir-video"
            @click="persistVideo()"
            class="btn btn-verde dark-grey-2 font-14"
          >
            <div :class="['check', isLoading ? 'dashed' : 'solid']">
              <div class="fill-check" v-if="isChecked" />
            </div>
            {{ labels.concluir }}
          </button>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chevron from "@/assets/icons/Chevron";

export default {
  components: { Chevron },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      isMobile: "configs/isMobile",
      user: "registration/getUser",
      allVideos: "temporadas/getAllVideos",
    }),

    currentVideo() {
      return Number(this.$route.params.video);
    },

    isChecked() {
      return this.allVideos.some(
        ({ visto, id }) => visto && id === this.currentVideo
      );
    },

    isPro() {
      return this.allVideos.some(
        ({ isPro, id }) => isPro && id === this.currentVideo && !this.user.isPro
      );
    },

    labels() {
      const largura = window.screen.width;
      const isSmall = largura > 1100 && largura > 750;
      const nextLabel = isSmall ? "Próxima aula" : "Próxima";
      const previousLabel = isSmall ? "Aula anterior" : "Anterior";

      const concluir =
        largura < 950 && largura > 750 ? "Concluir" : "Marcar como concluída";
      return { nextLabel, previousLabel, concluir };
    },
  },

  methods: {
    persistVideo() {
      if (this.isPro) return;

      this.isLoading = true;
      this.$root.persistVideo(this.isChecked);
    },

    escolherAula(direction) {
      this.$store.dispatch("temporadas/escolherVideo", direction);
    },
  },

  watch: {
    isChecked: {
      deep: true,
      handler() {
        this.isLoading = false;
      },
    },
  },
};
</script>
<style lang="sass" scoped>
hr
  margin: 10px 0px !important
.solid
  border: 1px solid #009a00

.dashed
  transform: rotate(90deg)
  border: 1px dashed #009a00
  animation: rotate 2000ms infinite

@keyframes rotate
  0%
    transform: rotate(0deg)

  50%
    transform: rotate(180deg)

  100%
    transform: rotate(0deg)

.check
  width: 14px
  height: 14px
  padding: 2px
  overflow: hidden
  border-radius: 50%
  margin-right: 10px
  .fill-check
    background-color: #009a00
    width: 100%
    height: 100%
    border-radius: 50%

.btn-verde
  border-radius: 5px !important
  padding: 0px 10px !important
  background-color: transparent
  border: 1px solid #646464
  width: 100%
  display: inline-flex
  flex-direction: row
  justify-content: center
  align-items: center
  &:hover
    color: white !important
.btn
  width: 100%
.zoom-negative
  transform: scale(.8)
.chevron-left
  transform: rotate(-90deg)
.chevron-right
  transform: rotate(90deg)
.bg-btn-pink
  display: inline-flex
  justify-content: center
  align-items: center
  padding: 0px !important
.label
  line-height: 16px
  padding-top: 2px
@media (max-width: 600px)
  .bg-btn-pink
    transform: scale(1) !important
  .bg-black
    background-color: #000 !important
</style>
