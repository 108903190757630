<template>
  <section class="row scroll mx-0 mx-md-auto">
    <div class="col-md-9 col-12 pe-0 bg-dark pb-md-4 pb-1 ps-0 min-vh-100">
      <Video
        v-if="video"
        class="pb-4"
        :video="video"
        :video-loading="videoLoading"
      />

      <h5 class="ms-md-3 ms-0 title title-bordered">
        {{ curso.nome }}
      </h5>
    </div>

    <div class="col-md-3 col-12 bg-dark-mobile px-3">
      <DocContent v-if="video && curso.quantidadeTemporadas > 0 && hasTemp" />
    </div>
  </section>
</template>

<script>
import DocContent from './DocContent'
import UserService from '@/service/userService'
import CursoService from '@/service/cursoService'
import Video from '@/components/shared/video/Video'

export default {
  name: 'DocumentarieComponent',

  components: {
    Video,
    DocContent
  },

  data () {
    return {
      video: {},
      videoLoading: true
    }
  },

  computed: {
    curso () {
      return this.$store.getters['cursos/getCurso']
    },
    hasTemp () {
      return this.$store.getters['temporadas/getTemporadas'].length
    }
  },

  async created () {
    this.UserService = new UserService(this.$resource)
    this.CursoService = new CursoService(this.$resource)

    this.getVideo()
    this.$root.persistVideo = this.persistVideo
  },

  methods: {
    async getVideo () {
      this.video = {}
      const currentVideo = this.$route.params.video
      if (!currentVideo || currentVideo === 'search' || currentVideo === '-1') return

      this.videoLoading = true
      const { body } = await this.CursoService.cursoVideo({ id: this.$route.params.video })
      this.videoLoading = false
      this.video = body
    },

    async persistVideo () {
      const { body: idVinculo } = await this.UserService.openCourse({ id: this.$route.params.id })
      this.UserService.watchVideo({ idVinculo, idVideo: this.$route.params.idTemporadaVideo })
    }
  },

  watch: {
    '$route.params.video' () {
      this.getVideo()
    }
  }
}
</script>
<style lang="sass">
@media (max-width: 600px)
  .min-vh-100
    min-height: auto !important
.white
  &:hover
    color: #aaa
hr
  border: 1px solid #888 !important
  background-color: #888
</style>
