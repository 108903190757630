<template>
  <section class="row justify-content-center py-3 py-md-5 px-3 px-md-0 align-items-end min-height">
    <div class="col-md-4 px-4 px-md-5">
      <form
        @submit.prevent="register()"
        class="d-flex f-column item-center content-center"
      >
        <HeaderEmpresas />

        <InputKey
          v-model="key"
          class="mb-4"
        />

        <div
          v-if="key.id && key.code"
          class="enter-effect w-100"
        >
          <InputEmpresa
            v-model="form.nome"
            label="Nome completo"
            type="text"
            placeholder="Digite o seu nome completo"
          />

          <InputEmpresa
            v-model="form.cpf"
            label="CPF"
            type="text"
            mask="###.###.###-##"
            placeholder="Informe seu CPF"
          />

          <InputEmpresa
            v-model="form.empresa"
            label="Empresa onde trabalha"
            type="text"
            placeholder="Digite a empresa onde trabalha"
          />

          <InputEmpresa
            v-model="form.email"
            label="Seu email"
            type="email"
            placeholder="Digite o email que usará para acessar a plataforma"
          />

          <InputEmpresa
            type="email"
            label="Confirme seu email"
            v-model="form.confirmarEmail"
            :is-invalid="isInvalidEmail"
            placeholder="Digite novamente o email que usará para acessar a plataforma"
          />

          <InputEmpresa
            type="password"
            v-model="form.senha"
            placeholder="Digite uma senha que usará para acessar a plataforma"
            label="Crie uma senha (sua senha deve conter pelo menos 8 caracteres)"
          />

          <InputEmpresa
            type="password"
            label="Confirme sua senha"
            v-model="form.confirmarSenha"
            :is-invalid="isInvalidSenha"
            placeholder="Digite novamente a senha que usará para acessar a plataforma"
          />

          <div class="px-5 w-100 mb-4">
            <button
              class="bg-btn-pink btn mt-3 upper dark-grey"
              :disabled="!preenchido"
            >
              Enviar
            </button>
          </div>
        </div>

        <div
          v-else
          class="height-50vh"
        />

        <p class="font-14 dark-grey-2 text-center">
          Em caso de dúvidas, entre em contato por email: <a
            class="dark-grey-2"
            :href="`mailto:${link}`"
          >{{ link }}</a>
        </p>
      </form>
    </div>
  </section>
</template>

<script>
import InputKey from './InputKey'
import InputEmpresa from './InputEmpresa'
import HeaderEmpresas from './HeaderEmpresas'
import Service from '@/service/empresasService'

export default {
  name: 'LoginComp',

  components: {
    InputKey,
    InputEmpresa,
    HeaderEmpresas
  },

  data () {
    this.ResourceEmpresa = new Service(this.$resource)
    return {
      link: 'contato@projetojovemarquiteto.com.br',
      key: {
        id: '',
        code: ''
      },

      form: {
        cpf: '',
        nome: '',
        senha: '',
        email: '',
        empresa: '',
        confirmarEmail: '',
        confirmarSenha: ''
      }
    }
  },

  methods: {
    async register () {
      const splitedName = this.form.nome.split(' ')
      const nome = splitedName[0].trim()
      delete splitedName[0]
      const sobrenome = splitedName.join(' ').trim()

      const paramsRegister = {
        nome,
        sobrenome,
        codigo: this.key.code,
        idCodigo: this.key.id,
        cpf: this.form.cpf,
        email: this.form.email,
        senha: this.form.senha
      }
      const { codigo, idCodigo, cpf, email, senha } = paramsRegister
      if (!nome || !sobrenome || !codigo || !idCodigo || !cpf || !email || !senha) return

      this.$sweet.sweetWait('Aguarde enquanto realizamos o cadastro')
      const { status } = await this.ResourceEmpresa.registrarAluno(paramsRegister)

      if (status === 202) {
        this.$sweet.sweetSuccess('Registrado')
        this.$router.push({ name: 'login' })
        return
      }

      this.$sweet.sweetError()
    }
  },

  computed: {
    isInvalidSenha () {
      const hasConfirmSenha = Boolean(this.form.senha && this.form.confirmarSenha)
      if (!hasConfirmSenha) return false
      if (this.form.senha.length < 8) return true
      return Boolean(this.form.senha !== this.form.confirmarSenha)
    },

    isInvalidEmail () {
      const hasConfirmEmail = Boolean(this.form.confirmarEmail)
      if (!hasConfirmEmail) return false

      return Boolean(this.form.email !== this.form.confirmarEmail)
    },

    preenchido () {
      const hasCPF = Boolean(this.form.cpf)
      const hasNome = Boolean(this.form.nome)
      const hasEmpresa = Boolean(this.form.empresa)
      const hasEmail = Boolean(this.form.email && this.form.confirmarEmail)
      const hasSenha = Boolean(this.form.senha && this.form.confirmarSenha)

      return Boolean(hasCPF && hasNome && hasEmpresa && hasEmail && hasSenha && !this.isInvalidSenha && !this.isInvalidEmail)
    }
  }
}
</script>

<style lang="sass" scoped>
button
  width: 100%
  padding: 5px !important
  font-size: 18px !important
  border-radius: 10px !important

.container-fluid
  padding: 0px !important
.d-flex
  display: inline-flex
.f-column
  flex-direction: column
.item-center
  justify-content: center
.content-center
  align-items: center
.bg-chale
  opacity: .5
  background-size: cover
  background-position: bottom center
  background-image: url('../../../assets/images/chale.png')
.height-50vh
  height: 30vh
.min-height
  min-height: 100vh
@media (max-width: 500px)
  .bg-chale
    display: none
  .bg-btn-pink
    transform: scale(1) !important
  .min-height
    min-height: 75vh
</style>
