<template>
  <svg
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M455.1 137.9L422.7 170.3L341.7 89.2L374.1 56.8C380.7 50.2 392.2 50.2 398.8 56.8L455.1 113.2C461.9 120 461.9 131.1 455.1 137.9ZM184.4 408.9L103.4 327.8L312.8 118.1L393.8 199.2L184.4 408.9V408.9ZM84.7 366.9L145.3 427.6L60.9 451.4L84.7 366.9ZM484 84.3L427.7 27.9C416.7 16.9 377 -3.9 345.3 27.9L60 313.4C57.5 315.9 55.7 318.9 54.8 322.3L11.8 475.4C9.8 482.5 11.9 490.1 17 495.4C22.2 500.7 32.6 501.6 37 500.6L190 457.5C193.4 456.6 196.4 454.8 198.9 452.3L484 166.8C506.7 144.1 506.7 107.1 484 84.3V84.3Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'PenIcon'
}
</script>

<style lang="sass" scoped>
svg
  width: 15px
  height: 15px
  margin-bottom: 5px
  margin-left: 5px
</style>
