<template>
  <div class="position-relative border-right notificacoes">
    <div
      :class="[
        'bg-opacity-50 bg-black width-100 height-100vh position-absolute',
        isOpen ? 'sombra' : 'sombra-closed',
      ]"
      @click="closeCollapse()"
    />
    <div
      :class="[
        'position-absolute notifications-content bg-black-notificacoes',
        isOpen ? 'open-right-collapse' : 'close-right-collapse',
      ]"
    >
      <Loading v-if="isLoading" class="mt-5" />
      <!-- @scroll="scroll()" -->
      <div
        class="scroll py-4 px-3 pt-3"
        v-else-if="!isLoading && notificacoes.length && isOpen"
      >
        <h6 class="text-start font-18 mb-4 dark-grey-2">Notificações</h6>

        <div
          class="row mb-3 pointer"
          @click="goTo(item.linkTo)"
          :key="`notificacao-${ind}`"
          v-for="(item, ind) in notificacoes"
          v-show="mountName(item.nome)"
        >
          <div class="col-auto pe-0">
            <div class="img-perfil bg-black border-radius-50">
              <img
                v-if="item.img && !list.includes(item.id)"
                :src="item.img"
                @error="setErrorImg(item)"
              />
              <div v-else class="bg-black font-20">
                {{ mountName(item.nome) }}
              </div>
              <!-- {{ item }} -->
              <div
                :class="[
                  'status-tag position-absolute',
                  item.assinatura === 'P' ? 'bg-pink' : 'bg-free',
                ]"
              />
            </div>
          </div>

          <div class="col hovered">
            <p class="bold white text-start font-14">
              {{ item.nome }}
            </p>
            <p class="dark-grey-2 text-start font-14">
              {{ item.acao }}. {{ item.tempo }}
            </p>
          </div>
        </div>
      </div>

      <h6 v-else class="text-center dark-grey-2 mt-5">Nenhuma notificação</h6>
    </div>
  </div>
</template>

<script>
import Mixins from "@/Mixins";
import Loading from "../Loading.vue";
import { mapGetters, mapActions } from "vuex";
import NotificacoesResource from "@/service/notificationsService";

const POST_ENTITIES = ["galeria.tpl_galeria_curtidas"];
const FOLLOW_ENTITIES = ["educando.tpl_educando_seguidores"];
const GALERY_ENTITIES = [
  "comunidade.postagem_comentarios",
  "galeria.trabalho_comentarios",
  "galeria.tpl_galeria_curtidas",
  "comunidade.tpl_postagem_curtidas",
];
const TOPICS_ENTITIES = [
  "comunidade.tpl_mensagem_topico_curtidas",
  "comunidade.mensagens_topico",
];
const VIDEO_ENTITIES = [
  "comunidade.tpl_video_comentario_curtidas",
  "comunidade.video_comentarios",
];

export default {
  components: { Loading },

  name: "NotificacoesComp",

  mixins: [Mixins],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      list: [],
      qtd: 40,
      skip: 40,
      direction: "down",
    };
  },

  created() {
    this.NotificacoesResource = new NotificacoesResource(this.$resource);
    this.setIsLoading(true);
    this.loadNotifications(0);

    const threeMinutes = 3 * 60 * 1000;
    setInterval(() => this.loadNotifications(0), threeMinutes);
  },

  methods: {
    scroll() {
      const alturaScroll = document.getElementById("area-follower");

      const { clientHeight, scrollHeight, scrollTop } = alturaScroll;
      const position = clientHeight + scrollTop;
      this.direction = position >= scrollHeight - 50 ? "up" : "down";
    },

    ...mapActions({
      setNotificacoes: "notificacoes/setNotificacoes",
      setIsLoading: "notificacoes/setIsLoading",
    }),

    setErrorImg(item) {
      this.list = [...this.list, item.id];
    },

    mountName([name]) {
      return name?.toUpperCase();
    },

    closeCollapse() {
      this.$emit("closeCollapse", true);
    },

    async loadNotifications(skip) {
      const { body } = await this.NotificacoesResource.get({
        skip: skip,
        qtd: this.qtd,
      });

      this.setIsLoading(false);

      if (body) {
        this.setNotificacoes(
          body.map((item) => ({
            ...item,
            id: item.idNotificacao,
            acao: item.tipoInteracao,
            readed: item.status === "N",
            linkTo: this.mountLink(item),
            nome: item.nomeEducandoInteragiu,
            tempo: this.$f.timeAgo(item.createdAt),
            img: this.mountImagePath(
              "educando",
              item.idEducandoInteragiu,
              item.extensaoImagem
            ),
          }))
        );
      }
    },

    mountLink(item) {
      const {
        dadosDirecionamento: [dir],
        pathEntidade,
        idEducandoInteragiu,
        idEntidade,
      } = item;
      if (TOPICS_ENTITIES.includes(pathEntidade)) {
        return {
          name: "topico",
          params: {
            forum: dir.idForum,
            tipo: dir.idForumTipo,
            topico: dir.idTopico,
          },
        };
      }

      if (VIDEO_ENTITIES.includes(pathEntidade) && dir) {
        return {
          name: "video",
          params: {
            id: dir.idCurso,
            modulo: dir.idTemporada,
            video: dir.idVideo,
            idTemporadaVideo: dir.idTemporadaVideo,
          },
        };
      }

      if (FOLLOW_ENTITIES.includes(pathEntidade)) {
        return { name: "perfil", params: { user: idEducandoInteragiu } };
      }

      if (POST_ENTITIES.includes(pathEntidade)) {
        return {
          name: "perfil",
          params: { user: idEducandoInteragiu, album: idEntidade },
        };
      }

      if (GALERY_ENTITIES.includes(pathEntidade)) {
        return {
          name: "perfil",
          params: { user: idEducandoInteragiu, album: idEntidade },
        };
      }

      return null;
    },

    goTo(link) {
      this.closeCollapse();
      if (this.routeName === link?.name) {
        if (
          link.name === "topico" &&
          link.params.tipo === this.$route.params.tipo &&
          link.params.forum === this.$route.params.forum &&
          link.params.topico === this.$route.params.topico
        )
          return;

        if (
          link.name === "video" &&
          Number(link.params.forum) === Number(this.$route.params.forum)
        )
          return;
      }

      this.$router.push(link);
    },

    async setReads(notificacao) {
      const { status } = await this.NotificacoesResource.update(notificacao);
      if (status >= 300) return;
      notificacao.readed = true;
    },
  },

  watch: {
    direction: {
      handler(direction) {
        if (direction === "up" && !this.isLoading) {
          this.loadNotifications(this.skip);
          this.skip = this.qtd + this.skip;
        }
      },
    },

    isOpen: {
      async handler(isOpen) {
        if (isOpen) {
          const notifications = this.notificacoes.filter(
            (item) => item.readed === false
          );
          for (const notificacao of notifications) {
            this.setReads(notificacao);
          }
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      notificacoes: "notificacoes/getNotificacoes",
      isLoading: "notificacoes/isLoading",
    }),

    routeName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="sass" scoped>
.notificacoes
  .hovered:hover
    .white
      color: #ff4163 !important
    .dark-grey-2
      color: #ff4163 !important
  .bg-black-notificacoes
    background-color: #111 !important
  .scroll::-webkit-scrollbar
    width: 1px !important
  .img-perfil
    position: relative
    .bg-black
      background-color: #151515
    img, div
      width: 40px
      height: 40px
      object-fit: cover
      transition: all 0.25s
      font-size: 25px
      display: inline-flex
      flex-direction: row
      justify-content: center
      align-items: center
      border-radius: 50%
  .border-right
    border-right: 1px solid rgba(50, 50, 50, .3)
  .sombra
    animation: sombra 350ms
    height: 100vh !important

  @keyframes sombra
    0%
      height: 100vh
      opacity: 0
    5%
      height: 100vh
      opacity: 0
    100%
      opacity: 1

  .sombra-closed
    height: 0px !important
    animation: sombra-closed 350ms
    display: none !important

  @keyframes sombra-closed
    0%
      height: 100vh
      opacity: 1
    95%
      height: 100vh
    100%
      height: 0vh
      opacity: 0

  .notifications-content
    position: absolute
    transition: all .35s
    z-index: 1
    height: 100vh
    top: 0px
    left: 100%
    border-left: 1px solid rgba(50, 50, 50, .3)

  .open-right-collapse
    min-width: 250px
  .close-right-collapse
    width: 0px
    opacity: 0
    min-width: 0px
  .width-100
    width: 100vw
  @media (max-width: 500px)
    .scroll
      max-height: 100vh !important
</style>
