import { render, staticRenderFns } from "./Cursos.vue?vue&type=template&id=361198d7&scoped=true&"
import script from "./Cursos.vue?vue&type=script&lang=js&"
export * from "./Cursos.vue?vue&type=script&lang=js&"
import style0 from "./Cursos.vue?vue&type=style&index=0&id=361198d7&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361198d7",
  null
  
)

export default component.exports