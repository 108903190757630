<template>
  <div>
    <!-- Button trigger modal -->
    <button data-toggle="modal" id="btn-modal-blackfriday" data-target="#modal-blackfriday"
      class="btn btn-primary opacity-0 position-absolute invisible" />

    <!-- Modal -->
    <div class="modal fade" id="modal-blackfriday">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content pt-5 pb-4 px-4">
          <a target="_blank" :href="$linkBlackFridayWhats">
            <img class="w-100 pointer with-events" src="@/assets/images/blackfriday/banner.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserService from "@/service/userService";

export default {
  name: 'BlackFridayComp',

  data() {
    this.userservice = new UserService(this.$resource);

    return {
      descricao: '',
      id: '',
      titulo: '',
      questoes: [],
      from: '',
      course: ''
    }
  },

  created() {
    this.load()
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
    }),
  },

  methods: {
    async load() {
      if (!this.user.id || this.user.isPro || this.user?.popups?.acesso) return

      const isViwed = localStorage.getItem("blackfriday2024");
      if (isViwed) return

      this.userservice.acessoPopup({ id: 1 })
      localStorage.setItem("blackfriday2024", true);
      this.toggleModal()
    },

    toggleModal() {
      setTimeout(() => {
        document.getElementById('btn-modal-blackfriday').click()
      }, 300)
    },
  },

  watch: {
    user: {
      deep: true,
      handler() {
        this.load()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-content
  background-color: #000
  background-size: cover
  // background-image: url('@/assets/images/bg_formulario.png')
h5
  letter-spacing: 2px
  font-size: 26px !important
  font-weight: bold !important
p
  text-align: justify
label
  font-size: 12px
.marq
  text-style: italic !important
.btn-pink
  padding-top: 3px !important
  place-items: center
@media (min-width: 576px)
  .modal-dialog
    max-width: 550px !important
    margin: 1.75rem auto
</style>
