import { render, staticRenderFns } from "./WhatsApp.vue?vue&type=template&id=6ba5ee97&scoped=true&"
var script = {}
import style0 from "./WhatsApp.vue?vue&type=style&index=0&id=6ba5ee97&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba5ee97",
  null
  
)

export default component.exports