const base = 'plataforma-conteudo/educando{/id}'

export default class softwareService {
  constructor (resource) {
    const acoesCustomizadas = {
      getDados: { method: 'GET', url: `${base}/perfil` },
      getListagemAtualizacao: { method: 'GET', url: `${base}/galeria/listar{/qtd}/pulando{/skip}/ordenando/ultima-atualizacao` },
      getListagemCurtidas: { method: 'GET', url: `${base}/galeria/listar{/qtd}/pulando{/skip}/ordenando/curtidas` }
    }
    this.resource = resource('', {}, acoesCustomizadas)
  }

  getDados (dados) {
    return this.resource
      .getDados(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  getListagemAtualizacao (dados) {
    return this.resource
      .getListagemAtualizacao(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  getListagemCurtidas (dados) {
    return this.resource
      .getListagemCurtidas(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }
}
