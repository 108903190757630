<template>
  <section class="row p-0">
    <div class="col-12 col-md-auto align-items-center ps-4 ps-md-5 pe-md-4">
      <form
        @submit.prevent="login()"
        class="d-flex f-column item-center height-100vh content-center pe-3"
      >
        <img
          class="logo mb-5 mt-5 mt-md-0"
          src="../../../assets/images/logo.png"
        />

        <InputLogin
          v-model="email"
          type="email"
          placeholder="Seu email cadastrado"
        >
          <EmailIcon />
        </InputLogin>

        <InputLogin
          v-model="senha"
          type="password"
          placeholder="Insira sua senha"
        >
          <PasswordIcon />
        </InputLogin>

        <div>
          <div class="row justify-content-between">
            <div class="col-auto">
              <CheckboxDark
                class="me-1"
                label="Lembrar deste dispositivo"
                v-model="connected"
              />
            </div>

            <div
              data-toggle="modal"
              data-target="#recuperar-senha"
              class="col-auto font-14 dark-grey-2 text-decoration-underline pointer"
            >
              Esqueceu a senha?
            </div>

            <ModalEsqueciSenha :current-email="email" />
          </div>
        </div>

        <button class="bg-btn-pink btn mt-5 upper">Entrar</button>
      </form>
    </div>

    <div class="col bg-chale" />
  </section>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import InputLogin from "./InputLogin";
import CheckboxDark from "./CheckboxDark";
import EmailIcon from "@/assets/icons/Email";
import UserService from "@/service/userService";
import PasswordIcon from "@/assets/icons/Password";
import ModalEsqueciSenha from "./ModalEsqueciSenha";

const config = {
  headers: {
    Origem: null,
  },
};
export default {
  name: "LoginComp",

  components: {
    EmailIcon,
    InputLogin,
    PasswordIcon,
    CheckboxDark,
    ModalEsqueciSenha,
  },

  data() {
    return {
      connected: false,
      email: "",
      senha: "",
    };
  },

  async created() {
    this.UserService = new UserService(this.$resource);
    this.email = localStorage.getItem("email-aluno");
    this.connected = localStorage.getItem("connected-aluno");
    this.getUUID();

    let senha = localStorage.getItem("senha-aluno");
    if (senha)
      senha = await this.UserService.decrypt({ value: senha }).then(
        ({ body }) => body
      );

    this.senha = senha;
  },

  methods: {
    async setLocalStorage(params) {
      localStorage.setItem("email-aluno", params.email);
      localStorage.setItem("connected-aluno", params.connected);

      const { body } = await this.UserService.encripty({ value: params.senha });
      localStorage.setItem("senha-aluno", body);
    },

    async login() {
      this.$sweet.sweetWait(
        "Aguarde enquanto estamos verificando seus dados de acesso"
      );
      const params = {
        senha: this.senha,
        email: this.email,
      };
      localStorage.removeItem("senha-aluno");
      localStorage.removeItem("email-aluno");
      localStorage.removeItem("connected-aluno");
      if (this.connected) this.setLocalStorage(params);

      axios
        .post("/acesso-publico/login/plataforma-conteudo/v2", params, config)
        .then(({ data }) => {
          this.$router.push({
            name: "token",
            params: { token: data.token, uuid: this.getUUID() },
          });
          this.$sweet.sweetClose();
        })
        .catch(async (error) => {
          console.error(error);
          if (
            error.response.data.indexOf(
              "origem informada diferente da registrada"
            ) > -1
          ) {
            this.origemDiferente(error.response.data);
            return;
          }

          let message = "Usuário ou senha inválidos";
          if (
            error.response.data ===
            "nenhuma assinatura ativa para o educando informado"
          )
            message = error.response.data;

          if (error.response.data === "pendente ativação cadastro free")
            message = error.response.data;
          this.$sweet.sweetMensagem(message);
        });
    },

    async origemDiferente(error) {
      const resp = await this.$sweet.sweetConfirm(
        "Você está tentando acessar a plataforma de um dispositivo desconhecido, deseja acessar por este dispositivo?"
      );
      if (resp.isConfirmed) {
        const userId = error.split("#")[1];
        await axios.put(
          "acesso-publico/login/plataforma-conteudo/origem-acesso",
          { id: userId, novaOrigem: this.getUUID() }
        );
        this.login();
      }
    },

    getUUID() {
      let uuid = localStorage.getItem("uuidPlataformaEducando");
      if (uuid) {
        config.headers.Origem = uuid;
        return uuid;
      }
      uuid = uuidv4();
      localStorage.setItem("uuidPlataformaEducando", `p-${uuid}`);
      config.headers.Origem = `p-${uuid}`;
      return `p-${uuid}`;
    },
  },
};
</script>
<style lang="sass" scoped>
@media (max-width: 500px)
  .bg-btn-pink
    transform: scale(1) !important
button
  width: 100%
  padding-top: 5px !important
  font-size: 16px !important
  padding-bottom: 5px !important
.container-fluid
  padding: 0px !important
.height-100vh
  max-height: 100vh
.d-flex
  display: inline-flex
.f-column
  flex-direction: column
.item-center
  justify-content: center
.content-center
  align-items: center
.logo
  max-width: 100px
.bg-chale
  opacity: .5
  background-size: cover
  background-position: bottom center
  background-image: url('../../../assets/images/chale.png')
@media (max-width: 500px)
  .bg-chale
    display: none

.border-l-3
  border-top-left-radius: 8px !important
  border-bottom-left-radius: 8px !important
.border-r-3
  border-top-right-radius: 8px !important
  border-bottom-right-radius: 8px !important
.input-modal
  background-color: white
  border: none !important
  padding-left: 10px !important
  &:focus
    border-bottom: none !important
    box-shadow: 0px 0px rgba(0,0,0,0) !important
.input-group-prepend
  position: relative
  &:before
    top: 50%
    right: 1px
    width: 1px
    content: ''
    height: 35%
    position: absolute
    background-color: rgba(45,45,45,.35)
    transform: translateY(-50%)
</style>
