const SET_PERFIL = 'SET_PERFIL'
const SET_LOADED = 'SET_LOADED'
const SET_LOADING = 'SET_LOADING'
const SET_FOLLOWING = 'SET_FOLLOWING'
const SET_FOLLOWERS_COUNT = 'SET_FOLLOWERS_COUNT'
const SET_FOLLOWINGS_COUNT = 'SET_FOLLOWINGS_COUNT'

const state = {
  perfil: {
    following: false
  },
  isLoaded: false,
  isLoading: false
}

const actions = {
  setPerson ({ commit }, perfil) {
    commit(SET_PERFIL, perfil)
  },

  setFollowing ({ commit }, value) {
    commit(SET_FOLLOWING, value)
  },

  setFollowersCount ({ commit }, option) {
    commit(SET_FOLLOWERS_COUNT, option)
  },

  setFollowingsCount ({ commit }, option) {
    commit(SET_FOLLOWINGS_COUNT, option)
  },

  setLoaded ({ commit }, status) {
    commit(SET_LOADED, status)
  },

  setLoading ({ commit }, status) {
    commit(SET_LOADING, status)
  }
}

const getters = {
  getPerson: state => state.perfil,
  getsoftwares: state => state.perfil?.softwares || [],
  isLoading: state => state.isLoading,
  isLoaded: state => state.isLoaded
}

const mutations = {
  [SET_FOLLOWERS_COUNT] (state, option) {
    const hasObj = state?.perfil?.seguindo?.[0]?.totalSeguindo
    if (isNaN(hasObj)) return

    const value = option ? -1 : 1
    state.perfil.seguindo[0].totalSeguindo = state.perfil.seguindo[0].totalSeguindo + value
  },

  [SET_FOLLOWINGS_COUNT] (state, option) {
    const hasObj = state?.perfil?.seguidores?.[0]?.totalSeguidores
    if (isNaN(hasObj)) return

    const value = option ? -1 : 1
    state.perfil.seguidores[0].totalSeguidores = state.perfil.seguidores[0].totalSeguidores + value
  },

  [SET_FOLLOWING] (state, value) {
    state.perfil.following = value
  },

  [SET_PERFIL] (state, perfil) {
    state.perfil = perfil
  },

  [SET_LOADED] (state, status) {
    state.isLoaded = status
  },

  [SET_LOADING] (state, status) {
    state.isLoading = status
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
