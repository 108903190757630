<template>
  <section class="pb-3 pt-4 pt-md-2 px-0">
    <h3 class="ms-2 mb-2 mb-md-3 bold bold-xs-none title text-center">
      O que nossos alunos dizem sobre a versão PRO
    </h3>

    <div class="content-slider">
      <Slider
        :arrowLeft="false"
        :show-infos="true"
        :hasSlot="true"
        :arrowRight="false"
        :settings-props="settings"
        :slides-to-show="slidesToShow"
        father-class="dep-slider-more"
      >
        <template #slider>
          <div
            v-for="slide in slides"
            :key="slide.video"
            class="depoiment-content"
          >
            <img class="depoiment" :src="slide.image" />

            <video
              controls
              allowfullscreen
              :id="slide.video"
              :src="slide.video"
              :poster="slide.thumb"
              controlslist="nodownload"
            >
              <source :src="slide.video" />
            </video>
          </div>
        </template>
      </Slider>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Slider from "@/components/shared/Slider";

import lais from "@/assets/images/depoimentos/lais.png";
import bruno from "@/assets/images/depoimentos/bruno.png";
import amanda from "@/assets/images/depoimentos/amanda.png";
import hatilla from "@/assets/images/depoimentos/hatilla.png";
import mariana from "@/assets/images/depoimentos/mariana.png";
import matheus from "@/assets/images/depoimentos/matheus.png";
import silvana from "@/assets/images/depoimentos/silvana.png";
import michelly from "@/assets/images/depoimentos/michelly.png";
import patricia from "@/assets/images/depoimentos/patricia.png";
import laisThumb from "@/assets/images/thumbnails/lais.png";
import brunoThumb from "@/assets/images/thumbnails/bruno.png";
import amandaThumb from "@/assets/images/thumbnails/amanda.png";
import hatillaThumb from "@/assets/images/thumbnails/hatilla.png";
import marianaThumb from "@/assets/images/thumbnails/mariana.png";
import matheusThumb from "@/assets/images/thumbnails/matheus.png";
import silvanaThumb from "@/assets/images/thumbnails/silvana.png";
import michellyThumb from "@/assets/images/thumbnails/michelly.png";
import patriciaThumb from "@/assets/images/thumbnails/patricia.png";

export default {
  components: { Slider },

  data() {
    return {
      isScrolling: true,
      slides: [
        {
          image: lais,
          thumb: laisThumb,
          showVideo: false,
          video:
            "https://publico.sfo3.cdn.digitaloceanspaces.com/lp-depoimentos/lais_dos_santos_fiel_-_editado%20(360p).mp4",
        },
        {
          image: bruno,
          thumb: brunoThumb,
          showVideo: false,
          video:
            "https://publico.sfo3.cdn.digitaloceanspaces.com/lp-depoimentos/bruno_retameiro_-_editado%20(360p).mp4",
        },
        {
          image: amanda,
          thumb: amandaThumb,
          showVideo: false,
          video:
            "https://publico.sfo3.cdn.digitaloceanspaces.com/lp-depoimentos/amanda_let%C3%ADcia_-_editado%20(1080p).mp4",
        },
        {
          image: hatilla,
          thumb: hatillaThumb,
          showVideo: false,
          video:
            "https://publico.sfo3.cdn.digitaloceanspaces.com/lp-depoimentos/h%C3%A1illa_peres_-_editado%20(360p).mp4",
        },
        {
          image: mariana,
          thumb: marianaThumb,
          showVideo: false,
          video:
            "https://publico.sfo3.cdn.digitaloceanspaces.com/lp-depoimentos/marina_goto%20(1080p).mp4",
        },
        {
          image: matheus,
          thumb: matheusThumb,
          showVideo: false,
          video:
            "https://publico.sfo3.cdn.digitaloceanspaces.com/lp-depoimentos/matheus_vitor_-_editado%20(1080p).mp4",
        },
        {
          image: silvana,
          thumb: silvanaThumb,
          showVideo: false,
          video:
            "https://publico.sfo3.cdn.digitaloceanspaces.com/lp-depoimentos/silvana_-_corona%20(1080p).mp4",
        },
        {
          image: michelly,
          thumb: michellyThumb,
          showVideo: false,
          video:
            "https://publico.sfo3.cdn.digitaloceanspaces.com/lp-depoimentos/michelly_-_corona%20(1080p).mp4",
        },
        {
          image: patricia,
          thumb: patriciaThumb,
          showVideo: false,
          video:
            "https://publico.sfo3.cdn.digitaloceanspaces.com/lp-depoimentos/patr%C3%ADcia_panach%C3%A3o_-_editado%20(1080p).mp4",
        },
      ],
    };
  },

  computed: {
    slidesToShow() {
      if (this.width < 600) return 2;
      if (this.width < 800) return 3;
      return 4;
    },

    settings() {
      return {
        speed: 300,
        infinite: true,
        autoplay: true,
        slidesToShow: this.slidesToShow,
        cssEase: "linear",
        slidesToScroll: 2,
        autoplaySpeed: 5000,
      };
    },

    ...mapGetters({
      width: "configs/getWidth",
    }),
  },
};
</script>

<style lang="sass">
.dep-slider-more
  .content-slider
    transform: scale(1.1)

  .depoiment-content
    position: relative
    .depoiment
      padding: 10px
      width: 100%
    video, .depoiment-thumb
      top: 28%
      left: 50%
      width: 80%
      position: absolute
      border-style: solid
      border-color: #dd4362
      pointer-events: auto !important
      transform: translate(-50%)
  .arrow-right
    top: 35% !important
    right: -35px !important
  .arrow-left
    top: 35% !important
    left: -35px !important
</style>
