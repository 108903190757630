<template>
  <div class="column">
    <div class="col label-modal" v-if="label">
      <label>{{ label }}</label>
    </div>

    <div class="col mb-2">
      <input
        v-mask="mask"
        :placeholder="placeholder"
        :class="['input-modal mb-2', disable ? 'disabled' : '', classe]"
        :type="type"
        :disabled="disable"
        v-model="text"
        :required="required"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},

    label: {
      type: String,
    },

    required: {
      default: false,
    },

    placeholder: {
      default: "",
    },

    classe: {
      default: "",
    },

    type: {
      default: "text",
    },

    mask: {
      default: "",
    },

    disable: {
      default: false,
    },
  },
  data() {
    return {
      text: this.value,
    };
  },

  watch: {
    value: {
      handler(value) {
        if (value === this.text) return;
        this.text = value;
      },
    },

    text: {
      handler(text) {
        if (text === this.value) return;
        this.$emit("input", text);
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.disabled
  cursor: no-drop !important
.input-modal
  padding: 8px 10px 5px 10px !important
  border-radius: 4px
  color: #333 !important
  background-color: #fff !important
  &:focus
    border: 1px solid #ff4163 !important
</style>
