<template>
  <div>
    <tagsInput
      v-model="tags"
      element-id="tags"
      :typeahead="true"
      add-tags-on-comma
      hide-input-on-limit
      delete-on-backspace
      placeholder="Adicione suas Tags"
    >
      <template #selected-tag="{ tag, index, removeTag }">
        <span
          v-html="tag.value"
          class="me-2 dark-grey"
        />

        <span
          class="tags-input-remove"
          @click.prevent="removeTag(index)"
        />
      </template>
    </tagsInput>
  </div>
</template>

<script>
import tagsInput from '@voerro/vue-tagsinput'

export default {
  components: {
    tagsInput
  },

  props: {
    value: {}
  },

  data () {
    return {
      tags: []
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        const tagsMap = this.tags.map(({ value }) => value)
        if (isEqual(value, tagsMap)) return
        this.tags = value
      }
    },

    tags: {
      deep: true,
      handler (tags) {
        const tagsMap = tags.map(({ value }) => value)
        if (isEqual(tagsMap, this.value)) return
        this.$emit('input', tagsMap)
      }
    }
  }
}

const isEqual = (val1, val2) => JSON.stringify(val1) === JSON.stringify(val2)
</script>

<style lang="sass">
.tags-input-wrapper-default
  border: 1px solid #606060 !important
  border-radius: 2px !important
  background-color: transparent !important
.tags-input-wrapper-default
  padding: 3px 8px !important

.tags-input-remove:before, .tags-input-remove:after
  background: #ff4163ed !important
.tags-input input
  color: #c5c5c5 !important
  font-size: 13px !important
.tags-input-wrapper-default.active
  border: 1px solid #ff4163 !important
  box-shadow: -0px 4px 4px -2px rgba(255, 65, 99, .5) !important
</style>
