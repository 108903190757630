<template>
  <div class="column">
    <div class="col mt-1 label-modal" v-if="label">
      <label>{{ label }}</label>
    </div>

    <div class="col">
      <textarea
        :type="type"
        v-model="text"
        :disabled="disable"
        :required="required"
        :placeholder="placeholder"
        :class="[
          'text-area-field mb-1',
          text ? 'min-height-text-area' : '',
          background,
        ]"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},

    label: {
      type: String,
    },

    background: {
      type: String,
      default: "bg-light",
    },

    required: {
      default: false,
    },

    placeholder: {
      default: "",
    },

    type: {
      default: "text",
    },

    disable: {
      default: false,
    },
  },
  data() {
    return {
      text: this.value,
    };
  },

  watch: {
    value: {
      handler(value) {
        if (value === this.text) return;
        this.text = value;
      },
    },

    text: {
      handler(text) {
        if (text === this.value) return;
        this.$emit("input", text);
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.min-height-text-area
  min-height: 130px !important

.bg-light
  background-color: #fff
.disabled
  cursor: no-drop !important
.input-modal
  border-radius: 2px
  min-height: 10px !important
  border: 1px solid #606060 !important
  padding: 8px 10px 5px 10px !important
  &:focus
    border: 1px solid #ff4163 !important

.text-area-field
  width: 100%
  border: none
  border-bottom: 1px solid #404040 !important
  font-size: 14px
  padding: 3px 8px
  border-radius: 3px
  transition: all .25s
  min-height: 50px
  &:focus
    transition: all .25s
    outline: none !important
    min-height: 130px !important
@media (max-width: 600px)
  .text-area-field
    &:focus
      min-height: 80px !important
</style>
