<template>
  <section class="row justify-content-center align-content-center p-0 height-100vh">
    <div class="bg-chale" />

    <div class="col-3 bg-black p-3 py-5 text-center">
      <img
        class="logo mb-4"
        src="../../../assets/images/logo.png"
      >
      <h5 class="text-center mb-3 white">
        Sua nova senha provisória!
      </h5>
      <h2 class="mb-3">
        {{ senha }}
      </h2>
      <p class="text-center dark-grey-2 font-14">
        *Ao acessar a plataforma, substitua a senha provisória por uma de sua preferência na aba "SENHAS"
      </p>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
  name: 'RecoverComp',

  data () {
    return {
      email: '',
      senha: ''
    }
  },

  async created () {
    const newPass = sessionStorage.getItem('new-pass')
    if (newPass) return (this.senha = newPass)
    const { data: senha } = await axios.get(`${this.$baseApiURL}/gerar-nova-senha-educando/${this.$route.params.email}`)
    sessionStorage.setItem('new-pass', senha)
    this.senha = senha
  }
}
</script>
<style lang="sass" scoped>
.bg-black
  z-index: 2
.container-fluid
  padding: 0px !important
  max-width: 100%
.height-100vh
  max-height: 100vh
.logo
  max-width: 150px
.bg-chale
  opacity: .5
  z-index: 1
  width: 100%
  height: 100vh
  position: absolute
  background-size: cover
  background-position: bottom center
  background-image: url('../../../assets/images/chale.png')
  margin-left: 25px
</style>
