<template>
  <div :class="['pie-wrapper', `progress-${percentual}`]">
    <p class="font-16 label">
      {{ percentual }}%
    </p>
    <div class="pie">
      <div class="left-side half-circle" />
      <div class="right-side half-circle" />
    </div>
    <div class="shadow" />
  </div>
</template>

<script>
export default {
  props: {
    percentual: {
      default: 0
    }
  }
}
</script>

<style lang="scss">

$color: #ff4163;
$default-size: 55px;
$border: 3px;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress) {
  .pie {
    .left-side {
      transform: rotate($progress * 3.6deg);
    }
    @if $progress <= 50 {
      .left-side {
        animation: colorload-#{$progress} 2s;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);
      .right-side {
        transform: rotate(180deg);
        transition: all .25s;
      }
    }
  }
}

.pie-wrapper {
  @include size($default-size, $default-size);
  transition: all 2s;
  .pie {
    transition: all 2s;
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    position: absolute;

    .half-circle {
      transition: all 2s;
      @include size(100%, 100%);
      border: $border solid $color;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      position: absolute;
    }
  }

  .label {
    color: #ecf0f1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .shadow {
    @include size(100%, 100%);
    border: $border  solid #c0c0c0;
    border-radius: 50%;
  }

  @for $i from 1 through 100 {
    @keyframes colorload-#{$i} {
      0% {
        transform: rotate(0deg); }
      100% {
        transform: rotate($i * 3.6deg); }
    }

    &.progress-#{$i} {
      @include draw-progress($i);
    }
  }
}

</style>
