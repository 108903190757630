<template>
  <svg
    width="514"
    height="449"
    viewBox="0 0 514 449"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M257.114 110C184.429 110 125.8 161.3 125.8 224.9C125.8 288.5 184.429 339.8 257.114 339.8C329.8 339.8 388.429 288.5 388.429 224.9C388.429 161.3 329.8 110 257.114 110ZM257.114 299.6C210.143 299.6 171.743 266.1 171.743 224.9C171.743 183.7 210.029 150.2 257.114 150.2C304.2 150.2 342.486 183.7 342.486 224.9C342.486 266.1 304.086 299.6 257.114 299.6ZM424.429 105.3C424.429 120.2 410.714 132.1 393.8 132.1C376.771 132.1 363.171 120.1 363.171 105.3C363.171 90.5 376.886 78.5 393.8 78.5C410.714 78.5 424.429 90.5 424.429 105.3ZM511.4 132.5C509.457 96.6 500.086 64.8 470.029 38.6C440.086 12.4 403.743 4.20001 362.714 2.40001C320.429 0.300012 193.686 0.300012 151.4 2.40001C110.486 4.10001 74.1429 12.3 44.0857 38.5C14.0286 64.7 4.77143 96.5 2.71429 132.4C0.314291 169.4 0.314291 280.3 2.71429 317.3C4.65715 353.2 14.0286 385 44.0857 411.2C74.1429 437.4 110.371 445.6 151.4 447.4C193.686 449.5 320.429 449.5 362.714 447.4C403.743 445.7 440.086 437.5 470.029 411.2C499.971 385 509.343 353.2 511.4 317.3C513.8 280.3 513.8 169.5 511.4 132.5ZM456.771 357C447.857 376.6 430.6 391.7 408.086 399.6C374.371 411.3 294.371 408.6 257.114 408.6C219.857 408.6 139.743 411.2 106.143 399.6C83.7429 391.8 66.4857 376.7 57.4572 357C44.0857 327.5 47.1714 257.5 47.1714 224.9C47.1714 192.3 44.2 122.2 57.4572 92.8C66.3714 73.2 83.6286 58.1 106.143 50.2C139.857 38.5 219.857 41.2 257.114 41.2C294.371 41.2 374.486 38.6 408.086 50.2C430.486 58 447.743 73.1 456.771 92.8C470.143 122.3 467.057 192.3 467.057 224.9C467.057 257.5 470.143 327.6 456.771 357Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'InstagramIcon'
}
</script>

<style lang="sass" scoped>
svg
  width: 15px
  height: 15px
</style>
