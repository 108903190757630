<template>
  <div class="button-open pointer">
    <div v-if="user.extensaoImagem && !hasError">
      <img
        class="photo-profile"
        :src="mountImage(user)"
        @error="hasError = true"
        data-toggle="modal"
        data-target="#modalImageProfile"
      >
    </div>

    <div
      class="image-work bold"
      data-toggle="modal"
      data-target="#modalImageProfile"
      v-else
    >
      {{ user.nome ? user.nome[0] : '' }}
      <span class="add">
        +
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    isOpen: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return { hasError: false }
  },

  computed: {
    ...mapGetters({
      user: 'registration/getUser'
    })
  },

  methods: {
    mountImage ({ extensaoImagem, id }) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=educando&extensao=${extensaoImagem}&identificador=${id}`
    }
  }
}
</script>
<style lang="sass" scoped>
%profile
  width: 75px
  height: 75px
  object-fit: cover
  transition: all .25s
  font-size: 25px
  display: inline-flex
  flex-direction: row
  justify-content: center
  align-items: center
  border-radius: 50%
  background-color: #000
.image-work, .photo-profile
  @extend %profile

.image-work
  position: relative
  overflow: hidden
  .add
    text-align: center
    position: absolute
    bottom: 0
    background-color: #111
    opacity: .65
    font-size: 15px
    height: 15px
    padding-bottom: 20px
    width: 100%
.button-open
  transition: all 1s
  z-index: 9
  display: inline-flex
  flex-direction: row
  justify-content: center !important
  align-items: center !important
  .icon
    width: 25px
    height: 25px
  .image
    width: 55px
    height: 55px
    border-radius: 50%

@media (max-width: 500px)
  .photo-profile
    width: 30px !important
    height: 30px !important
  .button-open
    top: 5vh
    .icon
      width: 15px
      height: 15px
    .image
      width: 35px
      height: 35px
</style>
