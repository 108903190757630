<template>
  <div class="row justify-content-center align-item-center">
    <img src="@/assets/images/loading.gif">
  </div>
</template>
<script>
export default {
  name: 'LoadingComp'
}
</script>
<style lang="sass" scoped>
img
  width: 60px
</style>
