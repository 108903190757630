const baseURL = 'plataforma-conteudo/comunidade/notificacao'
export default class notificationService {
  constructor (resource) {
    const acoesCustomizadas = {
      alterar: { method: 'PUT', url: `${baseURL}{/id}/visualizada` },
      getList: { method: 'GET', url: `${baseURL}/listar{/qtd}/pulando{/skip}` }
    }
    this.resource = resource(baseURL, {}, acoesCustomizadas)
  }

  get (dados) {
    return this.resource
      .getList(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        return err
      })
  }

  update ({ id }) {
    return this.resource
      .alterar({ id }, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }
}
