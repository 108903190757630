const SET_EMPTY = 'SET_EMPTY'
const SET_FOLLOWERS = 'SET_FOLLOWERS'
const SET_FOLLOWERS_FILTER = 'SET_FOLLOWERS_FILTER'
const SET_IS_LOADING = 'SET_IS_LOADING'
const SET_UNFOLLOWERS = 'SET_UNFOLLOWERS'

const state = {
  followers: [],
  followersFilter: [],
  unfollowers: [],
  isLoading: false
}

const actions = {
  setFollowers ({ commit }, unfollowers) {
    commit(SET_FOLLOWERS, unfollowers)
  },

  setFollowersFilter ({ commit }, followersFilter) {
    commit(SET_FOLLOWERS_FILTER, followersFilter)
  },

  setUnfollowers ({ commit }, followers) {
    commit(SET_FOLLOWERS, followers)
  },

  setEmpty ({ commit }) {
    commit(SET_EMPTY)
  },

  setIsFollowing ({ state }, follower) {
    const followers = state.followers.map(item => {
      if (item.id === Number(follower.id)) item.following = !item.following
      return item
    })
    const hasFollower = followers.some(item => item.id === Number(follower.id))

    if (!hasFollower) {
      const name = `
        ${follower.dadosEducando?.[0]?.nome || follower.nome} 
        ${follower.dadosEducando?.[0]?.sobrenome || ''}
      `.trim()

      const img = follower.dadosEducando?.[0]?.img || follower.img
      const conquistas = follower?.dadosEducando
        ? follower.conquistas
        : follower.conquistas.map(i => {
          const params = new URLSearchParams(i.src)
          const id = params.get('identificador')
          const extensaoImagem = params.get('extensao')
          return {
            id,
            extensaoImagem,
            titulo: i.titulo
          }
        })

      followers.unshift({
        img,
        name,
        conquistas,
        id: Number(follower.id),
        hasError: false,
        loadFollowing: false,
        following: follower.following
      })
    }
    state.followers = followers
  },

  setIsLoading ({ commit }, isLoading) {
    commit(SET_IS_LOADING, isLoading)
  }
}

const getters = {
  getFollowers: (state) => {
    return state.followers
  },

  getFollowersFilter: (state) => {
    return state.followersFilter
  },

  getUnfollowers: (state) => {
    return state.unfollowers
  },

  isLoading (state) {
    return state.isLoading
  }
}

const mutations = {
  [SET_FOLLOWERS] (state, followers) {
    state.followers = [...state.followers, ...followers]
  },

  [SET_EMPTY] (state) {
    state.followers = []
  },

  [SET_FOLLOWERS_FILTER] (state, followersFilter) {
    state.followersFilter = [...followersFilter]
  },

  [SET_UNFOLLOWERS] (state, unfollowers) {
    state.unfollowers = [...state.unfollowers, ...unfollowers]
  },

  [SET_IS_LOADING] (state, isLoading) {
    state.isLoading = isLoading
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
