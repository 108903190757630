<template>
  <div>
    <div class="row align-items-center">
      <div class="col-7">
        <input
          type="file"
          :required="required"
          :id="`${keyInput}-file`"
          accept="image/*"
          style="display: none"
          @change="changeFile"
        >
        <label
          :for="`${keyInput}-file`"
          class="btn-cta"
        >
          Clique e selecione sua imagem
        </label>
      </div>

      <div class="col-5 text-end">
        <img
          v-if="link"
          :src="link"
          :class="isRect ? 'img-rect': ''"
        >
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    isRect: {
      default: false
    },
    value: {},
    placeholder: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    },
    keyInput: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      link: '',
      attachment: {}
    }
  },

  methods: {
    findImage () {
      const inputFile = document.getElementById(`${this.keyInput}-file`)
      inputFile.click()
    },

    isValidateSize (size) {
      if (!this.maxSize) return true
      if (size <= this.maxSize) return true
      this.$sweet.sweetMensagem('<h3 style="font-size: 20px">Atenção!</h3><h6 style="color:#646464; font-size: 15px">Tamanho máximo do arquivo é de <span style="color: #ff4163"><b>2MB</b>!</h6>')
      return false
    },

    changeFile () {
      this.attachment = document.getElementById(`${this.keyInput}-file`).files[0]
      const isValideSize = this.isValidateSize(this.attachment.size)
      if (!isValideSize) return

      const reader = new FileReader()
      reader.onloadend = () => { this.link = reader.result }
      if (this.attachment) reader.readAsDataURL(this.attachment)
    }
  },

  computed: {
    isImage () {
      const lk = this.link
      const img = ['jpg', 'jpeg', 'png'].filter(ext => lk.includes(ext))
      return Boolean(img.length)
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        if (isEqual(value, this.attachment)) return
        this.attachment = value
      }
    },

    attachment: {
      deep: true,
      handler (attachment) {
        if (isEqual(attachment, this.value)) return
        this.$emit('input', attachment)
      }
    }
  }
}

const isEqual = (val1, val2) => val1 === val2
</script>

<style lang="sass" scoped>
.btn-cta
  background: #fff
  border-radius: 5px
  color: #424242
  cursor: pointer
  padding: 6px 16px
  width: 100%

.img-rect
  width: 100%
  height: 100px
  border-radius: 0px !important

img
  max-width: 100%
  box-shadow: 1px 1px 3px 2px rgba(45,45,45, .25)
  width: 100px
  height: 100px
  font-size: 50px
  border-radius: 50%
  background-color: #151515
  div
    padding-top: 10px

label
  font-size: 13px
  color: #ff4163
</style>
