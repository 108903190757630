<template>
  <div class="comentarios mb-3 mb-md-0">
    <h6 class="font-14 mb-1 mb-md-2 pb-1">
      Comentários:
    </h6>

    <p
      v-if="!comments.length && !isLoading"
      class="font-13 dark-grey-2"
    >
      Nenhum comentário
    </p>

    <div
      v-if="isLoading"
      class="loading-btn mb-1"
    />

    <ModalGaleryCommentCard
      v-for="item in comments"
      :key="`comment-${item.id}`"
      :item="item"
      :is-cdn="isCdn"
    />
  </div>
</template>

<script>
import Mixins from '@/Mixins'
import { mapActions, mapGetters } from 'vuex'
import GaleryService from '@/service/galeryService'
import ModalGaleryCommentCard from './ModalGaleryCommentCard'

export default {
  name: 'ModalBody',

  mixins: [Mixins],

  components: {
    ModalGaleryCommentCard
  },

  props: {
    isCdn: {
      type: Boolean,
      default: false
    },

    messages: {
      type: Array,
      default: () => []
    }
  },

  data () {
    this.GaleryService = new GaleryService(this.$resource)

    return {
      isLoading: false,
      current: {
        user: Number(this.$route.params.user),
        album: Number(this.$route.params.album)
      }
    }
  },

  created () {
    this.getComments()
  },

  methods: {
    ...mapActions({
      setComments: 'galery/setComments',
      setEmptyComments: 'galery/setEmptyComments'
    }),

    mountComment (item) {
      const resps = (item?.respostas || item?.messages)?.map(this.mountComment)
      return {
        id: item.idComentario,
        aluno: {
          name: item?.aluno || `${item?.nome || ''} ${item?.sobrenome || ''}`.trim(),
          img: this.mountImagePath('educando', item.idEducando, item.extensaoImagem) || item.img
        },
        comment: item.comentario || item.descricao,
        idEducando: item.idEducando || item.idAluno,
        time: item.data || this.$f.timeAgo(item.createdAt),
        answers: this.isCdn ? resps : resps.reverse()
      }
    },

    async getComments () {
      if (this.isCdn) {
        this.setEmptyComments()
        const comments = this.messages.map(this.mountComment)
        this.setComments(comments)
        return
      }

      const idGalery = this.$route.params.album
      if (!idGalery || this.isLoading) return
      this.setEmptyComments()

      this.isLoading = true
      const { status, body } = await this.GaleryService.getGaleryJobComments({ idGalery })
      this.isLoading = false
      if (status !== 200) return

      const comments = body.map(this.mountComment)
      this.setComments(comments)
    }
  },

  computed: {
    ...mapGetters({ comments: 'galery/getComments' })
  },

  watch: {
    '$route.params': {
      deep: true,
      handler ({ album, user }) {
        const isEqualAlbum = Number(album) === this.current.album
        const isEqualUser = Number(user) === this.current.user
        if (isEqualAlbum && isEqualUser) return

        this.getComments()
        this.current.user = Number(user)
        this.current.album = Number(album)
      }
    }
  }
}
</script>
