<template>
  <svg
    class="email"
    width="18"
    height="14"
    viewBox="0 0 16 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62.6,273.349H49.767a1.4,1.4,0,0,0-1.369,1.427v9.513a1.4,1.4,0,0,0,1.369,1.427H62.6a1.4,1.4,0,0,0,1.369-1.427v-9.513A1.4,1.4,0,0,0,62.6,273.349Zm-0.189.951-6.2,6.464L49.96,274.3H62.413Zm-13.1,9.792v-9.124L53.7,279.51Zm0.645,0.672,4.4-4.584,1.541,1.593a0.444,0.444,0,0,0,.644,0l1.5-1.566,4.373,4.559H49.956Zm13.1-.672-4.373-4.56,4.373-4.559v9.119Z"
      transform="translate(-48.406 -273.344)"
    />
  </svg>
</template>
<script>
export default {
  name: 'EmailComp'
}
</script>
<style lang="sass" scoped>
.email
  fill: #646464
</style>
