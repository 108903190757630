<template>
  <div class="column">
    <div class="label-modal position-absolute">
      <BarsIcon class="me-4 mb-1" v-if="hasBars" />
      <component
        v-if="icon"
        :personal-styles="personalStyles"
        class="me-2 mb-1"
        :is="icons[icon]"
      />
      <label>{{ label }}</label>
    </div>

    <div class="col mb-2">
      <input
        :type="type"
        v-mask="mask"
        :id="id"
        v-model="text"
        :disabled="disable"
        :required="required"
        :placeholder="placeholder"
        :class="['input-modal mb-2', hasPadding ? 'has-padding' : 'no-padding']"
      />
    </div>
  </div>
</template>

<script>
import BarsIcon from "@/assets/icons/Bars";
import Instagram from "@/assets/icons/Instagram";
import Twitter from "@/assets/icons/Twitter";
import Youtube from "@/assets/icons/Youtube";
import Tiktok from "@/assets/icons/Tiktok";
import Linkedin from "@/assets/icons/Linkedin";
import Search from "@/assets/icons/Search";

export default {
  components: { BarsIcon },
  props: {
    value: {},

    label: {
      type: String,
    },

    id: {
      type: String,
      default: "",
    },

    hasBars: {
      type: Boolean,
      default: true,
    },

    required: {
      default: false,
    },

    hasPadding: {
      default: true,
    },

    placeholder: {
      default: "",
    },

    type: {
      default: "text",
    },

    icon: {
      default: "",
    },

    mask: {
      default: "",
    },

    personalStyles: {
      default: "",
    },

    disable: {
      default: false,
    },
  },

  data() {
    return {
      text: this.value,
      icons: {
        instagram: Instagram,
        linkedin: Linkedin,
        twitter: Twitter,
        youtube: Youtube,
        tiktok: Tiktok,
        search: Search,
      },
    };
  },

  watch: {
    value: {
      handler(value) {
        if (value === this.text) return;
        this.text = value;
      },
    },

    text: {
      handler(text) {
        if (text === this.value) return;

        this.$emit("input", text);
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.has-padding
  padding-left: 150px
.no-padding
  padding-left: 25px
</style>
