<template>
  <div>
    <div class="row justify-content-center py-4 py-md-5 enter-effect">
      <div class="col-11 px-2 max-1000 m-auto">
        <h3 class="ms-1 mb-3 bold bold-xs-none title title-bordered">
          Inspire-se com os trabalhos criados pela nossa comunidade
        </h3>

        <div class="row justify-content-start px-1 px-md-3">
          <div class="col-auto mb-1 mb-md-0">
            <button
              @click="listBy('geral')"
              :class="[orderBy === 'geral' ? 'btn-fill' : 'btn-transparent']"
            >
              Geral
            </button>
          </div>

          <div class="col">
            <button
              @click="listBy('seguindo')"
              :class="[orderBy === 'seguindo' ? 'btn-fill' : 'btn-transparent']"
            >
              Seguindo
            </button>
          </div>
        </div>

        <div class="row justify-content-start mt-3 mt-md-5 px-3">
          <Album
            has-footer
            :album="album"
            v-for="(album, ind) in galery"
            :key="`${ind}-album-galery`"
            class="mb-3"
            col="col-6 col-sm-12 col-md-6 col-lg-4 col-xl-3"
          />
        </div>

        <p
          v-if="!loadingGalery && !isFullLoaded"
          title="Carregar mais"
          @click="getGalery()"
          class="pointer dark-grey-2 text-center font-13 hovered"
        >
          Carregar mais...
        </p>

        <p
          class="text-center dark-grey-2 mt-5 font-14"
          v-if="!galery.length && !loadingGalery"
        >
          Ainda não existem projetos a serem exibidos.
        </p>

        <LoadingComp v-if="loadingGalery" />
      </div>
    </div>

    <ModalGalery />
  </div>
</template>

<script>
import Mixins from '@/Mixins'
import { mapGetters, mapActions } from 'vuex'
import GaleryService from '@/service/galeryService'
import Album from '@/components/shared/galeria/Album'
import ModalGalery from '@/components/shared/galeria/modal/ModalGalery'

export default {
  name: 'GaleriaComp',

  mixins: [Mixins],

  components: {
    Album,
    ModalGalery
  },

  props: {
    direction: {
      default: 'down'
    }
  },

  created () {
    this.GaleryService = new GaleryService(this.$resource)

    const { album, user, image } = this.$route.params
    const shouldRedirect = Boolean(album && user && image)

    if (shouldRedirect) return (this.$router.push({ name: 'perfil', params: { user, album, image } }))

    this.setGaleryEmpty()
    this.getGalery()
    this.openModal()
  },

  data () {
    return {
      qtd: 16,
      skip: 0,
      isLoaded: false,
      orderBy: 'geral',
      // direction: 'down',
      loadingGalery: false,
      isFullLoaded: false
    }
  },

  computed: {
    ...mapGetters({
      galery: 'galery/getGalery'
    })
  },

  watch: {
    direction: {
      handler (direction) {
        if (direction === 'up') this.getGalery()
      }
    }
  },

  methods: {
    ...mapActions({
      setGalery: 'galery/setGalery',
      setGaleryEmpty: 'galery/setGaleryEmpty'
    }),

    listBy (orderBy) {
      if (this.loadingGalery) return

      this.skip = 0
      this.orderBy = orderBy
      this.isLoaded = false
      this.setGaleryEmpty()
      this.getGalery()
    },

    async getGalery () {
      if (this.loadingGalery || this.isLoaded) return

      this.loadingGalery = true
      const paramsRequest = { qtd: this.qtd, skip: this.skip }
      const order = this.orderBy === 'geral' ? 'galeryByGeneral' : 'galeryByFollowing'

      const { body } = await this.GaleryService[order](paramsRequest)
      if (body.length < this.qtd) this.isFullLoaded = true

      const galery = body.map(currentGalery => ({
        images: [],
        softwares: [],
        albumId: currentGalery.id,
        title: currentGalery.titulo,
        views: currentGalery.exibicoes,
        likes: currentGalery.curtidas || 0,
        trabalhos: currentGalery.trabalhos,
        descricao: currentGalery.descricao,
        aluno: this.mountAluno(currentGalery),
        liked: currentGalery.euCurtiEssaGaleria !== 'N',
        capa: this.mountPath(currentGalery.idEducando, currentGalery.id, currentGalery.trabalhos)
      }))
      this.skip = this.skip + this.qtd
      if (galery.length < this.qtd) this.isLoaded = true
      this.setGalery(galery)
      this.loadingGalery = false
    },

    mountPath (idEducando, galeryId, trabalhos) {
      if (!trabalhos.length) return ''

      const { id: jobId, extensao } = trabalhos[0]
      return `${this.$space}/postagens/${idEducando}/${galeryId}/${jobId}.${extensao}`
    },

    mountAluno (galery) {
      return {
        conquistas: [],
        id: galery.idEducando,
        ocupacao: galery.ocupacao,
        ext: galery.extensaoImagem,
        nome: `${galery.nome || ''} ${galery.sobrenome || ''}`,
        img: this.mountImagePath('educando', galery.idEducando, galery.extensaoImagem)
      }
    },

    openModal () {
      const user = Number(this.$route.params.user)
      const album = Number(this.$route.params.album)
      const shouldOpenModal = album && user

      if (shouldOpenModal) {
        const item = this.galery.find(({ albumId }) => albumId === album)
        this.setAlbum(item)
        setTimeout(() => document.getElementById('btn-modal-picture')?.click(), 500)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.scroll
  min-height: 100vh
.scroll::-webkit-scrollbar-track
  border-radius: 2px !important
.btn-fill, .btn-transparent
  width: 130px
@media (max-width: 1200px) and (min-width: 600px)
  .title
    font-size: 20px !important
    text-overflow: ellipsis
    max-width: 1005
    overflow: hidden
    white-space: nowrap
@media (max-width: 600px)
  .title
    height: auto !important
</style>
