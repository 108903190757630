<template>
  <div v-if="album.capa" :class="['box-picture px-0 overflow-hidden', col]">
    <div @click="openModal()" class="pointer">
      <img :src="album.capa" class="post-image pointer bg-black" />
    </div>

    <div
      v-if="hasFooter"
      class="row justify-content-between item-center pt-2 px-1 footer"
    >
      <div class="col pe-0">
        <router-link
          :to="{ name: 'perfil', params: { user: album.aluno.id } }"
          class="white font-12 pointer hovered"
        >
          <div class="row white-space">
            <div class="col-auto ps-0 pe-2">
              <div class="aluno-img pointer bg-black">
                <img
                  :src="album.aluno.img"
                  @error="errorImgAluno = true"
                  v-if="!errorImgAluno && album.aluno.img"
                />
                <div v-else class="text-center">
                  {{ album.aluno.nome[0].toUpperCase() }}
                </div>
              </div>
            </div>

            <div class="col-auto ps-1 ps-md-0 ellipse height-22">
              <span class="white font-12 pointer hovered">
                {{ album.aluno.nome }}
              </span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col">
        <div class="row justify-content-end">
          <div class="col-auto pe-0">
            <p class="font-12 dark-grey-2 pointer" @click="handleLike()">
              <LikeIcon :color="album.liked ? 'pink' : 'grey'" class="icon" />
              {{ album.likes }}
            </p>
          </div>
          <div class="col-auto pe-0">
            <p class="font-12 dark-grey-2">
              <EyeIcon color="grey" class="icon" />
              {{ album.views }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LikeIcon from "@/assets/icons/Like";
import EyeIcon from "@/assets/icons/EyeIcon";
import GaleryService from "@/service/galeryService";

export default {
  name: "PictureGalery",

  components: {
    LikeIcon,
    EyeIcon,
  },

  data() {
    this.galeryService = new GaleryService(this.$resource);

    return {
      errorImgAluno: false,
    };
  },

  props: {
    hasFooter: {
      type: Boolean,
      default: false,
    },

    isCdn: {
      type: Boolean,
      default: false,
    },

    album: {
      type: Object,
      default: () => {},
    },

    item: {
      type: Object,
      default: () => {},
    },

    col: {
      type: String,
      default: "col-6 col-md-3",
    },
  },

  ...mapActions({
    setLikedAlbum: "galery/setLikedAlbum",
  }),

  methods: {
    ...mapActions({ setAlbumStore: "galery/setAlbum" }),

    replaceByDefault(e) {
      e.target.src = require("@/assets/images/default.png");
    },

    goTo(user) {
      this.$router.push({ name: "perfil", params: { user } });
    },

    handleLike() {
      const liked = this.album.liked;
      const idGalery = this.album.albumId;
      const action = liked ? "removeLike" : "giveLike";
      const likes = this.album.likes + (liked ? -1 : 1);

      this.galeryService[action]({ idGalery });
      Object.assign(this.album, { ...this.album, liked: !liked, likes });
    },

    openModal() {
      this.routeToPicture();
      const softwares = this.item?.softwares || [];
      const params = { ...this.item, ...this.album, softwares };
      if (this.isCdn) params.trabalhos = params.imagens;

      this.setAlbumStore(params);
      document.getElementById("btn-modal-picture")?.click();
    },

    routeToPicture() {
      const name = this.$route.name;
      const params = {};
      params.album = this.item?.id || this.album?.albumId;
      params.image = this.album?.id || this.album?.trabalhos?.[0]?.id;
      params.user = this.album?.aluno?.id || this.$route?.params?.user;
      this.$router.push({ name, params }).catch(() => {});
    },
  },
};
</script>
<style lang="sass" scoped>
.white-space
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  .ellipse
    width: 90px
    overflow: hidden
    align-self: self-end
    text-overflow: ellipsis
.footer
  width: 100%
  max-width: 100%
  align-self: center
.box-picture
  display: flex
  flex-direction: column
  align-content: center
  justify-content: center
  padding: 0px 1px !important
  .post-image
    width: 100%
    height: 200px
    object-fit: cover
    &:hover
      transition: all .25s
      filter: brightness(0.4)
  .aluno-img
    width: 22px
    height: 22px
    margin-bottom: 4px
    overflow: hidden
    border-radius: 50%
    img
      width: 22px
      height: 22px
      object-fit: cover
    div
      padding-top: 3px
  p
    line-height: 24px
  .icon
    margin-right: 3px
    margin-bottom: 3px
.height-22
  height: 22px
@media (max-width: 600px)
  .box-picture
    background-color: #000
  .aluno-img
    margin-bottom: 4px
  .post-image
    height: 100px !important
</style>
