<template>
  <section class="bg-dark" v-if="images.length || isLoading">
    <LoadingComp v-if="isLoading" />

    <div v-else-if="images.length" class="row justify-content-end align-items-center">
      <Slider :dots="true" :slide-default="false" :not-zoom="true" :settings-props="settings" :images="images"
        :slides-to-show="1" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Slider from "@/components/shared/Slider";
import CursoService from "@/service/cursoService";

export default {
  name: "CampaignsComp",
  components: {
    Slider,
  },

  data() {
    this.CursoService = new CursoService(this.$resource);

    return {
      images: [],
      isLoading: true,
      settings: {
        speed: 300,
        dots: true,
        arrows: true,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        cssEase: "linear",
        slidesToScroll: 1,
        autoplaySpeed: 5000,
      },
    };
  },

  async created() {
    if (!this.user.id) return

    if (this.campanhas.length) {
      this.images = this.campanhas;
      this.isLoading = false;
      return;
    }

    this.load()
  },

  methods: {
    async load() {
      if (!this.user.id) return

      const id = this.user?.isPro ? 2 : 4;
      let { body: campanhas2 } = await this.CursoService.cursosCampanha({ id });
      this.isLoading = false;
      this.$store.dispatch("campanhas/setCampanhas", []);
      campanhas2 = campanhas2.map((item) => this.mountImage(item, id));
      this.images = !campanhas2.length ? [] : campanhas2;
      this.$store.dispatch("campanhas/setCampanhas", campanhas2);
    },

    ext(item) {
      return this.isMobile
        ? item.extensaoImagemMobile || item.extensaoImagem
        : item.extensaoImagem;
    },

    exp(item) {
      if (!item.extensaoImagemMobile) return "";
      return this.isMobile ? "&expressao=mobile" : "";
    },

    mountImage(item, origin) {
      const vue = this;
      return {
        image: `${this.$baseApiURL}/acesso-arquivo?caminho=campanha&identificador=${item.id}&extensao=${vue.ext(item)}${vue.exp(item)}`,
        linkExterno: item.linkVideo,
        origin,
      };
    },
  },

  computed: {
    isMobile() {
      return this.$store.getters.getWidth < 500;
    },
    ...mapGetters({
      user: "registration/getUser",
      campanhas: "campanhas/getCampanhas",
    }),
  },

  watch: {
    'this.user': {
      deep: true,
      handler(user) {
        if (user.id) this.load()
      }
    }
  }
};
</script>
<style lang="sass" scoped>
.campaigns-img
  user-select: none
  overflow: hidden
  img
    user-select: none
    width: 100% !important
.bg-dark
  background-color: rgba(0,0,0,0) !important
.slick-dots
  bottom: 25px !important
  .slick-next
    right: -20px !important
  .slick-active
    .custom-dot:before
      width: 20px
      border-radius: 3px
      background-color: #fff
.arrow-right
  transform: rotate(135deg) !important
  right: -20px !important
  top: 50% !important
.arrow-left
  left: -20px !important
  transform: rotate(-45deg) !important
@media (max-width: 500px)
  .arrow-left
    transform: rotate(-45deg) scale(.5)
  .arrow-right
    transform: rotate(135deg) scale(.5)
  .slick-dots
    .custom-dot:before
      width: 6px
      height: 6px
</style>
