import Vue from 'vue'
import user from '@/consts/user'
import UserService from '@/service/userService'

const LOG_OUT = 'LOG_OUT'
const SET_USER = 'SET_USER'
const SET_WINS = 'SET_WINS'
const SET_TOKEN = 'SET_TOKEN'
const SET_HOURS = 'SET_HOURS'
const SET_CERTIFICADO = 'SET_CERTIFICADO'

const state = {
  token: '',
  hours: 0,
  user,
  wins: [],
  certificados: [],
  isValidatedWins: false,
  loadedCertificados: false
}

const actions = {
  setUser ({ commit }, user) {
    const { token } = user
    if (token) commit(SET_TOKEN, { token })
    commit(SET_USER, { user })
  },

  logOut ({ commit }) {
    commit(LOG_OUT)
  },

  setHours ({ commit }, hours) {
    commit(SET_HOURS, { hours })
  },

  async setCertificados ({ commit, state }, { resource }) {
    if (state.loadedCertificados) return
    const service = new UserService(resource)
    const { body: certificados } = await service.certificados()
    commit(SET_CERTIFICADO, { certificados, loadedCertificados: true })
  },

  async setValidateWins ({ state }) {
    state.isValidatedWins = true
  },

  async setWins (params, values) {
    const commit = params?.commit
    const state = params?.state
    const dispatch = params?.dispatch
    const resource = values?.resource
    let wins = values?.wins

    if (!commit ||  !state?.user ||  !dispatch ||  !resource) return

    const service = new UserService(resource)
    const listWins = wins?.map(({ id }) => id) || []

    const hours = state.hours
    const totalPostagens = state.user?.totalPostagens || 0
    const totalSeguidores = state.user?.totalSeguidores || 0
    const totalCurtidasGalerias = state.user?.totalCurtidasGalerias || 0
    const totalComentariosPostagens = state.user?.totalComentariosPostagens || 0
    
    let hasNewWins = 0
    const hasWinsCertificate = isIncluded(listWins, 8) && isIncluded(listWins, 9)
    if (!hasWinsCertificate) await dispatch('setCertificados', { resource })
    const certificados = state?.certificados?.map(({ idCurso }) => idCurso)

    // validar certificado modelagem (curso 18 e 7 conquista 9 => Corona e V-ray)
    if (!isIncluded(listWins, 9) && (isIncluded(certificados, 18) || isIncluded(certificados, 7))) hasNewWins = await setNewConquista(service, 9, hasNewWins)

    // Verificado - mais de 50 seguidores
    if (!isIncluded(listWins, 14) && totalSeguidores >= 50) hasNewWins = await setNewConquista(service, 14, hasNewWins)
    if (isIncluded(listWins, 14) && totalSeguidores < 50) await deleteConquista(service, 14)

    // Fundador - uns dos primeiro 500 alunos
    // if (!isIncluded(listWins, 1) && state.user.id < 500) hasNewWins = await setNewConquista(service, 1, hasNewWins)
    
    // Pjotinha - Mais de 5 horas
    if (!isIncluded(listWins, 2) && hours >= 5) hasNewWins = await setNewConquista(service, 2, hasNewWins)
    if (isIncluded(listWins, 2) && hours < 5) await deleteConquista(service, 2)
        
    // Faixa Branca - Mais de 10 horas
    if (!isIncluded(listWins, 13) && hours >= 10) hasNewWins = await setNewConquista(service, 13, hasNewWins)
    if (isIncluded(listWins, 13) && hours < 10) await deleteConquista(service, 13)
          
    // Faixa Azul - Mais de 25 horas
    if (!isIncluded(listWins, 3) && hours >= 25) hasNewWins = await setNewConquista(service, 3, hasNewWins)
    if (isIncluded(listWins, 3) && hours < 25) await deleteConquista(service, 3)
      
    // Faixa Marrom - mais que 50 horas de aula e 10 postagens
    if (!isIncluded(listWins, 4) && hours >= 50 && totalPostagens >= 10) hasNewWins = await setNewConquista(service, 4, hasNewWins)
    if (isIncluded(listWins, 4) && (hours < 50 || totalPostagens < 10)) await deleteConquista(service, 4)
      
    // Faixa Preta - mais que 100 horas de aula + 10 postagens + 50 likes
    if (!isIncluded(listWins, 5) && hours >= 100 && totalPostagens >= 10 && totalCurtidasGalerias >= 50) hasNewWins = await setNewConquista(service, 5, hasNewWins)
    if (isIncluded(listWins, 5) && (hours < 100 || totalPostagens < 10 || totalCurtidasGalerias < 50)) await deleteConquista(service, 5)

    // Engajado - pelo menos 10 comentários no feed
    if (!isIncluded(listWins, 6) && totalComentariosPostagens >= 10 ) hasNewWins = await setNewConquista(service, 6, hasNewWins)
    if (isIncluded(listWins, 6) && totalComentariosPostagens < 10 ) await deleteConquista(service, 6)
    
    // Cadastro finalizado e imagem
    if (!isIncluded(listWins, 10) && state.user.cadastroFinalizado === 'S' && state.user.extensaoImagem && state.user.extensaoImagem !== '-') hasNewWins = await setNewConquista(service, 10, hasNewWins)

    // Mais de 3 conquistas
    if (!isIncluded(listWins, 7) && (wins?.length || 0) >= 3) hasNewWins = await setNewConquista(service, 7, hasNewWins)
    // if (isIncluded(listWins, 7) && hasNewWins + wins?.length || 0 < 3) await deleteConquista(service, 7)

    // validar certificado modelagem (curso 12 conquista 8 => Scketch)
    if (!isIncluded(listWins, 8) && isIncluded(certificados, 12)) hasNewWins = await setNewConquista(service, 8, hasNewWins)

    if (hasNewWins > 0) {
      const { body: newWins } = await service.conquistas()
      wins = newWins.map(win => ({ ...win, image: mountImg(win) }))
    }

    commit(SET_WINS, { wins })
  }
}

const getters = {
  getUser: (state) => state.user,

  getValidatedWins: (state) => state.isValidatedWins,

  getWins: (state) => state.wins,

  getCertificados: (state) => state.certificados,

  getHours: (state) => {
    const hour = Math.round(state.hours)
    return isNaN(hour) ? 0 : hour
  },

  getCurrentHours: (state) => state.hours
}

const mutations = {
  [SET_TOKEN] (state, token) {
    Object.assign(state, token)
  },

  [LOG_OUT] (state) {
    state.user = user
    state.token = ''
    state.hours = 0
    state.wins = []
    state.certificados = []
    state.loadedCertificados = false
  },

  [SET_CERTIFICADO] (state, certificados) {
    Object.assign(state, certificados)
  },

  [SET_USER] (state, user) {
    Object.assign(state, user || {})
  },

  [SET_HOURS] (state, hours) {
    Object.assign(state, hours)
  },

  [SET_WINS] (state, wins) {
    Object.assign(state, wins)
  }
}

const isIncluded = (list, item) => list?.includes(item)

const mountImg = (win) => `${Vue.prototype.$baseApiURL}/acesso-arquivo?caminho=conquista&identificador=${win.id}&extensao=${win.extensaoImagem}`

const setNewConquista = async (service, id, hasNewWins) => {
  await service.setConquistas({ idConquista: id })
  return hasNewWins + 1
}

const deleteConquista = async (service, idConquista) => {
  await service.deleteConquistas({ idConquista })
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
