<template>
  <div
    class="modal fade"
    id="modalImageBanner"
  >
    <div class="modal-dialog">
      <div class="modal-content bg-dark">
        <div class="modal-header py-0 px-2">
          <h5 class="modal-title">
            Altere sua imagem de capa
          </h5>

          <button
            type="button"
            class="btn close white upper hovered"
            data-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
        </div>
        <form @submit.prevent="sendImage">
          <div class="modal-body">
            <div class="row">
              <FieldFile
                :is-rect="true"
                v-model="image"
                :max-size="maxSize"
                key-input="capa"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-primary bg-pink border-none"
              type="submit"
            >
              Salvar alterações
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Mixins from '@/Mixins'
import { mapGetters, mapActions } from 'vuex'
import PerfilService from '@/service/perfilService'
import EducandoResource from '@/service/userService'
import FieldFile from '@/components/shared/forms/FieldFile'

export default {
  components: {
    FieldFile
  },

  mixins: [Mixins],

  data () {
    this.PerfilService = new PerfilService(this.$resource)
    this.EducandoResource = new EducandoResource(this.$resource)
    return {
      image: {},
      maxSize: 1000000 * 2
    }
  },

  methods: {
    ...mapActions({ setPerson: 'perfil/setPerson' }),

    mountImage ({ extensaoImagem, id }) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=educando&extensao=${extensaoImagem}&identificador=${id}`
    },

    sendImage () {
      this.PerfilService = new PerfilService(this.$resource)
      this.EducandoResource = new EducandoResource(this.$resource)
      const id = this.user.id
      const dataForm = new FormData()

      dataForm.append('arquivo', this.image)
      dataForm.append('identificador', id)
      dataForm.append('caminho', 'capa')
      const headers = { 'Content-Type': 'multipart/form-data' }
      this.$sweet.sweetWait('Aguarde enquanto estamos salvando')
      this.$http.post(`${Vue.http.options.root}/acesso-arquivo`, dataForm, { headers }).then(({ body: valor }) => {
        const data = { identificador: id, caminho: 'capa', tabela: 'educando.educandos', campo: 'extensao_capa_perfil', valor }
        this.$http.put(`${Vue.http.options.root}/atualiza-campo`, data).then(async () => {
          const { body } = await this.PerfilService.getDados({ id })
          this.setPerson(body)
          setTimeout(() => {
            body.dadosEducando[0].dataHoraCadastro = this.$f.converterDatas(body.dadosEducando?.[0]?.dataHoraCadastro)
            body.dadosEducando[0].img = this.mountImagePath('educando', id, body.dadosEducando?.[0]?.extensaoImagem)
            body.dadosEducando[0].capa = this.mountImagePath('capa', id, body.dadosEducando?.[0]?.extensaoCapaPerfil)
            this.setPerson({ ...body, id, following: false })
            this.$sweet.sweetSuccessResp('<h4>Capa atualizada com sucesso!')
          }, 200)
        }).catch(e => {
          console.log(e)
          this.$sweet.sweetError()
        })
      }).catch(_ => {
        this.$sweet.sweetError()
      })
    }
  },

  computed: {
    ...mapGetters({
      user: 'registration/getUser'
    })
  }
}
</script>

<style lang="sass" scoped>
#modalImageBanner
  background-color: rgba(0,0,0,0.35)
  .modal-footer
    border: none !important
  .modal-header
    border: none !important
  .close
    color: #ff4163
  .modal-title
    padding-left: 10px
    padding-top: 5px
  .bg-pink
    height: 25px !important
    padding-top: 0px
    padding-bottom: 0px
    border-radius: 20px
</style>
