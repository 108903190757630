const SET_ARQUIVOS = 'SET_ARQUIVOS'

const state = {
  arquivos: [],
  isLoading: false
}

const actions = {
  setArquivos ({ commit }, arquivos) {
    commit(SET_ARQUIVOS, { arquivos })
  },
  setIsLoading ({ commit }, isLoading) {
    commit(SET_ARQUIVOS, { isLoading })
  }
}

const getters = {
  getArquivos: (state) => {
    return state.arquivos
  },
  isLoading: (state) => {
    return state.isLoading
  }
}

const mutations = {
  [SET_ARQUIVOS] (state, arquivos) {
    Object.assign(state, arquivos)
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
