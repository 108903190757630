<template>
  <div v-if="visible" class="col-md-6 col-12 mt-4 position-relative">
    <div class="card-arquivos bg-black title-bordered border-radius-0">
      <div class="tag-message">
        <TagMode :is-pro="arquivo.isPro" />
      </div>

      <div :class="['row max-h', isDisabled && 'is-pro']">
        <div class="col-md-5 px-0 pt-0 position-relative">
          <img
            class="arquivo-thumb"
            @error="visible = false"
            :src="arquivo.imagem"
          />
        </div>

        <div class="col-md-7 py-2 py-md-2 px-3 pe-4">
          <div class="d-flex flex-column h-100">
            <div class="scroll mb-3 pe-1">
              <h4 class="font-16 name-title bold pb-1 mb-1">
                {{ arquivo.nome }}
              </h4>

              <p class="secondary paragraph font-12">
                {{ arquivo.descricao }}
              </p>
            </div>

            <a
              role="link"
              target="_blank"
              :href="arquivo.link"
              :class="[
                'w-100',
                isDisabled && 'is-pro',
                'hovered secondary w-100 d-inline-flex justify-content-between font-14',
              ]"
            >
              Clique e faça o download
              <span class="text-secondary font-13">
                {{ arquivo.tamanho }}
                <Download />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Download from "@/assets/icons/Download";

export default {
  components: {
    Download,
  },

  props: {
    arquivo: {},
  },

  data() {
    return {
      visible: true,
    };
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
    }),

    isDisabled() {
      return (
        (this.arquivo.isPro && !this.user.isPro) ||
        this.arquivo.link === "assinatura ainda apta a pedido de reembolso"
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.content
  overflow-y: auto
  box-sizing: border-box
  display: flex
  flex-direction: column
.card-arquivos
  .secondary
    height: 17px !important
  .scroll
    max-height: 100% !important

.title-bordered:has(.is-pro)
  border-left: 2px solid #ff416388
.border-radius-0
  border-radius: 0px !important
.max-h
  height: -webkit-fill-available
.tag-message
  z-index: 1
  position: absolute
  transform: translateY(50%) translateX(-10%)

.is-pro
  opacity: .45
  pointer-events: none
  cursor: not-allowed !important
.border-bottom
  border-color: #444 !important

.image-link
  width: 180px
  z-index: 99999

::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff416399 !important
::-webkit-scrollbar
  width: 1.5px !important
  width: 0px

.scrolling
  max-height: 130px !important
  overflow: scroll
  overflow-x: hidden
.bg-black
  overflow: hidden
  position: relative

.arquivo-thumb
  width: 100%

.conten-info
  overflow: hidden !important
  display: -webkit-box !important
  -webkit-line-clamp: 2 !important
  text-overflow: ellipsis !important
  -webkit-box-orient: vertical !important

@media (max-width: 900px)
  .card-arquivos
    .secondary
      height: auto !important
  .scroll
    max-height: 100vh !important
    height: 100% !important
  .conten-info
    max-height: 35px !important
  .font-16
    font-size: 14px !important
  .bg-black
    background-color: #000 !important
  .image-link
    width: 130px !important
  .arquivo-thumb
    width: 100%
    margin-bottom: 0px !important
</style>
