var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-container"},[(_vm.isLoading)?_c('LoadingComp'):_c('div',{staticClass:"slider"},[_c('div',{staticClass:"slides",on:{"scroll":_vm.handleScroll}},_vm._l((_vm.orderedJobs),function(ref,ind){
var id = ref.id;
var img = ref.img;
return _c('div',{key:("galery-" + ind + "-image"),staticClass:"slide",attrs:{"id":("image_" + ind)},on:{"dblclick":function($event){return _vm.likeProject()},"click":function($event){return _vm.goToDirectPicture(id)}}},[_c('img',{class:[
            'img-post opacity-25',
            _vm.isCurrentImage(id) ? 'opacity-100' : '' ],attrs:{"src":img}})])}),0),(_vm.hasMoreThanOne)?_c('span',{staticClass:"arrow arrow-left pointer",attrs:{"title":"Anterior"},on:{"click":function($event){return _vm.goToPrevious()}}}):_vm._e(),(_vm.hasMoreThanOne)?_c('span',{staticClass:"arrow arrow-right pointer",attrs:{"title":"Próximo"},on:{"click":function($event){return _vm.goToNext()}}}):_vm._e(),(_vm.hasMoreThanOne)?_c('div',{staticClass:"slider_nav"},_vm._l((_vm.orderedJobs),function(ref){
          var id = ref.id;
return _c('span',{key:("galery-" + id + "-links"),class:['slider_nav_link pointer', _vm.isCurrentImage(id)],on:{"click":function($event){return _vm.goToDirectPicture(id)}}})}),0):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }