<template>
  <div id="tag-mode" :class="[isPro ? 'bg-pink' : 'bg-free']">
    <p class="upper text-white">
      {{ isPro ? "Pro" : "Free" }}
    </p>
  </div>
</template>

<script>
export default {
  name: "TagPro",

  props: {
    isPro: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="sass" scoped>
#tag-mode
  font-size: 12px !important
  width: min-content
  place-items: center
  border-radius: 10px
  display: grid !important
  padding: 1px 6px
  .upper
    line-height: normal
    margin: 0px !important
    padding: 0px !important
    font-weight: 600 !important
    font-family: system-ui, -apple-system !important
  .text-white
    color: #fff !important
  .bg-pink
    background-color: #ff4163 !important
  .bg-free
    background: #02b1f0 !important
</style>
