const SET_COURSE = 'SET_COURSE'
const SET_COURSES = 'SET_COURSES'
const SET_IS_LOADING = 'SET_IS_LOADING'

const state = {
  curso: {},
  cursos: [],
  isLoading: false
}

const actions = {
  setCursos ({ commit }, { cursos, url }) {
    commit(SET_COURSES, [...cursos.map(item => mountCourses(item, url))])
  },
  setCurso ({ commit }, curso) {
    commit(SET_COURSE, curso)
  },
  setIsLoading ({ commit }, isLoading) {
    commit(SET_IS_LOADING, isLoading)
  }
}

const getters = {
  getCursos: (state) => {
    return state.cursos
  },
  getCurso: (state) => {
    return state.curso
  },
  isLoading (state) {
    return state.isLoading
  }
}

const mountCourses = (item, url) => {
  return {
    ...item,
    isPro: item.visualizacao === 'P',
    detalhesCorpoModal: item?.detalhesCorpoModal !== '-' ? JSON.parse(item.detalhesCorpoModal) : null,
    image: `${url}/acesso-arquivo?caminho=curso&identificador=${item.id}&extensao=${item.extensaoBannerVertical}&expressao=vertical`,
    imgHorizontal: `${url}/acesso-arquivo?caminho=curso&identificador=${item.id}&extensao=${item.extensaoBanner}&expressao=horizontal`
  }
}

const mutations = {
  [SET_COURSES] (state, cursos) {
    state.cursos = cursos
  },
  [SET_COURSE] (state, curso) {
    state.curso = curso
  },
  [SET_IS_LOADING] (state, isLoading) {
    state.isLoading = isLoading
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
