<template>
  <div class="column">
    <div class="col mb-2 label-modal" v-if="label">
      <label>{{ label }}</label>
    </div>

    <div class="col mb-4">
      <input
        v-mask="mask"
        :placeholder="placeholder"
        class="input-modal mb-2"
        :type="type"
        :disabled="disable"
        v-model="text"
        :required="required"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},

    label: {
      type: String,
    },

    required: {
      default: false,
    },

    placeholder: {
      default: "",
    },

    type: {
      default: "text",
    },

    mask: {
      default: "",
    },

    disable: {
      default: false,
    },
  },
  data() {
    return {
      text: this.value,
    };
  },

  watch: {
    value: {
      handler(value) {
        if (value === this.text) return;
        this.text = value;
      },
    },

    text: {
      handler(text) {
        if (text === this.value) return;

        this.$emit("input", text);
      },
    },
  },
};
</script>
