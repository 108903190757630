<template>
  <div>
    <div class="title-bordered mb-4 mt-4 mt-md-4">
      <h4 class="title">Minhas conquistas</h4>

      <span class="secondary font-14">
        As conquistas desbloqueadas ficarão visíveis no seu perfil e em seus
        comentários no fórum
      </span>
    </div>

    <div class="col-12">
      <LoadingComp v-if="isLoading" />
      <div class="row enter-effect" v-else-if="wins.length">
        <div class="col-12 col-md-6 mb-3" v-for="win in wins" :key="win.id">
          <CardConquista :conquista="win" />
        </div>
      </div>

      <div v-else class="row justify-content-center">
        <img class="sem-conquistas" src="@/assets/images/wins/baby_yoda.png" />
        <p class="text-center secondary">Você ainda não possui conquistas.</p>
      </div>
    </div>

    <div class="col-12 pb-5">
      <div class="row" v-if="!isLoading">
        <div class="col-12">
          <h4 class="title title-bordered mb-4 mt-5">Conquistas bloqueadas</h4>
        </div>

        <div
          :class="[
            'col-12 col-md-6 mb-3',
            conquista.enabled ? '' : 'opacity-25',
          ]"
          v-for="conquista in listaConquistas"
          :key="conquista.titulo"
        >
          <CardConquista :conquista="conquista" v-if="conquista" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import conquistas from "./conquistas.json";
import CardConquista from "./CardConquista";
import UserService from "@/service/userService";
import SharedService from "@/service/sharedServices";

export default {
  name: "ConquistasComp",
  components: {
    CardConquista,
  },

  data() {
    this.UserService = new UserService(this.$resource);
    this.SharedService = new SharedService(this.$resource);
    return {
      conquistas,
      isLoading: true,
      todasConquistas: [],
    };
  },

  mounted() {
    this.buscarConquistas();
  },

  methods: {
    async buscarConquistas() {
      if (!this.user.id) return;
      const { body: conquistasEducando } = await this.UserService.conquistas();
      const mapConquistas = conquistasEducando?.map((conq) => ({
        ...conq,
        image: this.mountImg(conq.id, conq.extensaoImagem),
      }));

      await this.$store.dispatch("registration/setWins", {
        wins: mapConquistas || [],
        resource: this.$resource,
      });

      const { body: conquistas } = await this.SharedService.conquistas();
      this.todasConquistas = conquistas
        .filter(({ id }) => id !== 12)
        .map((conq) => ({
          ...conq,
          image: this.mountImg(conq.id, conq.extensaoImagem),
        }));
      this.isLoading = false;
    },

    mountImg(id, ext) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=conquista&identificador=${id}&extensao=${ext}`;
    },
  },

  computed: {
    ...mapGetters({
      user: "registration/getUser",
      wins: "registration/getWins",
    }),

    listaConquistas() {
      return this.todasConquistas.filter(
        (conquista) => !this.wins.map(({ id }) => id).includes(conquista.id)
      );
    },
  },

  watch: {
    user: {
      deep: true,
      handler() {
        this.buscarConquistas();
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.opacity-25
  transition: all .15s
  opacity: .35 !important
  &:hover
    opacity: .85 !important
.sem-conquistas
  opacity: .85 !important
  width: 200px
</style>
