<template>
  <div
    class="modal fade"
    id="recuperar-senha"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content position-relative">
        <img
          class="pointer close-modal position-absolute"
          src="@/assets/images/close.png"
          data-toggle="modal"
          id="close-recuperar-senha"
          data-target="#recuperar-senha"
        >
        <form @submit.prevent="recuperarSenha">
          <div class="modal-header">
            <h6
              class="modal-title"
              id="exampleModalLabel"
            >
              Recuperar minha senha
            </h6>
          </div>

          <div class="modal-body">
            <InputLogin
              type="email"
              v-model="form.email"
              placeholder="Informe o seu email"
              label="Email"
            >
              <EmailIcon />
            </InputLogin>
          </div>

          <div class="modal-footer">
            <p
              data-dismiss="modal"
              class="font-12 pointer me-3"
            >
              Cancelar
            </p>

            <button
              type="submit"
              class="btn bg-btn-pink"
            >
              Recuperar minha senha
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UsuarioService from '@/service/userService'
import EmailIcon from '@/assets/icons/Email'
import InputLogin from './InputLogin'

export default {
  name: 'ModalRecuperarSenha',

  props: {
    currentEmail: {}
  },

  components: {
    EmailIcon,
    InputLogin
  },

  created () {
    this.UsuarioService = new UsuarioService(this.$resource)
  },

  data () {
    return {
      form: {
        email: this.currentEmail
      }
    }
  },

  methods: {
    async recuperarSenha () {
      this.$sweet.sweetMensagem('', 0)

      this.$sweet.sweetWait('Aguarde enquanto estamos verificando seus dados de acesso')
      document.getElementById('close-recuperar-senha').click()

      const { status } = await this.UsuarioService.recuperarSenha(this.form)
      if (status === 200) {
        const { isDenied } = await this.$sweet.sweetQuestionRecoverPassowrd(
          'Já recebi minha nova senha',
          'Não recebi o e-mail',
          ` <h4 class="font-16">Uma nova senha foi enviada para o seu email!</h4>
            <h6 class="font-14 pink mt-4">Caso a nova senha não esteja na sua caixa de entrada de e-mail, confira na caixa de spam</h6>
          `, 0)

        if (!isDenied) {
          localStorage.setItem('senha-aluno', '')
          location.reload()
          return
        }

        const email = 'suportepjamais@gmail.com'
        this.$sweet.sweetMensagem(`
          <p class="mt-3 font-14">
            Caso não tenha recebido sua nova senha de acesso, 
            nos contate através do e-mail <a class="pink" href="mailto:${email}">${email}</a> para que possamos 
            fornecer um suporte e lhe auxiliar no acesso a plataforma.
          </p>
        `, 0)

        return
      }

      const email = 'senhas@jovemarquiteto.com.br'
      this.$sweet.sweetMensagem(`
        <h4 class="pt-2">Ops, houve um erro na recuperação de senha.</h4>
        <p class="dark-grey-2 my-3">Por favor confira o e-mail informado.</p>
        <p class="font-14 mt-3">Caso o erro persista, entre em contato com <a class="pink" href="${email}">${email}</a></p>
      `, 10000)
    }
  },

  error () {
    this.$sweet.sweetClose()
  }
}
</script>

<style lang="sass">
.swal2-styled.swal2-deny
  margin-top: 5px
  color: #ccc !important
  font-size: 14px !important
</style>

<style lang="sass" scoped>
.input-modal
  background-color: white
  border: none !important
  padding-left: 10px !important
  &:focus
    border-bottom: none !important
    box-shadow: 0px 0px rgba(0,0,0,0) !important
.input-group-prepend
  position: relative
  &:before
    top: 50%
    right: 1px
    width: 1px
    content: ''
    height: 35%
    position: absolute
    background-color: rgba(45,45,45,.35)
    transform: translateY(-50%)
.modal-content
  background-color: #000
#recuperar-senha
  .modal-header
    border-bottom: 1px solid #ffffff33 !important
  .modal-footer
    border-top: 1px solid #ffffff33 !important
  .close-modal
    right: 10px
    top: 10px
</style>
