<template>
  <div>
    <div class="bg-black">
      <a
        target="_blank"
        :href="`https://youtu.be/${item.video}`"
      >
        <img
          class="live-thumb"
          :src="`https://i.ytimg.com/vi/${mountImage(item.video)}/sddefault.jpg`"
        >
      </a>
    </div>

    <div class="conten-info mt-2">
      <h6
        class="bold font-13 px-1 dark-grey-2"
        :title="`${item.titulo} - ${item.descricao}`"
      >
        <span class="font-13 text-white">
          {{ item.titulo }}
        </span>

        <span class="font-13 weight-400 dark-grey-2">
          - {{ item.descricao }}
        </span>
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {}
  },

  methods: {
    mountImage (val) {
      if (!val.includes('?')) return val
      return val.split('?')[0]
    }
  }
}
</script>

<style lang="sass" scoped>
.bg-black
  display: grid
  position: relative
  place-items: center
  overflow: hidden
  border-radius: 10px !important
.live-thumb
  width: 100%
  margin-bottom: -5%
  margin-top: -5%
  aspect-ratio: 3/2
  object-fit: cover
.conten-info
  overflow: hidden !important
  display: -webkit-box !important
  -webkit-line-clamp: 2 !important
  text-overflow: ellipsis !important
  -webkit-box-orient: vertical !important

@media (max-width: 600px)
  .font-16
    font-size: 14px !important
  .bg-black
    background-color: #000 !important
  .conten-info
    max-height: 35px !important
</style>
