<template>
  <svg
    width="448"
    height="512"
    viewBox="0 0 448 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_0_1)">
      <path
        d="M448 209.91C403.975 210.015 361.029 196.285 325.23 170.66V349.38C325.218 382.481 315.1 414.789 296.231 441.985C277.361 469.18 250.638 489.967 219.636 501.565C188.633 513.164 154.829 515.02 122.743 506.888C90.6564 498.755 61.8177 481.021 40.0826 456.055C18.3476 431.09 4.75231 400.085 1.11459 367.184C-2.52312 334.284 3.97016 301.057 19.7262 271.947C35.4822 242.836 59.75 219.23 89.2845 204.284C118.819 189.338 152.213 183.765 185 188.31V278.2C169.996 273.481 153.885 273.623 138.967 278.606C124.049 283.59 111.088 293.159 101.933 305.949C92.7781 318.738 87.8987 334.093 87.9913 349.821C88.0839 365.549 93.1439 380.846 102.449 393.526C111.753 406.207 124.827 415.624 139.803 420.431C154.778 425.238 170.89 425.19 185.837 420.295C200.784 415.399 213.802 405.905 223.031 393.17C232.261 380.434 237.23 365.108 237.23 349.38V0H325.23C325.169 7.4314 325.791 14.8527 327.09 22.17V22.17C330.148 38.5048 336.506 54.0441 345.775 67.8373C355.045 81.6306 367.031 93.3879 381 102.39C400.874 115.531 424.174 122.535 448 122.53V209.91Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_1">
        <rect
          width="448"
          height="512"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TiktokIcon'
}
</script>

<style lang="sass" scoped>
svg
  width: 15px
  height: 15px
</style>
