<template>
  <!-- <div class="mb-3"> -->
  <div class="title-bordered box-conquista bg-black py-2 py-md-1">
    <div class="row align-items-center">
      <div class="col-auto pe-0">
        <div class="circulo-conquista">
          <img :src="`${conquista.image}`" />
        </div>
      </div>
      <div class="col p-3 pe-4 ps-3">
        <div class="conteudo-conquista">
          <h6 class="title mb-2 pb-1">
            {{ conquista.titulo }}
          </h6>
          <p class="font-14 secondary">
            {{ conquista.descricao }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default {
  props: {
    conquista: {},
  },
};
</script>
<style lang="sass" scoped>
.conteudo-conquista
  .title
    border-bottom: 1px solid #1d1d1d
    height: auto !important
.circulo-conquista
  width: 70px
  overflow: hidden
  img
    width: 100%
.bg-black
  height: 100%
@media (max-width: 600px)
  .bg-black
    background-color: #000 !important
  .circulo-conquista
    width: 50px
</style>
