export default class cursoService {
  constructor (resource) {
    const acoesCustomizadas = {
      cursosAtivos: { method: 'GET', url: 'plataforma-conteudo/curso' },
      cursosCampanha: { method: 'GET', url: 'plataforma-conteudo/geral/campanha/tipo{/id}' },
      // cursosCampanha: { method: 'GET', url: 'acesso-publico/campanha/tipo{/id}' },
      cursoTags: { method: 'GET', url: 'curso{/id}/tag/status/a' },
      cursoLinksUteis: { method: 'GET', url: 'plataforma-conteudo/curso{/id}/link-util' },
      cursoTemporadas: { method: 'GET', url: 'plataforma-conteudo/curso{/id}/temporada' },
      todasTemporadas: { method: 'GET', url: 'plataforma-conteudo/curso{/id}/temporadas-videos' },
      cursoVideos: { method: 'GET', url: 'plataforma-conteudo/curso{/id}/temporada{/idTemporada}/videos' },
      cursoVideo: { method: 'GET', url: 'plataforma-conteudo/video{/id}' },
      getVideo: { method: 'GET', url: 'plataforma-conteudo/curso{/id}/temporada{/modulo}/video{/video}/acessar-video' },
      materiais: { method: 'GET', url: 'plataforma-conteudo/video{/id}/material-complementar' }
    }
    this.resource = resource('plataforma-conteudo/curso{/id}', {}, acoesCustomizadas)
  }

  getVideo (dados) {
    return this.resource.getVideo(dados, { emulateJSON: true }).then(res => res, err => err)
  }

  materiais (dados) {
    return this.resource
      .materiais(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  get (dados) {
    return this.resource
      .get(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  cursosCampanha (dados) {
    return this.resource
      .cursosCampanha(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  cursoVideo (dados) {
    return this.resource
      .cursoVideo(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  cursoVideos (dados) {
    return this.resource
      .cursoVideos(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  cursoTemporadas (dados) {
    return this.resource
      .cursoTemporadas(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  todasTemporadas (dados) {
    return this.resource
      .todasTemporadas(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  cursoLinksUteis (dados) {
    return this.resource
      .cursoLinksUteis(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  cursoTags (dados) {
    return this.resource
      .cursoTags(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }

  cursosAtivos (dados) {
    return this.resource
      .cursosAtivos(dados, { emulateJSON: true })
      .then(res => res, (err) => {
        throw new Error(`Não foi possível obter o acesso ${err}`)
      })
  }
}
