<template>
  <button
    :class="['upper white btn-pink', classe]"
    type="submit"
    :disabled="isDisabled"
  >
    {{ label }}
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    isDisabled: {
      default: false,
    },
    label: {
      default: "Começar a assistir",
    },
    classe: {
      default: "",
    },
  },
};
</script>

<style lang="sass" scoped>
@media (max-width: 500px)
  .btn
    height: 24px !important
    padding: 0px 8px
    font-size: 12px
button
  display: inline-flex
  justify-content: center
  align-items: center
  width: 100%
  max-width: 250px
  padding-left: 50px
  padding-right: 50px
  background-color: #ff4163
  border-radius: 5px
  border: none
  &:hover
    color: #fff !important
    opacity: .75
.btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus
  box-shadow: 0px 0px 1px 4px rgba(255,65,99,.5)
.small
  width: max-content
  height: 28px
  font-size: 12px !important
  padding: 0px 10px
</style>
