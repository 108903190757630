const SET_LIVES = 'SET_LIVES'

const state = {
  lives: [],
  isLoading: false
}

const actions = {
  setLives ({ commit }, lives) {
    commit(SET_LIVES, { lives })
  },
  setIsLoading ({ commit }, isLoading) {
    commit(SET_LIVES, { isLoading })
  }
}

const getters = {
  getLives: (state) => {
    return state.lives
  },
  isLoading: (state) => {
    return state.isLoading
  }
}

const mutations = {
  [SET_LIVES] (state, parceiros) {
    Object.assign(state, parceiros)
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
