<template>
  <div class="statistics">
    <div class="row justify-content-between">
      <div class="col white font-13">
        Exibições de projetos
      </div>
      <div class="col-auto white font-13 text-end">
        {{ estatisticas.projectsViews || 0 }}
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col white font-13">
        Curtidas
      </div>
      <div class="col-auto white font-13 text-end">
        {{ estatisticas.likes || 0 }}
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col white font-13">
        Seguidores
      </div>
      <div class="col-auto white font-13 text-end">
        {{ estatisticas.followers || 0 }}
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col white font-13">
        Seguindo
      </div>
      <div class="col-auto white font-13 text-end">
        {{ estatisticas.following || 0 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticsComp',

  props: {
    estatisticas: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="sass" scoped>
.statistics
  padding: 0px 2px
</style>
