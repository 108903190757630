const SET_FEEDS = 'SET_FEEDS'
const SET_LOADING = 'SET_LOADING'
const SET_EXCLUDED = 'SET_EXCLUDED'
const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE'

const state = {
  isLoading: false,
  feeds: [],
  newMessages: [],
  excludedItems: []
}

const actions = {
  setFeeds: ({ commit }, feeds) => commit(SET_FEEDS, { feeds }),

  setIsLoading: ({ commit }, isLoading) => commit(SET_LOADING, { isLoading }),

  setExcludedItems: ({ commit }, key) => commit(SET_EXCLUDED, key),

  setNewMessage: ({ commit }, message) => commit(SET_NEW_MESSAGE, message)
}

const getters = {
  getFeeds: ({ feeds }) => feeds,

  isLoading: ({ isLoading }) => isLoading,

  getExcludedItems: ({ excludedItems }) => excludedItems,

  getNewMessages: ({ newMessages }) => newMessages
}

const mutations = {
  [SET_LOADING]: (state, feeds) => (state.feeds = feeds),

  [SET_EXCLUDED]: (state, key) => state.excludedItems.push(key),

  [SET_FEEDS]: (state, isLoading) => (state.isLoading = isLoading),

  [SET_NEW_MESSAGE]: (state, message) => state.newMessages.push(message)
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
